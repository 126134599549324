<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import EntityPagesEditor from './pages/EntityPagesEditor.vue'
import PageFieldsEditor from './page_fields/PageFieldsEditor.vue'


// const store = useStore()
// store.commit('setPageTitle', "Editeur d'entité des dossiers permanents")

interface Props {
    objectModelId: any
}

const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()


const objectModelsResponse = await userApi.getDatabaseObjectModels()
const objectModels = ref(objectModelsResponse)

const unsavedChanges = ref(false)
provide("unsavedChanges", unsavedChanges)

console.log("editing objectModel: ", route.params.objectModelId, objectModels.value)

function findObjectModelById(id: string) {
    return objectModels.value.find(objectModel => objectModel.id === id)
}

const objectModel = ref([findObjectModelById(route.params.objectModelId)?.definitionJson])
const selectedPage = ref(null)
provide("selectedPage", selectedPage)

console.log("editing objectModel: ", objectModel.value)

const splitterModel = ref(25)

const thumbStyle = {
    right: '1px',
    borderRadius: '5px',
    width: '5px',
    color: 'rgb(71, 71, 71)',
    opacity: '0.2',
    zIndex: 10,
}

// https://www.sanwebe.com/2013/02/confirmation-dialog-on-leaving-page-javascript#:~:text=If%20you%20want%20to%20show,leave%20or%20reload%20the%20page.
window.onbeforeunload = function (e) {
    console.log('onbeforeunload', unsavedChanges.value)
    return unsavedChanges.value ? true : undefined
}

onBeforeRouteLeave(async (to, from) => {
    if (unsavedChanges.value == false) {
        return true
    }

    const answer = window.confirm('Les modifications que vous avez apportées ne seront pas enregistrées si vous quittez cette page.')
    if (!answer) {
        return false
    }
})
</script>

<template>
    <q-page class="">
        <q-splitter v-model="splitterModel" style="height: calc(100vh - 80px)">
            <template v-slot:before>
                <EntityPagesEditor :objectModel="objectModel"></EntityPagesEditor>
            </template>

            <template v-slot:after>
                <PageFieldsEditor :objectModel="objectModel" :page="selectedPage"></PageFieldsEditor>
            </template>
        </q-splitter>
    </q-page>
</template>

<style lang="scss" scoped></style>
