import { ref, onUnmounted, Ref } from 'vue'

interface ScrollOptions {
  delay?: number
  scrollAmount?: number
  duration?: number
}

interface ScrollElement {
  setScrollPosition: (axis: 'vertical', position: number, duration: number) => void
  getScrollPosition: () => { top: number }
}

export function useDelayedScroll(scrollRef: Ref<ScrollElement>, shouldScroll: () => boolean, options: ScrollOptions = {}) {
  const {
    delay = 300,
    scrollAmount = 100,
    duration = 500
  } = options

  let timeoutId: number | undefined = undefined

  const scroll = () => {
    if (shouldScroll() && scrollRef.value) {
      scrollRef.value.setScrollPosition(
        'vertical',
        scrollRef.value.getScrollPosition().top + scrollAmount,
        duration
      )
    }
  }

  const startScrollTimer = () => {
    if (timeoutId !== undefined) window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      timeoutId = undefined
      scroll()
    }, delay)
  }

  const clearScrollTimer = () => {
    if (timeoutId !== undefined) {
      window.clearTimeout(timeoutId)
      timeoutId = undefined
    }
  }

  onUnmounted(() => {
    clearScrollTimer()
  })

  return {
    startScrollTimer,
    clearScrollTimer
  }
}
