<script setup lang="ts">
import { ref, watch, provide, onMounted } from 'vue'
import { useStore } from '@/store/store'
import { useRoute } from 'vue-router'
import CheckVersion from '@/components/utils/CheckVersion.vue'
import { isMobile } from '@/utils/detectMobile'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import GuidedTour from './GuidedTour.vue'

const route = useRoute()
const store = useStore()
const userApi: UserApi = useApi()

const defaultLayout = 'default'
const layout = ref('default-layout')

layout.value = setLayout()

const favorites = ref({
  clients: [],
  userProjects: [],
})
provide('favorites', favorites)

const recentProjects = ref([])
provide("recentProjects", recentProjects)

onMounted(async () => {
  if(store.state.user !== null) {
    favorites.value = await userApi.getFavorites()
    recentProjects.value = await userApi.getRecentlyViewedProjects(1, '', 5)
  }
})

watch(route, () => {
  layout.value = setLayout()
})

function setLayout() {
  // console.log("isMobile()", isMobile());
  if (store.state.user === null) {
    return 'public-layout'
  }

  if (isMobile()) {
    return 'mobile-layout'
  }

  if (route?.name?.includes('outlook-add-in')) {
    return 'outlook-add-in-layout'
  }

  return (route.meta.layout || defaultLayout) + '-layout'
}
</script>

<template>
  <div id="app">
    <Suspense>
      <div>
        <CheckVersion></CheckVersion>
        <component :is="layout">
          <router-view></router-view>
        </component>
        <GuidedTour/>
      </div>
    </Suspense>
  </div>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@/styles/quasar.variables.scss';

body {
  font-family: 'IBM Plex Sans';
  color: black;
}

h1 {
  font-weight: 700;
  font-size: 28px;
  color: black;
}

.tooltip {
  background-color: white;
  color: black;
  font-size: 13px;
  font-weight: 400;
  padding: 8px 16px;
  border-radius: 8px;
}

.main_primary {
  color: $main_color;
  opacity: $primary_opacity;
}

.main_secondary {
  color: $main_color;
  opacity: $secondary_opacity;
}

.main_disabled {
  color: $main_color;
  opacity: $disabled_opacity;
}

.main_background {
  color: $main_color;
  opacity: $background_opacity;
}

.error_primary {
  color: $error_color;
  opacity: $primary_opacity;
}

.error_secondary {
  color: $error_color;
  opacity: $secondary_opacity;
}

.error_disabled {
  color: $error_color;
  opacity: $disabled_opacity;
}

.error_background {
  color: $error_color;
  opacity: $background_opacity;
}

.warning_primary {
  color: $warning_color;
  opacity: $primary_opacity;
}

.warning_secondary {
  color: $warning_color;
  opacity: $secondary_opacity;
}

.warning_disabled {
  color: $warning_color;
  opacity: $disabled_opacity;
}

.warning_background {
  color: $warning_color;
  opacity: $background_opacity;
}

.success_primary {
  color: $success_color;
  opacity: $primary_opacity;
}

.success_secondary {
  color: $success_color;
  opacity: $secondary_opacity;
}

.success_disabled {
  color: $success_color;
  opacity: $disabled_opacity;
}

.success_background {
  color: $success_color;
  opacity: $background_opacity;
}

.information_primary {
  color: $information_color;
  opacity: $primary_opacity;
}

.information_secondary {
  color: $information_color;
  opacity: $secondary_opacity;
}

.information_disabled {
  color: $information_color;
  opacity: $disabled_opacity;
}

.information_background {
  color: $information_color;
  opacity: $background_opacity;
}

.black_primary {
  color: $black_color;
  opacity: $primary_opacity;
}

.black_secondary {
  color: $black_color;
  opacity: $secondary_opacity;
}

.black_tertiary {
  color: $black_color;
  opacity: $tertiary_opacity;
}

.black_quaternary {
  color: $black_color;
  opacity: $quaternary_opacity;
}

.black_quinary {
  color: $black_color;
  opacity: $quinary_opacity;
}

.black_senary {
  color: $black_color;
  opacity: $senary_opacity;
}

.white_primary {
  color: $white_color;
  opacity: $primary_opacity;
}

.white_secondary {
  color: $white_color;
  opacity: $secondary_opacity;
}

.white_tertiary {
  color: $white_color;
  opacity: $tertiary_opacity;
}

.white_quaternary {
  color: $white_color;
  opacity: $quaternary_opacity;
}

.white_quinary {
  color: $white_color;
  opacity: $quinary_opacity;
}

.white_senary {
  color: $white_color;
  opacity: $senary_opacity;
}

.page {
  padding-top: 0px;
  padding-left: 24px;
  height: 100%;
  // height: calc(100% - 100px);
}

.button-link {
  text-decoration: none;
  color: #027be3;
}

.button-link:hover {
  text-decoration: underline;
}
</style>
