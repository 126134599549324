<script lang="ts" setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/store/useAppStore'
import { Notify } from 'quasar'
import BaseProgressBar from '@/components/base/BaseProgressBar.vue'
import BaseFooter from '@/components/base/BaseFooter.vue'
import SurveyQuestion from '@/components/survey/SurveyQuestion.vue'
import { Survey } from '@/models/survey/Survey'
import { Question } from '@/models/survey/Question'
import { DependantQuestion } from '@/models/survey/DependantQuestion'
import Preview from '@/components/survey/Preview.vue'
import { onUnmounted } from 'vue'
import { useDelayedScroll } from '@/composables/useDelayedScroll'

const router = useRouter()
const userApi = useApi()
const route = useRoute()
const page = ref(0)
const isRecapButtonLoading = ref(false)
const isNextLoading = ref(false)
const isPreviousLoading = ref(false)
const isDisable = ref(false)
const survey = ref<Survey>()
const showGlobalVariables = ref(false)
const scrollAreaRef = ref()

survey.value = await userApi.getSurvey(route.params.documentId)

if (route.query.page) {
  page.value = +route.query.page
}
const isAllQuestionHidden = computed(() => {
  const result = survey.value?.pages[page.value - 1].questions.find((question) => {
    return isQuestionVisible(question) && (!question.isGlobal || !question.isGlobalValue)
  })
  return !result
})

function isQuestionVisible(question: Question): boolean {
  let isVisible: boolean | undefined = true
  let isVisibleArray: Array<boolean> = []

  if (question.label === "embedded") {
    return false
  }

  if (question.dependantQuestions.length === 0) {
    return true
  }
  for (let i = 0; i < question.dependantQuestions.length; i++) {
    const dependantQuestion = question.dependantQuestions[i]
    const parentQuestion = findDependantQuestion(dependantQuestion)
    if (parentQuestion === undefined) {
      return true
    }
    if (!isQuestionVisible(parentQuestion)) {
      return false
    }
    let dependantQuestionTest = getDependantValue(dependantQuestion, parentQuestion)
    isVisibleArray.push(dependantQuestionTest)
  }

  isVisible = isVisibleArray.every((iv) => iv)
  return isVisible
}

function findDependantQuestion(dependantQuestion: DependantQuestion): Question | undefined {
  const parentQuestionPage = survey.value?.pages.find((page) => {
    return page.questions.find((question) => {
      return question.id === dependantQuestion.id
    })
  })
  if (parentQuestionPage === undefined) {
    return undefined
  }
  const parentQuestion = parentQuestionPage.questions.find((parentQuestion) => {
    if (parentQuestion.id === dependantQuestion.id) {
      return parentQuestion
    }
  })
  return parentQuestion
}

function getDependantValue(dependantQuestion, parentQuestion): boolean {
  let isVisibleArray: Array<boolean> = []
  if (typeof dependantQuestion.value === 'boolean' || typeof parentQuestion.answer.value === 'boolean') {
    isVisibleArray.push(dependantQuestion.value.toString() === parentQuestion.answer.value.toString())
  } else if (Array.isArray(parentQuestion.answer.value)) {
    isVisibleArray.push(parentQuestion.answer.value.some((v) => v === dependantQuestion.value))
  } else {
    isVisibleArray.push(dependantQuestion.value === parentQuestion.answer.value)
  }
  return isVisibleArray[0]
}

function isAskedPageEmpty(page) {
  let isPageEmpty: boolean = true
  survey.value?.pages[page - 1].questions.forEach((question) => {
    if (isQuestionVisible(question)) {
      isPageEmpty = false
    }
  })
  return isPageEmpty
}

function showGlobalVariableIfEmpty(question: Question) {
  // Never show global variables
  // return false;
  if (showGlobalVariables.value) {
    return true
  }
  if (question.isGlobal && !showGlobalVariables.value) {
    return question.isGlobal && !question.isGlobalValue
  }
}

async function putSurvey() {
  let data: Survey = JSON.parse(JSON.stringify(survey.value))
  data.pages = data.pages.filter((p, index) => {
    return index + 1 === +route.query.page
  })
  await userApi.putSurvey(route.params.documentId, data)
}

async function goToSummary() {
  try {
    isRecapButtonLoading.value = true
    await putSurvey()
    isRecapButtonLoading.value = false
    router.push({
      path: `${route.path}/summary`,
    })
  } catch (e) {
    Notify.create({
      message: `Erreur lors de la sauvegarde des réponses de la page`,
      type: 'negative',
    })
    isDisable.value = false
    isNextLoading.value = false
    isPreviousLoading.value = false
  }
}

async function goToPage(pageAsked, isNext: boolean) {
  // TODO: check error cases here (linked to bug COL-26)
  try {
    isDisable.value = true
    isNext ? (isNextLoading.value = true) : (isPreviousLoading.value = true)
    await putSurvey()
    if (isAskedPageEmpty(pageAsked)) {
      if (pageAsked === survey.value?.pages.length) {
        router.push({
          path: `${route.path}/summary`,
        })
      } else {
        isNext ? goToPage(pageAsked + 1, true) : goToPage(pageAsked - 1, false)
      }
    } else {
      router.push({
        path: route.path,
        query: { page: pageAsked },
      })

      scrollAreaRef.value.setScrollPosition('vertical', 0)
      page.value = pageAsked
    }

    isDisable.value = false
    isNextLoading.value = false
    isPreviousLoading.value = false
  } catch (e) {
    Notify.create({
      message: `Erreur lors de la sauvegarde des réponses de la page`,
      type: 'negative',
    })
    isDisable.value = false
    isNextLoading.value = false
    isPreviousLoading.value = false
  }
}

function onBack() {
  if (route.params.clientId && route.params.projectId) {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: { taskId: route.params.taskId },
    })
  } else {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: { taskId: route.params.taskId },
    })
  }
}

console.log("survey.value", survey.value)

const showPreview = ref(true)
const splitterModel = ref(50)

watch(() => showPreview.value, (value) => {
  if (value) {
    splitterModel.value = 50
  } else {
    splitterModel.value = 100
  }
})

const isUnanswered = computed(() => {
  let isUnanswered = false
  // Check only visible questions on the current page
  const currentPageQuestions = survey.value?.pages[page.value - 1]?.questions || []
  for (const question of currentPageQuestions) {
    if (isQuestionVisible(question) && showGlobalVariableIfEmpty(question)) {
      // Check if answer is empty or undefined (take first element for "object" question)
      const answer = question.type === 'object' ? question.options[0].answer?.value : question.answer?.value
      if (answer === undefined || answer === null || answer === '' ||
      (Array.isArray(answer) && answer.length === 0)) {
        isUnanswered = true
        break
      }
    }
  }
  return isUnanswered
})

const { startScrollTimer, clearScrollTimer } = useDelayedScroll(
  scrollAreaRef,
  () => isUnanswered.value,
  { delay: 300, scrollAmount: 100, duration: 500 }
)

onUnmounted(() => {
  clearScrollTimer()
})
</script>
<template>
  <div class="column">
    <div class="row justify-between q-mt-sm items-center">
      <!-- <div style="max-width: 100%; display: inline"> -->
      <q-btn outline icon="arrow_back" class="q-mb-xs q-mr-md col-auto" label="Retour" @click="onBack" />
      <div class="col" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
        <span class="document-title">{{ survey?.documentName }}</span>
        <q-tooltip style="font-size: 14px;" :delay=400>
          {{ survey?.documentName }}
        </q-tooltip>
      </div>
      <!-- </div> -->
      <div class="col-auto">
        <q-toggle v-model="showGlobalVariables" label="Voir les questions déjà renseignées" left-label color="primary"
          keep-color checked-icon="visibility" unchecked-icon="visibility_off" size="lg" />
        <q-toggle v-model="showPreview" label="Voir la prévisualisation" left-label color="primary" keep-color
          checked-icon="visibility" unchecked-icon="visibility_off" size="lg" />
      </div>
    </div>
    <BaseProgressBar :pages="survey?.pages" class="q-mb-sm"></BaseProgressBar>

    <q-splitter v-model="splitterModel" :limits="[30,100]" style="width: 100%;">
      <template v-slot:before>
        <q-scroll-area ref="scrollAreaRef" class="questions-scroll-area">

          <span class="page-title">{{ survey?.pages[page - 1] ? survey?.pages[page - 1].name : `Page ${page}` }}</span>

          <div v-if="survey?.pages[page - 1]?.description"
            class="description q-mt-sm q-mb-md q-pa-sm row items-center no-wrap">
            <div>
              <q-icon name="info" size="md" class="info q-mr-sm"></q-icon>
            </div>
            <div>{{ survey.pages[page - 1]?.description }}</div>
          </div>

          <div style="height: 16px;"></div>

          <div v-for="question in survey?.pages[page - 1]?.questions" :key="question.id">
            <SurveyQuestion :question="question" :survey="survey"
              v-if="isQuestionVisible(question) && showGlobalVariableIfEmpty(question)"></SurveyQuestion>
          </div>
          <div class="column items-center q-pt-lg" v-if="!showGlobalVariables && isAllQuestionHidden">
            <q-toggle v-model="showGlobalVariables" left-label color="primary" keep-color checked-icon="visibility"
              unchecked-icon="visibility_off" size="80px" />
            <span class="empty-sentence">
              Afficher ou modifier les questions ayant déjà été renseignées.
            </span>
          </div>
        </q-scroll-area>
      </template>

      <template v-slot:after>
          <div style="margin-left: 20px; display: flex; align-items: center;">
            <h6 style="margin: 0; padding-right: 10px;">Contrôle de saisie</h6>
            <q-icon name="info" size="sm" color="primary">
              <q-tooltip style="font-size: 14px;">
                Cette fenêtre ne sert qu’au contrôle de saisie, la mise en forme se fera automatiquement sur Word
              </q-tooltip>
            </q-icon>
          </div>
          <q-scroll-area class="preview-scroll-area">
            <Preview :surveyPages="survey?.pages" :surveyId="survey?.id"></Preview>
          </q-scroll-area>
      </template>
    </q-splitter>
    <BaseFooter
      @mouseenter="startScrollTimer"
      @mouseleave="clearScrollTimer">
      <template #content>
        <q-btn :disable="page === 1 || isDisable" flat color="white" label="Précédent" icon="navigate_before"
          size="22px" @click="goToPage(+route.query.page - 1, false)" :loading="isPreviousLoading" />
        <q-btn flat color="white" label="Voir récapitulatif" icon-right="done_all" size="22px"
          :loading="isRecapButtonLoading" :disable="isDisable" @click="goToSummary" />
        <q-btn v-if="page < survey?.pages.length" flat color="white" label="Suivant" icon-right="navigate_next"
          size="22px" :loading="isNextLoading" :disable="isDisable" @click="goToPage(+route.query.page + 1, true)" />
      </template>
    </BaseFooter>

  </div>
</template>
<style lang="scss" scoped>
.questions-scroll-area {
  height: calc(100vh - 191px);
  padding-right: 15px;
  margin-right: 5px;
}

.preview-scroll-area {
  height: calc(100vh - 270px);
  max-width: 800px;
  background-color: #fff;
  margin: 20px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
}

.document-title {
  font-size: 24px;
  font-weight: 500;
  margin-right: 10px;
}

.page-title {
  font-size: 24px;
  font-weight: 500;
}

.description {
  border: solid;
  background-color: #eeeeee;
  border-color: #cfd8dc;
  border-width: 0px 8px;
  border-radius: 4px;

  .info {
    color: rgba(64, 104, 200, 0.48);
  }
}

.empty-sentence {
  color: gray;
  font-size: 26px;
  font-weight: 400;
}

</style>
