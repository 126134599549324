<script setup lang="ts">
import { ref, Ref, provide, onMounted, onBeforeUnmount } from 'vue'
import { Dialog } from 'quasar'
import { Node } from '@/models/database/Node'
import { Question } from '@/models/survey/Question'
import { Survey } from '@/models/survey/Survey'
import ObjectSelectionForm from '@/components/common/database/ObjectSelectionForm.vue'
import ObjectSelectionFormv2 from '@/components/common/database/ObjectSelectionFormv2.vue'
import ObjectEditionDialog from '@/components/common/database/ObjectEditionDialog.vue'
import SurveyQuestionInput from '../survey/SurveyQuestionInput.vue'
import SurveyArrayFormDialog from './dialog/SurveyArrayFormDialog.vue'
import SurveyMultiArrayFormDialog from './dialog/SurveyMultiArrayFormDialog.vue'
import SurveyAIModal from './dialog/SurveyAIModal.vue'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'

const userApi: UserApi = useApi()

interface Props {
  question: Question
  survey: Survey
}
const props = withDefaults(defineProps<Props>(), {})

const descriptionOverflowing = ref(false)
const showFullDescription = ref(false)
const descriptionText = ref(null)

function checkOverflow() {
  if (descriptionText.value && showFullDescription.value===false) {
    descriptionText.value.style.overflow = 'visible'; // Temporarily allow overflow to calculate
    descriptionOverflowing.value = descriptionText.value.scrollHeight > descriptionText.value.clientHeight
    descriptionText.value.style.overflow = ''; // Reset overflow style
  }
}

// Set up ResizeObserver to dynamically check overflow in the question description
const resizeObserver = ref(null);
const setupResizeObserver = () => {
  if (descriptionText.value) {
    resizeObserver.value = new ResizeObserver(() => {
      checkOverflow();
    });
    resizeObserver.value.observe(descriptionText.value);
  }
};

onMounted(() => {
  checkOverflow()
  setupResizeObserver()
});

onBeforeUnmount(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect(); // Clean up observer
  }
});

const databaseObject: Ref<Array<Node | null>> = ref([])
provide('databaseObject', databaseObject)
const databaseForInsert = ref(undefined)
provide('databaseForInsert', databaseForInsert)
const databaseChildObject: Ref<Array<any>> = ref([])
provide('databaseChildObject', databaseChildObject)

////////// Old code
function updateAnswerFromDatabase(question) {
  console.log("updating role related question", question)
  if (question.type === 'text') {
    let objectFieldName = question.databaseObjectModelFieldsMapping[0].databaseObjectModelFieldName
    let objectField = getField(databaseObject.value, objectFieldName)

    if (objectFieldName === 'Adresse du siège social') {
      let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
      if (objectField.value.fields[1].value !== undefined) {
        address += ' ' + objectField.value.fields[1].value
      }
      if (objectField.value.fields[2].value !== undefined) {
        address += ' ' + objectField.value.fields[2].value
      }
      if (objectField.value.fields[3].value !== undefined) {
        address += ' ' + objectField.value.fields[3].value
      }
      if (objectField.value.fields[4].value !== undefined) {
        address += ' ' + objectField.value.fields[4].value
      }
      if (objectField.value.fields[5].value !== undefined) {
        address += ' ' + objectField.value.fields[5].value
      }
      question.answer.value = address
    } else {
      question.answer.value = objectField.value
    }
  }
  if (question.type === 'number') {
    let objectFeildName = question.databaseObjectModelFieldsMapping[0].databaseObjectModelFieldName
    let objectField = getField(databaseObject.value, objectFeildName)
    question.answer.value = objectField.value
  }
  if (question.type === 'object') {
    databaseObject?.value.fields?.forEach((field) => {
      if (question.label === field.label) {
        question.answer.value = field.value
      }
      if (question.label === 'Dénomination sociale' && field.label === 'Nom') {
        question.answer.value = field.value
      }
      if (question.label === 'Forme sociale' && field.label === 'Forme juridique') {
        question.answer.value = field.value
      }
      if (question.label === 'Forme sociale ' && field.label === 'Forme juridique') {
        question.answer.value = field.value
      }
      if (
        question.label === 'Veuillez renseigner les informations suivantes relatives au capital social' &&
        field.label === 'Capital social'
      ) {
        // question.answer.value = field.value;
        question.options[0].answer.value = field.value
      }
      if (question.label === 'Adresse de siège social' && field.label === 'Adresse du siège social') {
        let objectField = field
        question.answer.value = field.value
      }
      if (question.label === 'Numéro de SIREN' && field.label === "Numéro d'immatriculation") {
        question.answer.value = field.value
      }
      if (question.label === "Ville d'immatriculation au RCS" && field.label === "Ville d'immatriculation") {
        question.answer.value = field.value
      }
      if (
        (question.label === 'Adresse' && field.label === 'Adresse') ||
        (question.label === 'Adresse du siège social' && field.label === 'Adresse du siège social') ||
        (question.label === 'Siège social' && field.label === 'Adresse du siège social')
      ) {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        if (objectField.value.fields[3].value !== undefined) {
          address += ' ' + objectField.value.fields[3].value
        }
        if (objectField.value.fields[4].value !== undefined) {
          address += ' ' + objectField.value.fields[4].value
        }
        if (objectField.value.fields[5].value !== undefined) {
          address += ' ' + objectField.value.fields[5].value
        }
        question.answer.value = address
      }

      if (field.label === 'Adresse/Numéro et rue') {
        let objectField = field
        let address = objectField.value.fields[0].value !== undefined ? objectField.value.fields[0].value : ''
        if (objectField.value.fields[1].value !== undefined) {
          address += ' ' + objectField.value.fields[1].value
        }
        if (objectField.value.fields[2].value !== undefined) {
          address += ' ' + objectField.value.fields[2].value
        }
        question.answer.value = address
      }
    })
  } // question type object

  if (question.type === 'multiarray') {
    console.log('question type: multiarray', question, databaseObject.value)
  }
}
function getField(dBObject, fieldLabel: string): any {
  let result = null
  dBObject.fields.forEach((field) => {
    if (field.label === fieldLabel) {
      result = field
    }
  })
}
/////////// End old code

function isObjectTypePresentInDatabase(): boolean {
  if (
    props.question.databaseObjectModelFieldsMapping &&
    props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName !== ''
  ) {
    return true
  } else {
    return false
  }
}

function showDatabaseObjectSelectionForm() {
  const dialog = Dialog.create({
    component: ObjectSelectionForm,
    componentProps: {
      question: props.question,
      databaseForInsert: databaseForInsert,
    },
  })
    .onOk((data) => {
      console.log("databaseobject selected", data)
      if (data.isChild) {
        console.log("databaseobject selected is child")
        databaseChildObject.value = data.data

        if (props.question.role !== '') {
          props.survey.pages.forEach((page) => {
            page.questions.forEach((question) => {
              if (question.role === props.question.role && question != props.question) {
                console.log("Need to update answer for question", question)
                // updateAnswerFromDatabase(question)
              }
            })
          })
        }

      } else {
        databaseObject.value = data
        updateAnswerFromDatabase(props.question)
        if (props.question.role !== '') {
          props.survey.pages.forEach((page) => {
            page.questions.forEach((question) => {
              if (question.role === props.question.role && question != props.question) {
                updateAnswerFromDatabase(question)
              }
            })
          })
        }
      }
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function showEditionForm() {
  let databaseObject = await userApi.getDatabaseObject(props.question.answer.dpLink.databaseObjectId)
  if (!databaseObject) {
    delete props.question.answer.dpLink
    showDatabaseObjectSelectionFormv2()
    return
  }
  const dialog = Dialog.create({
    component: ObjectEditionDialog,
    componentProps: {
      databaseObject: databaseObject,
      listNodeId: props.question.answer.dpLink.listNodeId,
      arrayRowId: props.question.answer.dpLink.arrayRowId,
      questionMappings: props.question.databaseObjectModelFieldsMapping[0].data,
      questionOptions: props.question.options
    },
  })
    .onOk((data) => {
      if (data && data.deleteLink) {
        props.question.answer.dpLink = null
      }
      dialog.hide()
    })
    .onCancel(() => {
    })
}

function showDatabaseObjectSelectionFormv2() {
  const dialog = Dialog.create({
    component: ObjectSelectionFormv2,
    componentProps: {
      question: props.question,
    },
  })
    .onOk(({selectedObjects, listNodeIds, arrayRowIds}) => {
      if (props.question.type === 'object') {
        // Question already filled via EditionDialog
        console.log("Question of type object -> already filled via ObjectEditionDialog")
        dialog.hide()
        return
      }
      console.log("User selection made:", selectedObjects, 'listNodeIds', listNodeIds, 'arrayRowIds', arrayRowIds)
      console.log("question mappings", props.question.databaseObjectModelFieldsMapping[0].data)
      fillQuestionWithObject(props.question, selectedObjects, listNodeIds, arrayRowIds)
      if (props.question.role !== '') {
        props.survey.pages.forEach((page) => {
          page.questions.forEach((question) => {
            if (question.role === props.question.role && question != props.question) {
              fillQuestionWithObject(question, selectedObjects, listNodeIds, arrayRowIds)
            }
          })
        })
      }
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function fillQuestionWithObject(question, selectedObjects, listNodeIds, arrayRowIds) {

  // Helper function to traverse the object and to find those of its fields that match the question mappings
  function findMatchingFields(node, listNodeId, arrayRowId, questionMappings = question.databaseObjectModelFieldsMapping[0].data) {
    // console.log("findMatchingFields", node, listNodeId, questionMappings)
    const matchingFields: { variableFieldName: string; value: any }[] = [];
    // If the current node is a list and list nodes have been selected (listNodeIds), find them
    if (node.type == 'LIST' && listNodeId) {
          console.log("check list node", listNodeId)
          node.children.forEach((child) => {
          if (child.id === listNodeId) {
            console.log("found list node", child)
            // Recursively find matching fields in the list node
            let childMatches = findMatchingFields(child, listNodeId, arrayRowId, questionMappings)
            if (childMatches.length > 0) {
              matchingFields.push(...childMatches)
            }
            }
        })
      return matchingFields;
    } else {
      // Check fields in the current node
      if (node.fields) {
        node.fields.forEach(field => {
          // If the current field is an array and array rows have been selected (arrayRowIds), find them
          if (field.type == 'array' && arrayRowId) {
            field.rows.forEach(row => {
              if (row.id === arrayRowId) {
                console.log("found array row", row)
                // Get the values needed as per the question mapping
                matchingFields.push(...findMatchingFields(row, listNodeId, arrayRowId, questionMappings))
              }
            })
          } else if (field.type == 'group_field') {
            matchingFields.push(...findMatchingFields(field, listNodeId, arrayRowId, questionMappings))
          }
          // Check if this field is mapped
          const mapping = questionMappings.find(
            mapping => mapping.databaseObjectModelFieldId === field.modelId
          );
          if (mapping) {
            matchingFields.push({
              variableFieldName: mapping.variableFieldName,
              value: field.value, // Use the field value from the selected object
            });
          }
        });
      }
      // Traverse children
      if (node.children) {
        node.children.forEach(child => {
            matchingFields.push(...findMatchingFields(child, listNodeId, arrayRowId, questionMappings))
        });
      }
      return matchingFields;
    }
  }


  // Fill the question based on its type
  if (question.type === 'text') {
    // For text questions, there should only be one mapping
    const matchingFields = findMatchingFields(selectedObjects[0].definitionJson, listNodeIds ? listNodeIds[0] : undefined, arrayRowIds ? arrayRowIds[0] : undefined);
    if (matchingFields.length > 0) {
      question.answer = { 'value': matchingFields[0].value };
    }
  } else if (question.type === 'object') {
    // UNUSED : object questions are filled via EditionDialog
    // For object questions, map options based on the variableFieldName
    // const matchingFields = findMatchingFields(selectedObjects[0].definitionJson, listNodeIds ? listNodeIds[0] : undefined, arrayRowIds ? arrayRowIds[0] : undefined);
    // console.log("matchingFields", matchingFields)
    // question.options.forEach(option => {
    //   const match = matchingFields.find(
    //     field => field.variableFieldName === option.label
    //   );
    //   if (match) {
    //     option.answer = createTypeAnswer(option.type, match.value)
    //   }
    // });
  } else if (question.type === 'array') {
      // For array questions, create answers according to the case
      // (one row per object if no array / list, one row per list node if no array, one row per array row otherwise)
      let pastAnswerIndex = props.question.answer.value.length
      if (!props.question.answer.dpLink) { props.question.answer.dpLink = {}}
      if (arrayRowIds) {
        arrayRowIds.forEach((arrayRowId, index) => {
          const matchingFields = findMatchingFields(selectedObjects[0].definitionJson, listNodeIds ? listNodeIds[0] : undefined, arrayRowId);
          console.log("matchingFields", matchingFields)
          let answer = {}
          question.options.forEach(option => {
            const match = matchingFields.find(field => field.variableFieldName === option.label);
            if (match) {
              answer[option.id] = match.value
            }
          })
          question.answer.value.push(answer)
          props.question.answer.dpLink[pastAnswerIndex + index] = {
            "databaseObjectId": selectedObjects[0].id,
            "listNodeId": listNodeIds ? listNodeIds[0] : null,
            "arrayRowId": arrayRowId
          }
        })
      }
      else if (listNodeIds) {
        listNodeIds.forEach((listNodeId, index) => {
          const matchingFields = findMatchingFields(selectedObjects[0].definitionJson, listNodeId, undefined);
          console.log("matchingFields", matchingFields)
          console.log(question)
          let answer = {}
          question.options.forEach(option => {
            const match = matchingFields.find(field => field.variableFieldName === option.label);
            if (match) {
              answer[option.id] = match.value
            }
          })
          question.answer.value.push(answer)
          props.question.answer.dpLink[pastAnswerIndex + index] = {
            "databaseObjectId": selectedObjects[0].id,
            "listNodeId": listNodeId
          }
        })
      }
      else {
        selectedObjects.forEach((selectedObject, index) => {
          const matchingFields = findMatchingFields(selectedObject.definitionJson, undefined, undefined);
          console.log("matchingFields", matchingFields)
          let answer = {}
          question.options.forEach(option => {
            const match = matchingFields.find(field => field.variableFieldName === option.label);
            if (match) {
              answer[option.id] = match.value
            }
          })
          question.answer.value.push(answer)
          props.question.answer.dpLink[pastAnswerIndex + index] = {
            "databaseObjectId": selectedObject.id
          }
        })
      }
  } else if (question.type === 'multiarray') {
      let pastAnswerIndex = props.question.answer.value.length
      if (!props.question.answer.dpLink) { props.question.answer.dpLink = {} }
      if (selectedObjects.length === 1) {
        let nonEmptyMappings = question.databaseObjectModelFieldsMapping.filter(mapping => mapping && mapping.databaseObjectModelName)
        for (let [index, arrayRowId] of arrayRowIds.entries()) {
          // Find the mapping with the most non-empty fields
          let bestMapping = null
          let bestMatchingFields: any = []
          for (const mapping of nonEmptyMappings) {
            const matchingFields = findMatchingFields(selectedObjects[0].definitionJson, undefined, arrayRowId, mapping.data);
            if (matchingFields.filter(field => field.value).length > bestMatchingFields.length) {
              bestMapping = mapping
              bestMatchingFields = matchingFields
            }
          }
          if (bestMapping) {
            // Take the options associated with the best mapping
            let option = question.options.find(option => option.type === bestMapping.label)
            let answer = { type: option.type, typeId: option.id, fields: [] }
            option.fields.forEach(suboption => {
              const match = bestMatchingFields.find(field => field.variableFieldName === suboption.label);
              answer.fields.push({ id: suboption.id, value: match?.value, type: suboption.type, label: suboption.label })
            })
            question.answer.value.push(answer)
            props.question.answer.dpLink[pastAnswerIndex + index] = {
              "databaseObjectId": selectedObjects[0].id,
              "arrayRowId": arrayRowId
            }
          }
        }
      } else {
        // OLD VERSION OF MULTIARRAY WITH PP AND PM - TO DELETE
        selectedObjects.forEach((selectedObject, index) => {
          let questionMappings = question.databaseObjectModelFieldsMapping.find(mapping => mapping.databaseObjectModelName === selectedObject.objectModelName).data
          console.log("questionMappings", questionMappings, selectedObject.objectModelName)
          const matchingFields = findMatchingFields(selectedObject.definitionJson, undefined, undefined, questionMappings = questionMappings);
          console.log("question", props.question)
          console.log("matchingFields", matchingFields)
          let answer
          question.options.forEach(option => {
            if (fakeEquals(option.type, selectedObject.objectModelName)) {
              answer = { type: option.type, typeId: option.id, fields: [] }
              option.fields.forEach(suboption => {
                const match = matchingFields.find(field => field.variableFieldName === suboption.label);
                answer.fields.push({ id: suboption.id, value: match?.value, type: suboption.type, label: suboption.label })
              })
            }
          })
          question.answer.value.push(answer)
          props.question.answer.dpLink[pastAnswerIndex + index] = {
            "databaseObjectId": selectedObject.id
          }
        })
      }
  } else if (question.type === 'unisign') {
      let signatureMapping = {
        'data': [
          { variableFieldName: 'email', databaseObjectModelFieldId: 'c35ab66f-e457-4fd8-9758-73682b392ed9' },
          { variableFieldName: 'firstName', databaseObjectModelFieldId: '0ae9baf4-4796-468f-848e-795b0667a5db' },
          { variableFieldName: 'lastName', databaseObjectModelFieldId: 'e11aa337-8b11-4b36-9bcd-66c6788e35d1' }
        ]
      }
      const matchingFields = findMatchingFields(selectedObjects[0].definitionJson, undefined, undefined, signatureMapping.data)
      console.log("matchingFields", matchingFields)
      signatureMapping.data.forEach(option => {
        const match = matchingFields.find(field => field.variableFieldName === option.variableFieldName);
        if (match) {
          question.answer.value[option.variableFieldName] = match.value
        }
      })
  } else if (question.type === 'multisign') {
      for (const selectedObject of selectedObjects) {
        let signatureMapping = {
          'data': [
            { variableFieldName: 'email', databaseObjectModelFieldId: 'c35ab66f-e457-4fd8-9758-73682b392ed9' },
            { variableFieldName: 'firstName', databaseObjectModelFieldId: '0ae9baf4-4796-468f-848e-795b0667a5db' },
            { variableFieldName: 'lastName', databaseObjectModelFieldId: 'e11aa337-8b11-4b36-9bcd-66c6788e35d1' }
          ]
        }
        const matchingFields = findMatchingFields(selectedObject.definitionJson, undefined, undefined, signatureMapping.data)
        console.log("matchingFields", matchingFields)
        let answer = {}
        signatureMapping.data.forEach(option => {
          const match = matchingFields.find(field => field.variableFieldName === option.variableFieldName);
          if (match) {
            answer[option.variableFieldName] = match.value
          }
        })
        question.answer.value.push(answer)
        }
    }
  console.log("Filled question", question)
  return question;
}

function fakeEquals(name, modelName) {
  // Diff between the model name and the name in the multiarray question mapping
  if (name.toLowerCase() == modelName.toLowerCase()) { return true }
  else if (name.toLowerCase().includes('morale') && modelName.toLowerCase().includes('morale')) { return true }
  else if (name.toLowerCase().includes('physique') && modelName.toLowerCase().includes('physique')) { return true }
  else { return false }
}

function createTypeAnswer(type, value) {
  console.log("createTypeAnswer", type, value)
  if (type === 'text' || type === 'number' || type === 'date' || type === 'text_wysiwyg' || type === 'bool' || type === 'choice') {
    if (typeof value !== 'string') {
      // For addresses
      value = Object.values(value).filter(v => v).join(', ')
    }
    return { 'value': value }
  } else {
    return value
  }
}

function hasCompositeLink(question) {
  // console.log("hasCompositeLink", question, props.survey)
  if (props.survey.isCompositeProject == true) {
    return false
  }

  if (question.hasOwnProperty('compositeLink') == false) {
    return false
  }

  if (question.compositeLink !== null && question.compositeLink !== "") {
    return true
  }
  return false
}

function showAddFormArrayModal() {
  let arrayForm = JSON.parse(JSON.stringify(props.question.options))
  let arrayValue = ref({})
  arrayForm.forEach((element: any) => {
    arrayValue.value[element.id] = ''
  })

  const dialog = Dialog.create({
    component: SurveyArrayFormDialog,
    componentProps: {
      question: props.question,
      isEdit: false,
      valueDict: arrayValue.value,
      questionOptions: arrayForm,
      questionMappings: props.question.databaseObjectModelFieldsMapping[0].data,
    },
  })
    .onOk(() => {
      props.question.answer.value.push(JSON.parse(JSON.stringify(arrayValue.value)))
      dialog.hide()
    })
    .onCancel(() => {
    })
}

function showAddFormMultiArrayModal() {
  // Manual mode
  const dialog = Dialog.create({
    component: SurveyMultiArrayFormDialog,
    componentProps: {
      question: props.question,
      isEdit: false,
    },
  })
    .onOk((data) => {
      addToMultiArray(data)
      dialog.hide()
    })
}

function showAIModal() {
  const dialog = Dialog.create({
    component: SurveyAIModal,
    componentProps: {
      question: props.question,
    },
  })
    .onOk((data) => {
      props.question.answer.value = data
      dialog.hide()
    })
}

function addToMultiArray(data: any): void {
  if (props.question.answer.value === undefined) {
    props.question.answer['value'] = []
  }
  const row = JSON.parse(JSON.stringify(data))
  props.question.answer.value.push(row)
}

function parseText(text: string) {
  return text.replaceAll('\n', '<br />')
}
</script>
<template>
  <div class="column q-mb-md">
    <q-card class="my-card" flat bordered>
      <q-card-section>
        <div class="text-subtitle1 text-weight-medium">
          <span v-html="parseText(question.label)" class="q-mr-sm"></span>
          <span v-if="isObjectTypePresentInDatabase() && !hasCompositeLink(question)">
            <q-btn v-if="question.type === 'object' && question.answer.dpLink && question.answer.dpLink.databaseObjectId"
              @click="showEditionForm"  class="q-mb-xs q-mx-xs" size="12px" outline>
              <q-icon name="link" size="xs" class="q-mr-xs"/>Dossier permanent
            </q-btn>
            <q-btn v-else @click="showDatabaseObjectSelectionFormv2" class="q-mb-xs q-mx-xs" size="12px" outline>
              <q-icon name="add" size="xs" class="q-mr-xs"/>Dossier permanent
            </q-btn>
          </span>
          <q-btn v-if="question.type === 'array'" @click="showAddFormArrayModal()" class="q-mb-xs q-mx-xs" size="12px" outline>
            <q-icon name="add" size="xs" class="q-mr-xs"/>Saisie manuelle
          </q-btn>
          <q-btn v-if="question.type === 'multiarray'" @click="showAddFormMultiArrayModal()" class="q-mb-xs q-mx-xs" size="12px" outline>
            <q-icon name="add" size="xs" class="q-mr-xs"/>Saisie manuelle
          </q-btn>
          <q-btn v-if="question.type === 'textformat' && question.aiDescription && question.aiPrompt" @click="showAIModal()" class="q-mb-xs q-mx-xs" size="12px" outline>
            <q-icon name="psychology" size="sm" class="q-mr-xs"/>Rédaction par IA
          </q-btn>
        </div>
        <div v-if="hasCompositeLink(question)">Réponse héritée de l'opération parente</div>

        <div v-if="question.description" class="description-container q-mt-sm q-pa-sm row items-center no-wrap">
          <div>
            <q-icon name="info" size="md" class="info q-mr-sm"></q-icon>
          </div>
          <div>
            <div ref="descriptionText" v-html="parseText(question.description)"
              :class="{
                'description-full': showFullDescription,
                'description-reduced': !showFullDescription,
                'gradient-overlay': descriptionOverflowing && !showFullDescription
              }"
              @resize:deep="checkOverflow"
            ></div>
            <div v-if="descriptionOverflowing" class="expand-btn-container">
              <q-btn @click="showFullDescription = !showFullDescription" flat dense round
              :style="{ marginTop: showFullDescription ? '0.5em' : '-0.5em' }"
              :icon="showFullDescription ? 'expand_less' : 'expand_more'"/>
            </div>
          </div>
        </div>

      </q-card-section>
      <q-separator inset />
      <q-card-section>
        <SurveyQuestionInput :question="question" :complete-question="question" :survey="survey">
        </SurveyQuestionInput>
      </q-card-section>
    </q-card>
  </div>
  <!-- <ObjectSelectionForm :question="question"></ObjectSelectionForm> -->
</template>
<style lang="scss" scoped>
:deep(p) {
  margin-bottom: 0px; /* adjust the value to your desired spacing */
}

.description-container {
  border: solid;
  background-color: #eeeeee;
  border-color: #cfd8dc;
  border-width: 0px 8px;
  border-radius: 4px;

  .info {
    color: rgba(64, 104, 200, 0.48);
  }
}

.description-full {
  max-height: none;
}
.description-reduced {
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  position: relative;
  max-height: 8.5em;
}
.gradient-overlay::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4em;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #eeeeee);
  pointer-events: none; /* Allows clicks to pass through to text */

}
.expand-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
