<script lang="ts" setup>
import { Field } from '@/models/database/Field'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])
function onFieldChange() {
  emit('update:field', props.field)
}
</script>

<template>
  <div>
    {{ field.name }}
  </div>
  <q-option-group v-model="field.value" :options="field.choices?.map(choice => ({label: choice.name, value: choice.name}))"
  type="checkbox" debounce="1000" @update:model-value="onFieldChange"/>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
