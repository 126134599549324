<script lang="ts" setup>
import { Field } from '@/models/database/Field'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])

function updatingModel() {
  // props.field.value = props.field.value.replace(/\s/g, ''); // Automatically strip spaces
  emit('update:field', props.field)
}
</script>

<template>
  <q-input
    v-model="field.value" :label="showLabel ? field.name : undefined"
    :prefix="field.format"
    :rules="[val => /^[\d\s]*(\,\d+)?$/.test(val)]"
    error-message='Utiliser un nombre au format "123,4"'
    hide-bottom-space
    @update:model-value="updatingModel"
  />

</template>

<style lang="scss" scoped>
</style>
