<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { computed } from 'vue';
import { useStore } from '@/store/store';
import { VTour } from '@globalhive/vuejs-tour';
import '@globalhive/vuejs-tour/dist/style.css'

const store = useStore();
const router = useRouter()
const isTourActive = computed(() => store.state.productTour);
const tour = ref();

onMounted(() => {

  watch(isTourActive, (newValue) => {
    if (newValue) {
      console.log('isTourActive', newValue)
      tour.value.startTour();
    }
  });

})

function finishTour() {
  console.log('finishTour')
  store.commit('setProductTour', false)
  tour.value.resetTour()
}

const steps = [
          // {
          //   // Show the client table and select "Clients" in the left menu
          //   target: '[label="Clients"]',
          //   content: `Retrouvez ici vos clients`,
          //   placement: 'bottom',
          //   onBefore: () => {
          //     return new Promise<void>((resolve) => {
          //       router.push('/clients')
          //       resolve();
          //     });
          //   },
          // },
          // {
          //   // Select the first row in the client table
          //   target: 'tbody tr:first-child', // buggy if the table takes time to charge
          //   content: 'Sélectionnez un client pour entrer dans l’espace associé',
          //   placement: 'top',
          // },
          // {
          //   // Select the client and show "Dossiers" in the left menu
          //   target: 'a.q-item:nth-child(2)',
          //   content: 'Pour chaque client, vous pouvez avoir de multiples dossiers',
          //   placement: 'right',
          //   onBefore: async () => {
          //     await nextTick()
          //     const firstRow = document.querySelector('tbody tr:first-child');
          //     const clientId = firstRow?.__vnode.key
          //     const clientName = document.querySelector('tbody tr:first-child > td:nth-child(3)')?.innerText;
          //     console.log('firstRow of client table', clientName, clientId, firstRow)
          //     store.state.currentClient = { id: clientId, name: clientName };
          //     store.state.user.clientId = clientId
          //     await router.push({
          //       name: 'clients/client/projects',
          //       params: { clientId: clientId }, // doesn't have an id yet
          //     })
          //     console.log('router push done')
          //   },
          // },
          // {
          //   // Select the first Dossier in the project table
          //   target: 'tbody tr:first-child', // buggy if the table takes time to charge
          //   content: 'Sélectionner un dossier pour entrer dans l’espace associé',
          //   placement: 'top',
          // },
          // {
          //   target: '.documents-content',
          //   content: 'Les documents du dossier sont centralisés et faciles à consulter',
          //   placement: 'right',
          //   onBefore: async () => {
          //     const firstRow = document.querySelector('tbody tr:first-child');
          //     const projectId = firstRow?.__vnode.key
          //     console.log('firstRow of project table', projectId, firstRow)
          //     await router.push({
          //       name: 'clients/client/projects/project',
          //       params: {
          //         clientId: store.state.user.clientId,
          //         projectId: projectId,
          //       },
          //     })
          //     // Wait for the dossier to load...
          //     await new Promise(r => setTimeout(r, 2000));
          //   },
          // },
          {
            target: '.sub-projects-content > div:nth-child(2) > div:nth-child(1)',
            content: 'Une opération est constituée de plusieurs étapes.',
            placement: 'top',
          },
          {
            target: '.sub-projects-content > div:nth-child(2) > div:nth-child(1)',
            content: 'Effectuez les étapes dans l\'ordre afin d\'avancer dans l\'opération. Vos actes seront générés au fur et à mesure.',
            placement: 'top',
            onBefore: async () => {
              let firstSection = document.querySelector('div.q-tree__node-header');
              console.log(firstSection)
              firstSection?.click()
              await new Promise(r => setTimeout(r, 500))
            },
          },
          {
            target: 'div.q-splitter__panel:nth-child(3)',
            content: 'À chaque étape, retrouvez les instructions ici.',
            placement: 'left',
            onBefore: async () => {
              let firstTask = document.querySelector('div.q-tree__node > div.q-tree__node-collapsible  > div.q-tree__children > div.q-tree__node > div > div > div > div > div > div > div');
              console.log(firstTask)
              firstTask?.click()
              await new Promise(r => setTimeout(r, 500))
            },
          },
          // {
          //   target: 'div.q-splitter__panel:nth-child(3)',
          //   content: 'Ce menu contextuel permet d\'échanger avec vos collaborateurs sur la tâche ou le document sélectionné',
          //   placement: 'right',
          // },
          // {
          //   target: 'div.q-tab:nth-child(3)',
          //   content: 'Vous pouvez envoyer des messages ici, les utilisateurs sélectionnés seront prévenus par email',
          //   placement: 'right',
          //   onBefore: async () => {
          //     await nextTick()
          //     const discussionButton = document.querySelector('div.q-tab:nth-child(2)');
          //     discussionButton.click()
          //   },
          // },
          // {
          //   target: 'div.q-tab:nth-child(2)',
          //   content: 'De même les notifications maintiennent vos collaborateurs informés',
          //   placement: 'right',
          //   onBefore: async () => {
          //     await nextTick()
          //     const notifButton = document.querySelector('div.q-tab:nth-child(3)');
          //     notifButton.click()
          //   },
          // },
          // {
          //   target: '[label="Accès rapide"]',
          //   content: 'Retrouver instantanément les opérations ou documents récents ou favoris',
          //   placement: 'right',
          // }

]

const buttonLabels = { next: "Suivant", back: "Précédent", done: "Fin", skip: "Ignorer"}

</script>

<template>
  <VTour :steps="steps" backdrop noscroll hideBack ref="tour" highlight :buttonLabels="buttonLabels" saveToLocalStorage='never' @on-tour-end="finishTour">
    <template #actions="{ lastStep, nextStep, endTour, _CurrentStep, getNextLabel, props }">
      <div class="vjt-actions">
        <button v-if="!props.hideSkip" type="button" @click.prevent="endTour()">Ignorer</button>
        <button type="button" @click.prevent="nextStep()" v-text="getNextLabel"></button>
      </div>
    </template>
  </VTour>
</template>

<style lang="scss">
@import '@/styles/quasar.variables.scss';

  // The backdrop is the overlay behind the tooltip
  #vjt-backdrop {
    background-color: rgba(0, 0, 0, 0.4);
  }

  #vjt-tooltip {
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid $primary;
    font-size: 14px;
  }

  #vjt-arrow::before {
    background-color: white;
  }

  .vjt-highlight {
    outline: 0px solid rgba(0, 0, 0, 0);
    outline-offset: 4px;
  }
  .vjt-actions button {
    border: 1px solid black;
    color: $primary;
    border-color: $primary;
  }
  .vjt-actions button:hover {
    background-color: $primary;
    color: rgb(241 245 249);
  }

</style>