<script lang="ts" setup>
import { ref } from 'vue'
import { QTableProps } from 'quasar'
import { Question } from '@/models/survey/Question'
import clip from 'text-clipper'

interface Props {
  question: Question
}
const props = withDefaults(defineProps<Props>(), {})

const multisignColumns: QTableProps['columns'] = [
  { name: 'email', label: 'Adresse mail', field: 'email', align: 'left' },
  { name: 'firstName', label: 'Prénom', field: 'firstName', align: 'left' },
  { name: 'lastName', label: 'Nom', field: 'lastName', align: 'left' },
]

const arrayColumns = ref([{}])
if (props.question.type === 'array') {
  props.question.options.forEach((option) => {
    arrayColumns.value.push({
      name: option.id,
      label: option.label,
      field: option.id,
      align: 'left',
    })
  })
}

function getErroColor(question: Question): string {
  if (question.type !== 'choice' && question.type !== 'multi' && question.type !== 'unisign' && question.type !== 'multisign') {
    return 'orange-background'
  } else {
    return 'text-red'
  }
}
</script>
<template>
  <div v-if="question.type === 'text' || question.type === 'date' || question.type === 'choice'">
    <span v-if="question.answer?.value === '' || question.answer?.value === null" :class="getErroColor(question)">Valeur
      non remplie</span>
    <span v-else>{{ question.answer?.value }}</span>
  </div>
  <div v-if="question.type === 'number'">
    <span v-if="question.answer?.value === '' || question.answer?.value === null" :class="getErroColor(question)">Valeur
      non remplie</span>
    <span v-else>{{ question.answer?.value }}</span>
  </div>
  <div v-if="question.type === 'textformat'">
    <span v-if="question.answer?.value === '' || question.answer?.value === null" :class="getErroColor(question)">Valeur
      non remplie</span>
    <div v-else v-html="clip(question.answer?.value, 100, { html: true, maxLines: 5 })"></div>
  </div>
  <div v-else-if="question.type === 'multi'">
    <span v-if="question.answer?.value.length === 0">Aucune option sélectionnée</span>
    <span v-else>{{ question.answer.value.join(' - ') }}</span>
  </div>
  <div v-else-if="question.type === 'bool'">
    {{ question.answer?.value ? 'Oui' : 'Non' }}
  </div>

  <div v-else-if="question.type === 'composable'">
    <div v-for="item in question.answer.value">
      <div v-if="item.selected">
        {{ item.name }}
      </div>
    </div>
  </div>

  <div v-else-if="question.type === 'object'" class="column">
    <div v-for="a in question.options">
      {{ a.label }} :
      <span v-if="a.answer.value == '' || a.answer.value == null" :class="getErroColor(question)">Valeur non
        remplie</span>
      <span v-else-if="a.type !== 'multi'">{{ a.answer.value }} </span>
      <span v-else-if="a.type === 'multi'">{{ a.answer.value.join(' - ') }}
      </span>
    </div>
  </div>
  <div v-else-if="question.type === 'unisign'">
    Email :
    <span :class="getErroColor(question)"
      v-if="question.answer.value.email === '' || question.answer.value.email === null">Valeur non remplie</span>
    <span v-else>{{ question.answer.value.email }}</span>
    <br />
    Prénom :
    <span :class="getErroColor(question)"
      v-if="question.answer.value.firstName === '' || question.answer.value.firstName === null">Valeur non
      remplie</span>
    <span v-else> {{ question.answer.value.firstName }}</span>
    <br />
    Nom :
    <span :class="getErroColor(question)"
      v-if="question.answer.value.lastName === '' || question.answer.value.firstName === null">Valeur non remplie</span>
    <span v-else>{{ question.answer.value.lastName }}</span>
  </div>
  <div v-else-if="question.type === 'array'">
    <q-table flat bordered :rows="question.answer.value" :columns="arrayColumns"
      :hide-bottom="question.answer.value.length < 6"><template #no-data class="">
        <div class="full-width row flex-center q-gutter-sm">
          <span :class="getErroColor(question)"> Valeur non remplie </span>
        </div>
      </template>
    </q-table>
  </div>
  <div v-else-if="question.type === 'multisign'">
    <q-table flat bordered :rows="question.answer.value" :columns="multisignColumns"
      :hide-bottom="question.answer.value.length < 6">
      <template #no-data class="">
        <div class="full-width row flex-center q-gutter-sm">
          <span :class="getErroColor(question)"> Valeur non remplie </span>
        </div>
      </template>
    </q-table>
  </div>
  <div v-else-if="question.type === 'multiarray'">
    <q-list bordered separator v-if="question.answer.value.length > 0">
      <q-item v-for="element in question.answer.value" v-ripple dense
        class="row separator items-center justify-start col-12">
        <q-item-section class="col-2 ellipsis">
          <q-item-label><q-chip class="q-ml-none">{{ element.type }}</q-chip></q-item-label>
        </q-item-section>
        <q-item-section v-for="val in element.fields" class="col">
          <q-field outlined stack-label :label="val.label" dense>
            <template v-slot:control>
              <div class="self-center full-width no-outline" tabindex="0">
                {{ val.value }}
              </div>
            </template>
          </q-field>
        </q-item-section>
      </q-item>
    </q-list>
    <span v-if="question.answer.value.length === 0" :class="getErroColor(question)"> Valeur non remplie</span>
  </div>
</template>
<style lang="scss" scoped>
.orange-background {
  color: #d89010;
}
</style>
