<script setup lang="ts">
import { ref, watch } from 'vue'
import { format } from 'date-fns'

interface Props {
  modelValue: string | null
}

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])

const model = ref()
const proxyDate = ref(format(new Date(), 'dd/MM/yyyy'))

function updateValue() {
  if (props.modelValue && props.modelValue.includes('-')) {
    model.value = format(new Date(props.modelValue), 'dd/MM/yyyy')
    proxyDate.value = format(new Date(props.modelValue), 'dd/MM/yyyy')
  } else {
    model.value = props.modelValue
    proxyDate.value = props.modelValue
  }
}

updateValue()

watch(
  () => props.modelValue,
  () => {
    updateValue()
  },
)

function save() {
  model.value = proxyDate.value
  emit('update:modelValue', model.value)
}

function onChange() {
  emit('update:modelValue', model.value)
}
</script>
<template>
  <q-input outlined v-model="model" class="q-pb-none" mask="##/##/####" v-bind="$attrs" @update:model-value="onChange">
    <template v-slot:prepend>
      <q-icon name="event" class="cursor-pointer"> </q-icon>
    </template>
    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
      <q-date today-btn v-model="proxyDate" mask="DD/MM/YYYY">
        <div class="row items-center justify-end" style="margin-top: -40px;">
          <q-btn v-close-popup label="Annuler" color="primary" flat />
          <q-btn v-close-popup label="OK" color="primary" @click="save" flat />
        </div>
      </q-date>
    </q-popup-proxy>
  </q-input>
</template>
<style lang="scss" scoped></style>
