<script setup lang="ts">
import { ref, watch, provide } from 'vue'

import { useApi } from '@/store/useAppStore'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import UserApi from '@/services/api/core/UserApi'

import { useAgoDatabase } from '@/store/useDatabase'

import DatabaseMenu from './DatabaseMenu.vue'
import DatabaseTree from './DatabaseTree.vue'
import DatabaseItemEditForm from './DatabaseItemEditForm.vue'
import NewDatabaseTree from './NewDatabaseTree.vue'
import NewDatabaseItemEditForm from './NewDatabaseItemEditForm.vue'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { Database } from '@/models/database/Database'
import { ObjectType } from '@/models/database/ObjectType'
import { FieldType } from '@/models/database/FieldType'

import { sas } from '@/models/database/node_types/SAS'
import { entity } from '@/models/database/node_types/Entity'
import { physicalPerson } from '@/models/database/node_types/PhysicalPerson'

interface Props {
  environment: boolean
}

const props = withDefaults(defineProps<Props>(), {
  environment: false,
})

const authenticatedUser = useAuthenticatedUser()
let splitterModel = ref(20)

let demoDatabase = useAgoDatabase()

const userApi: UserApi = useApi()

const treeRef = ref()

provide('treeRef', treeRef)

let databaseJson = null
if (authenticatedUser.clientId != '' && authenticatedUser.clientId != undefined) {
  databaseJson = await userApi.getDatabase()
}

if (databaseJson === null) {
  databaseJson = JSON.parse(JSON.stringify(demoDatabase))
  databaseJson.clientName = '' // TODO: To assign
}

let environmentDatabase = await userApi.getEnvironmentDatabase() // {}; TODO: Manage environment database

provide('environmentDatabase', environmentDatabase)
provide('inEnvironmentDatabase', props.environment)

databaseJson.isEnvironment = false

function uniqBy(a, key) {
  var seen = {}
  return a.filter(function (item) {
    var k = key(item)
    return seen.hasOwnProperty(k) ? false : (seen[k] = true)
  })
}

if (props.environment != false) {
  databaseJson = JSON.parse(JSON.stringify(demoDatabase))
  databaseJson.isEnvironment = true
  environmentDatabase.forEach((clientDatabase) => {
    if (clientDatabase.databaseJson != undefined) {
      clientDatabase.databaseJson.nodes[0].children.forEach((node) => {
        node.clientId = clientDatabase.clientId
      })
      clientDatabase.databaseJson.nodes[1].children.forEach((node) => {
        node.clientId = clientDatabase.clientId
      })
      databaseJson.nodes[0].children = databaseJson.nodes[0].children.concat(
        clientDatabase.databaseJson.nodes[0].children,
      )
      databaseJson.nodes[1].children = databaseJson.nodes[1].children.concat(
        clientDatabase.databaseJson.nodes[1].children,
      )
    }
  })

  // databaseJson.nodes[0].children = [...new Set(databaseJson.nodes[0].children)];
  // databaseJson.nodes[0].children = databaseJson.nodes[0].children.filter(function(item, pos) {
  //     return databaseJson.nodes[0].children.indexOf(item) == pos;
  // })
}

parseDatabase(databaseJson)

saveDatabase(databaseJson)

let database = ref(databaseJson)
let selectedNode = ref(null)

watch(selectedNode, (node) => {
  console.log('Database node selection updated', node, database.value)
})

function parseDatabase(database: Database) {
  database.nodes.forEach((node) => {
    parseNode(node, database)
  })
}

function parseNode(node: Node, database: Database) {
  console.log('Parsing node top level', node)
  updateNode(node, database)
  updateGdprNode(node, database)
  node.children.forEach((childNode) => {
    childNode.parentId = node.id
    parseNode(childNode, database)
  })
}

function updateGdprNode(node, database) {
  if (
    (node.type === NodeType.Folder && node.label === 'Registres RGPD') ||
    (node.type === NodeType.GdprRegisters && node.children.length != 4)
  ) {
    console.log('GDPR: Need to update node')
    node.id = createUUID()
    node.type = NodeType.GdprRegisters
    node.fields = [
      {
        name: 'project',
        value: undefined,
      },
    ]
    node.children = [
      {
        id: createUUID(),
        label: 'Registre des traitements',
        type: NodeType.GdprTreatmentsRegister,
        objectType: 'Registre des traitements',
        fields: [],
        children: [],
      },
      {
        id: createUUID(),
        label: 'Registre des sous-traitants',
        type: NodeType.GdprSubContractorssRegister,
        objectType: 'Registre des sous-traitants',
        fields: [],
        children: [],
      },
      {
        id: createUUID(),
        label: 'Registre des requêtes',
        type: NodeType.GdprRequestsRegister,
        objectType: 'Registre des requêtes',
        fields: [],
        children: [],
      },
      {
        id: createUUID(),
        label: 'Registre des violations',
        type: NodeType.GdprViolationsRegister,
        objectType: 'Registre des violations',
        fields: [],
        children: [],
      },
    ]
  }
}

function updateNode(node: Node, database: Database, nodeObjectTypeName = undefined, baseFieldsData = undefined) {
  if (node.type === NodeType.Object) {
    let nodeObjectType
    if (nodeObjectTypeName === undefined) {
      nodeObjectType = findObjectType(node.objectType, database)
    } else {
      nodeObjectType = findObjectType(nodeObjectTypeName, database)
    }

    if (nodeObjectType?.parent !== undefined) {
      baseFieldsData = node?.fields
      node.fields = []
      updateNode(node, database, nodeObjectType.parent, baseFieldsData)
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    } else {
      if (baseFieldsData === undefined) {
        baseFieldsData = node?.fields
        node.fields = []
      }
      updateNodeFields(node, database, nodeObjectType, baseFieldsData)
    }

    // For new database
    // if (node.children.length >= 1 && node.children[0].label === "Fiche d'identité") {
    //   updateObjectNodeChildren(node)
    // }
  } else if (node.type === NodeType.Folder) {
    let nodeObjectType = findObjectType(node.objectType, database)
    //   node.
    // TODO: Check if necessary
  }
}

function hasChild(node, childLabel): boolean {
  for (let i = 0; i < node.children.length; i++) {
    if (node.children[i].label === childLabel) {
      return true
    }
  }
  return false
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

function updateObjectNodeChildren(node: Node) {
  node.children?.forEach((child) => {
    if (child.label === "Fiche d'identité") {
      const identityChilds = [
        'Statuts',
        'Extraits Kbis',
        'RIB - IBAN',
        'Commissaires aux comptes',
        'Baux',
        'Sociétés filles',
        'Actionnaires',
        "Pacte d'actionnaires",
        'Assemblées générales',
        "Conseils d'administration",
        'Conseils de surveillance',
        'Table de capitalisation',
        'Bilans',
        'Comptes de résultat',
        'Opérations juridiques',
        'Membres du CSE',
        'Délégués du personnel',
        'Membres du conseil stratégique',
        "Contrats d'attribution",
        'Contrats généraux',
        'Conventions de compte courant',
        "Conventions d'honoraires",
        'Délégations de pouvoirs',
        'Registre des mouvements de titres',
        'ODM et CERFAs',
        'Contrats mandataires',
        'Notes de travail',
        'Règlement intérieur',
        'Lettres confidentielles',
        'Suivi des mandats',
        'Organigrammes',
      ]

      identityChilds.forEach((identityChild) => {
        if (!hasChild(node.children[0], identityChild)) {
          const childNode = {
            id: createUUID(),
            label: identityChild,
            type: NodeType.FieldLink,
            objectLinkId: node.id,
            fieldLink: {
              objectId: node.id,
              fields: [identityChild],
            },
            fields: [],
            children: [],
          }
          node.children[0].children.push(childNode)
        }
      })
    }
  })
}

function updateNodeFields(node: Node, database: Database, nodeObjectType, baseFieldsData) {
  const newNodeFields = nodeObjectType?.fields.map((fieldModel) => {
    let fieldValue = getFieldValue(baseFieldsData, fieldModel.label)
    if (fieldModel.type === FieldType.Object) {
      updateNodeObjectField(fieldModel, fieldValue, database)
    }

    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: fieldValue,
    }
  })

  node.fields = node?.fields.concat(newNodeFields)
}

function updateNodeObjectField(fieldModel, fieldValue, database) {
  const objectType = findObjectType(fieldModel.objectTypeName, database)
  const newFields = objectType.fields.map((fieldModel) => {
    return {
      label: fieldModel.label,
      fieldModel: fieldModel,
      value: getFieldValue(fieldValue.fields, fieldModel.label),
    }
  })

  fieldValue.fields = newFields
}

function getFieldValue(baseFieldsData, fieldLabel: string): any {
  let result = null
  baseFieldsData.forEach((field) => {
    if (field.label === fieldLabel) {
      result = field.value
    }
  })
  return result
}

function findObjectType(objectTypeName: string, database: Database): ObjectType {

  if (authenticatedUser.clientId == '103a5fa9-cced-4eea-af9d-feb0e5aa7416') {
    return null
  }

  if (objectTypeName === 'SAS') {
    return sas
  }

  if (objectTypeName === 'Entité') {
    return entity
  }

  if (objectTypeName === 'Personne physique') {
    return physicalPerson
  }

  let result = null

  database.objectTypes.forEach((objectType) => {
    if (objectType.name === objectTypeName) {
      result = objectType
    }
  })
  return result
}

async function saveDatabase(database) {
  if (!database.isEnvironment) {
    let saveResult = await userApi.updateDatabase(database)
  }
}

function nodeRemoved() {
  selectedNode.value = null
}

const nodesHashMap = {}

createNodeHashMap(databaseJson)

provide('nodesHashMap', nodesHashMap)

function addNodesToHashMap(nodes) {
  nodes.forEach((node) => {
    nodesHashMap[node.id] = node
    addNodesToHashMap(node.children)
  })
}

function createNodeHashMap(database) {
  addNodesToHashMap(database.nodes)
}

</script>

<template>
  <q-splitter v-model="splitterModel"
    before-class after-class="fit" style="height: calc(100vh - 54px);">
    <template v-slot:before>
      <div class="q-pa-md">
        <!-- <DatabaseMenu /> -->
        <DatabaseTree :database="database" v-model:selectedNode="selectedNode" />
      </div>
    </template>

    <template v-slot:after>
      <div class="q-pa-md fit">
        <DatabaseItemEditForm :database="database" :environmentDatabase="environmentDatabase" v-model:node="selectedNode"
          @remove:node="nodeRemoved" />
      </div>
    </template>
  </q-splitter>
</template>

<style lang="scss" scoped>
.page {
  padding-left: 0px;
}
</style>
