<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { useDialogPluginComponent } from 'quasar'
import * as XLSX from 'xlsx';
import BaseFileInput from '@/components/base/BaseFileInput.vue';

const props = defineProps({
  tableColumns: Array, // Table column definitions with types and names
});

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const csvData = ref([]);
const step: Ref<number> = ref(1);
const columnMapping: Ref<{ [csvColumn: string]: { name: string, type: string } }> = ref({}); // { csvColumn: { name: 'tableColumnName', type: 'tableColumnType'}, ... }
let tableColumnOptions: Array<{ name: string, type: string }> = []
const choiceMappings = computed(() => {
  // For each choice column, map each of its value
  return Object.fromEntries(props.tableColumns.filter(col => col.type === 'choice').map(col => [col.name, {}]));
})
const uniqueValues = computed(() => {
  // For each column mapped to a choice, find its unique values
  return Object.keys(columnMapping.value).reduce((acc, csvColumn) => {
    const tableColumn = columnMapping.value[csvColumn];
    if (tableColumn && tableColumn.type === 'choice') {
      acc[tableColumn.name] = [...new Set(csvData.value.map(row => row[csvColumn]))];
    }
    return acc;
  }, {});
});

function detectDelimiter(csvContent) {
  const delimiters = [',', ';', '\t'];
  const firstLine = csvContent.split('\n')[0];
  const counts = delimiters.map(delim => (firstLine.split(delim).length - 1));
  return delimiters[counts.indexOf(Math.max(...counts))];
}

function parseFile() {
  // Parse the CSV file as [{'Col1': 'ValueRow1Col1', 'Col2': 'ValueRow1Col2'}, {...}]
  console.log('Uploading ', fileToUpload.value)
  const reader = new FileReader();
  reader.onload = (event) => {
    const fileContent = event.target.result;

    if (
      fileToUpload.value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || // Excel XLSX
      fileToUpload.value.type === 'application/vnd.ms-excel' // Excel XLS
    ) {
      // Handle Excel
      const workbook = XLSX.read(fileContent, { type: 'array', cellDates: true, dateNF: 'dd/mm/yyyy', cellNF: true, });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, blankrows: false, defval: '' }); // Extract rows as a 2D array
      const firstNonEmptyRowIndex = rows.findIndex(row => row.some(cell => cell !== undefined && cell !== null && cell !== ''));
      const headers = rows[firstNonEmptyRowIndex]
      // console.log("Example row:", rows[1])

      csvData.value = rows.slice(firstNonEmptyRowIndex + 1)
        .filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== '')) // Exclude entirely empty rows
        // Convert to each row to {'Col1': 'ValueRow1Col1', 'Col2': 'ValueRow1Col2'}, filtering empty columns
        .map(row => Object.fromEntries(
        headers.map((key, i) => [key, row[i]]).filter(([key]) => key !== undefined && key !== null && key !== '')));

      // console.log(csvData)

      // Define the table columns (including subcolumns for group fields)
      tableColumnOptions = props.tableColumns.slice(1).filter(col => !['file', 'function'].includes(col.type)).flatMap(col =>
        (col.type === 'group_field'
        ? col.fields.map(subCol => ({ name: col.name + ' / ' + subCol.name, type: subCol.type }))
        : { name: col.name, type: col.type }))
      // Define columnMapping, initialized by getting any table column that has the same name as a csv column
      columnMapping.value = Object.fromEntries(Object.keys(csvData.value[0])
        .map((csvColumn) => [csvColumn, tableColumnOptions.find(col => col.name === csvColumn)]));
    } else {
      console.error('Unsupported file type');
      return;
    }
    step.value = 2;
  };

  // Determine how to read the file based on its type
  if (fileToUpload.value.type === 'text/csv') {
    reader.readAsText(fileToUpload.value); // CSV files are text
  } else {
    reader.readAsArrayBuffer(fileToUpload.value); // Excel files are binary
  }
}

function completeMapping() {
  emit('ok', { csvData: csvData.value, columnMapping: columnMapping.value, choiceMappings: choiceMappings.value });
}

function exportModel() {
  const exportCols = props.tableColumns.slice(1)
    .filter((col) => col.type !== 'file')
    .flatMap((col) => {
      if (col.type === 'group_field') {
        return col.fields.map((subColumn) => col.name + ' / ' + subColumn.name)
      }
      return [col.name]
    })

  const worksheetData = [exportCols];
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  worksheet['!cols'] = exportCols.map((col) => ({ wch: Math.min(20, col.length + 5) })) // set column width
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  let blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

  // Download the file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', `Modèle.xlsx`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const fileToUpload: Ref<File> = ref();
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" persistent>
    <div v-if="step === 1">
      <q-card style="min-width: 350px">
          <q-card-section>
              <div class="text-h6">Sélectionner un fichier</div>
          </q-card-section>
          <q-card-section>
            <BaseFileInput label="Sélectionnez le fichier à importer." v-model="fileToUpload" :multiple="false"
            @uploadFile="parseFile" :showImportButton="true"></BaseFileInput>
          </q-card-section>
          <q-card-section class="q-pt-lg">
            <div class="row">
              <q-icon name="o_info" color="grey-8" size="sm" class="col-1"/>
              <p class="col">
                Vous pouvez télécharger un <a href="#" @click.prevent="exportModel()">modèle d'import Excel</a> qui vous aidera à faire correspondre vos colonnes et celles de la plateforme.
              </p>
            </div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Annuler" color="primary" @click="emit('hide')"/>
          </q-card-actions>
      </q-card>
    </div>

    <div v-if="step === 2">
      <q-card>
        <q-card-section>
          <div class="text-h6">Définir les correspondances</div>
          <table style="border-collapse: separate; border-spacing: 1em;">
            <thead>
              <tr>
                <th>Vos colonnes</th>
                <th>Colonne correspondante</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="csvColumn in Object.keys(csvData[0] || {})" :key="csvColumn">
                <td>{{ csvColumn }}</td>
                <td>
                  <q-select v-model="columnMapping[csvColumn]" class="select-button"
                  :options="tableColumnOptions" option-value="name" option-label="name"
                  outlined dense clearable />
                </td>
              </tr>
            </tbody>
          </table>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Retour" color="primary" @click="step = 1"/>
          <q-btn flat label="Suivant" color="primary" @click="step = 3"/>
        </q-card-actions>
      </q-card>
    </div>

    <div v-if="step === 3">
      <q-card style="width: 400px; max-width: 80vw;">
        <q-card-section>
          <div class="text-h6">Définir les correspondances</div>
        </q-card-section>
        <q-card-section>
          <div v-if="Object.keys(uniqueValues).length === 0">
            Votre fichier est prêt à l'import. {{ csvData.length }} lignes vont être créées.
          </div>
          <div v-for="(values, columnName) in uniqueValues" :key="columnName" class="q-mb-md">
            <div>Colonne : {{ columnName }}</div>
            <table>
              <thead>
                <tr>
                  <th>Valeur dans le CSV</th>
                  <th>Valeur correspondante</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value in values" :key="value">
                  <td>{{ value }}</td>
                  <td>
                    <select v-model="choiceMappings[columnName][value]" class="select-choice-button">
                      <option v-for="option in tableColumns.find(col => col.name === columnName).choices" :key="option.name" :value="option.name">
                        {{ option.name }}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="Retour" color="primary" @click="step = 2"/>
          <q-btn flat label="Importer" color="primary" @click="completeMapping"/>
        </q-card-actions>
      </q-card>
    </div>
  </q-dialog>
</template>

<style>
.select-button .q-field__native {
  width: 150px; text-wrap: nowrap; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
}
.select-choice-button {
  width: 150px; text-wrap: nowrap; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}
</style>
