<script setup lang="ts">
import { ref, watch, provide, onBeforeUnmount, onBeforeMount } from 'vue'
import debounce from 'lodash/debounce';
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, Notify } from 'quasar'
import { useStore } from '@/store/store'
import NewDatabaseTree from '@/components/common/database/NewDatabaseTree.vue'
import NewDatabaseItemEditForm from '@/components/common/database/NewDatabaseItemEditForm.vue'
import ApiEntrepriseDialogv2 from '@/components/common/database/ApiEntrepriseDialogv2.vue'

const route = useRoute()
const router = useRouter()
const objectId = ref(route.params.objectId)

let splitterModel = ref(20)

const userApi: UserApi = useApi()

const databaseObject = ref(await userApi.getDatabaseObject(objectId.value))
const databaseObjectModel = await userApi.getDatabaseObjectModel(databaseObject.value.objectModelId)
provide('databaseObjectModel', databaseObjectModel)
const selectedNode = ref()
let parentIds // to expand the parent nodes
const isSaving = ref(false);
const unsavedChanges = ref(false)
const pendingSave = ref(false)
const lastFetchedAt = ref(getCurrentTime())
provide("unsavedChanges", unsavedChanges)

const store = useStore()
store.commit('setPageTitleComponent', `${databaseObject.value.definitionJson.name}`)

// Parse URL hash to find the right node
const parseHash = () => {
  const hash = route.hash.substring(1);
  if (hash.startsWith('item-')) {
    let result = getNodeAndParents(databaseObject.value.definitionJson.children, hash.substring(5));
    selectedNode.value = result?.node || databaseObject.value.definitionJson.children[0];
    parentIds = result?.parentIds;
  } else {
    selectedNode.value = databaseObject.value.definitionJson.children[0];
  }
};
parseHash()

function getNodeAndParents(nodes, id) {
  // Find the node "id" in the "nodes", together with its parents
  for (const node of nodes) {
    if (node.id === id) {
      return { node, parentIds: [] };
    }
    if (node.children && node.children.length > 0) {
      const childResult = getNodeAndParents(node.children, id);
      if (childResult) {
        return {
          node: childResult.node,
          parentIds: [...childResult.parentIds, node.id],
        };
      }
    }
  }
  return null;
}

async function syncWithBackend() {
  // Retrieve the latest version of the object from the backend and update the frontend state
  if (isSaving.value) { return; } // we already sync on save
  try {
    const latestObject = await userApi.getDatabaseObject(objectId.value);

    if (latestObject.updatedAt !== databaseObject.value.updatedAt) {
      // Overwrite frontend state
      Object.assign(databaseObject.value, latestObject);
      ({ node: selectedNode.value, parentIds } = getNodeAndParents(databaseObject.value.definitionJson.children, selectedNode.value.id))
      unsavedChanges.value = false
      Notify.create({
        message: "L'objet a été mis à jour par un autre utilisateur.",
        type: "primary",
      })
    }
    lastFetchedAt.value = getCurrentTime()
  } catch (error) {
    console.error("Failed to sync with backend:", error);
  }
}

onBeforeMount(() => {
  // Set up periodic sync with backend
  const syncInterval = setInterval(syncWithBackend, 30000); // Every 30 seconds.
  onBeforeUnmount(() => {
    clearInterval(syncInterval);
  });
});

const debouncedSave = debounce(async () => { await onSave() }, 2000);

watch(unsavedChanges, (newVal) => {
  console.log('unsavedChanges', newVal)
  if (newVal) { debouncedSave(); }
})

async function onSave() {
  if (isSaving.value) { pendingSave.value = true } // Prevent multiple saves
  isSaving.value = true;
  try {
    const latestObject = await userApi.getDatabaseObject(objectId.value);

    if (latestObject.updatedAt !== databaseObject.value.updatedAt) {
      Object.assign(databaseObject.value, latestObject); // Overwrite frontend state.
      selectedNode.value = databaseObject.value.definitionJson.children.find(child => child.id === selectedNode.value.id)
      Notify.create({message: "L'objet a été mis à jour par un autre utilisateur.", type: "negative",})
    } else {
      // Save user changes.
      const updatedObject = await userApi.updateDatabaseObject(objectId.value, databaseObject.value.definitionJson);
      databaseObject.value.updatedAt = updatedObject.updatedAt;
    }
  } catch (error) {
    console.error("Save failed:", error);
    Notify.create({
      message: "Une erreur est survenue lors de la sauvegarde.",
      type: "negative"
    });
  } finally {
    unsavedChanges.value = false
    lastFetchedAt.value = getCurrentTime()
    setTimeout(() => isSaving.value = false, 2000); // to ensure the saving message is visible
    // If there are pending changes, re-trigger the save process
    if (pendingSave.value) {
      pendingSave.value = false
      debouncedSave()
     }
  }
}

function getCurrentTime() {
  const now = new Date();
  return new Intl.DateTimeFormat('default', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Set to true for 12-hour format
  }).format(now);
}

function onClickImportFromPappers() {
  const dialog = Dialog.create({
    component: ApiEntrepriseDialogv2,
    componentProps: {
      node: databaseObject.value.definitionJson,
    },
  })
    .onOk((rows) => {
      if (rows[0].update) {
        databaseObject.value.definitionJson.name = rows[0].new_value
        databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Dénomination sociale').value = rows[0].new_value // Dénom
      }
      if (rows[1].update) databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Forme juridique').value = rows[1].new_value // Forme juridique
      if (rows[2].update) databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Capital social').value = rows[2].new_value // Capital social
      if (rows[3].update) databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Date de création').value = rows[3].new_value // Date création
      if (rows[4].update) databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Numéro d\'immatriculation').value = rows[4].new_value // Numéro immat
      if (rows[5].update) databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Ville d\'immatriculation').value = rows[5].new_value // Ville immat
      let addressFields = databaseObject.value.definitionJson.children[0].fields.find((f) => f.name === 'Adresse du siège social').fields
      const field6 = addressFields.find((f) => f.name === 'Numéro, rue et compléments')
      if (rows[6].update && field6) field6.value = rows[6].new_value
      const field7 = addressFields.find((f) => f.name === 'Code postal')
      if (rows[7].update && field7) field7.value = rows[7].new_value
      const field8 = addressFields.find((f) => f.name === 'Ville / Commune')
      if (rows[8].update && field8) field8.value = rows[8].new_value

      unsavedChanges.value = true
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function showDeleteDialog() {
  const dialog = Dialog.create({
    title: 'Supprimer',
    message: 'Etes-vous sur de vouloir supprimer cet objet ?',
    style: 'white-space: pre-wrap;',
    cancel: true,
  })
  .onOk(async () => {
    await userApi.deleteDatabaseObject(databaseObject.value.id)
    dialog.hide()
    router.push(`/clients/${store.state.currentClient?.id}/database`)
    Notify.create({
      message: 'Objet supprimé',
      type: 'primary',
    })
  })
  .onCancel(() => {
    dialog.hide()
  })
}

function directToDatabase() {
  if (route.params.clientId) {
    router.push({
      name: 'clients/client/database',
      params: {
        clientId: route.params.clientId.toString(),
      }
    })
  } else {
    router.push({
      name: 'database'
    })
  }
}

function onUpdateLabel(newLabel) {
  databaseObject.value.definitionJson.name = newLabel
}

</script>

<template>
  <Teleport defer to="#pageTitleComponent">
    <div class="row justify-between items-center no-wrap">
      <div class="col-shrink row items-center no-wrap">
        <q-btn outline icon="arrow_back" class="q-pa-sm q-pr-md q-mr-sm col-auto" dense color="black" label="Retour" @click="directToDatabase" />

        <span class="object-name">
          {{ databaseObject?.definitionJson.name }}
        </span>

        <q-btn-dropdown class="q-ml-sm q-mb-xs" flat dense dropdown-icon="o_settings"
          label="" menu-self="top left" menu-anchor="top right">
          <q-list>
            <q-item v-if="databaseObject?.definitionJson?.modelName === 'Personne morale'" clickable v-close-popup @click="onClickImportFromPappers">
              <q-item-section>
                <q-item-label>Importer depuis Pappers</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="showDeleteDialog">
              <q-item-section>
                <q-item-label>Supprimer</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
      <div class="col-auto">
        <div v-if="isSaving" class="text-body2 text-grey-8">
          <q-spinner-dots color="primary" size="sm" /> Sauvegarde...
        </div>
        <div v-else class="text-body2 text-grey-8">
          <q-tooltip :delay="500">Dernière synchronisation: {{ lastFetchedAt }}</q-tooltip>
          <q-icon name="check_circle" color="green" size="xs" /> À jour
        </div>
      </div>
    </div>
  </Teleport>

  <q-splitter v-model="splitterModel" after-class="fit" style="height: calc(100vh - 54px);">
    <template v-slot:before>
      <div class="q-pa-md">
        <NewDatabaseTree :databaseObject="databaseObject" v-model:selectedNode="selectedNode" :parentIds="parentIds"/>
      </div>
    </template>

    <template v-slot:after>
      <div class="q-pa-md fit">
        <NewDatabaseItemEditForm :node="selectedNode" @update:label="onUpdateLabel"/>
      </div>
    </template>
  </q-splitter>
</template>

<style lang="scss" scoped>
.page {
  padding-left: 0px;
}

.object-name {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #4068c8;
  font-weight: 800;
}
</style>
