<script setup lang="ts">
import { inject, ref, watch, onMounted, computed } from 'vue'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import UuidEncoder from 'uuid-encoder'
import { useRouter, useRoute } from 'vue-router'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { toggleFavorite } from '@/utils/favoritesUtils'

interface Props {
  icon?: string | undefined
  link: string
  label: string
  active?: boolean
  custom?: string
  iconSelected?: string
}
const props = defineProps<Props>()

const encoder = new UuidEncoder('base62')
const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()
const userApi: UserApi = useApi()

const favorites = inject('favorites')
const recentProjects = inject('recentProjects')

const visible = ref(true)
const clientId = ref('')

const updateVisibleState = function () {
  if (authenticatedUser) {
    clientId.value = authenticatedUser.clientId
    if (props.custom == 'need_client') {
      if (store.state.currentClient != undefined) {
        visible.value = true
      } else {
        visible.value = false
      }
    }
  }
}

watch(store?.state, (_newStore, _oldStore) => {
  updateVisibleState()
})

updateVisibleState()

async function goToClientFavorite(favorite) {
  store.state.user.clientId = favorite.objectId
  const client = await userApi.getClient(favorite.objectId)
  store.state.currentClient = client
  router.push({
    name: 'clients/client/projects',
    params: { clientId: favorite.objectId },
  })
}

async function goToUserProjectFavorite(favorite) {
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: favorite.userProjectId,
      },
    })
  } else {
    const client = await userApi.getClientForProject(favorite.objectId)
    store.state.user.clientId = client.id
    store.state.currentClient = client
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: client.id,
        projectId: favorite.objectId,
      },
    })
  }
}

async function goToRecentProject(recentProject) {
  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: recentProject.id,
      },
    })
  } else {
    const client = await userApi.getClientForProject(recentProject.id)
    store.state.user.clientId = client.id
    store.state.currentClient = client
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: client.id,
        projectId: recentProject.id,
      },
    })
  }
}

function unsetClientFavorite(favorite) {
  console.log('unsetClientFavorite')
  toggleFavorite(userApi, favorites, 'client', {
    id: favorite.objectId,
    name: favorite.name,
  })
}

function unsetUserProjectFavorite(favorite) {
  console.log('unsetUserProjectFavorite')
  toggleFavorite(userApi, favorites, 'user_project', {
    id: favorite.objectId,
    name: favorite.name,
  })
}

const isProjectActive = computed(() => {
  // Highlight projects on project routes
  return route.params.projectId && route.params.projectId.length ===36 && props.label == 'Dossiers';
})

async function getProjectName() {
  if (isProjectActive.value) {
    let project = await userApi.getProject(route.params.projectId.toString())
    console.log('project name (works)', project.name)
    projectName.value = project.name
  }
  else {
    projectName.value = ''
  }
}
const projectName = ref('')
onMounted(() => {
  getProjectName()
})
watch(route, () => {
  getProjectName()
})

</script>

<template>
  <q-item v-if="visible && link != 'none' && link != 'quick_access'" :to="{ name: link, params: { clientId: clientId } }"
    class="item" :inset-level="0.55">
    <q-item-section avatar>
      <q-icon v-if="icon && !active" color="{active ? '#5469D4' : 'primary'}" :name="icon" />
      <q-icon v-if="iconSelected && active" color="{active ? '#5469D4' : 'primary'}" :name="icon" />
    </q-item-section>
    <q-item-section>{{ label }}</q-item-section>
  </q-item>
  <q-item v-if="isProjectActive" class="item"  :class="{ 'q-router-link--active': isProjectActive }" :inset-level="0.75" >{{ projectName }}</q-item>

  <q-item v-if="visible && link == 'none'" class="item" :inset-level="0.55">
    <q-item-section avatar>
      <q-icon v-if="icon && !active" color="{active ? '#5469D4' : 'primary'}" :name="icon" />
      <q-icon v-if="iconSelected && active" color="{active ? '#5469D4' : 'primary'}" :name="icon" />
    </q-item-section>
    <q-item-section>{{ label }}</q-item-section>
  </q-item>

  <q-item v-if="visible && link == 'quick_access' && custom == 'recent_projects_quick_access'" class="item"
    :inset-level="0.4" style="margin-bottom: 16px; margin-left: 6px; margin-right: -12px">
    <q-item-section>
      <q-item-label
        style="font-weight: 800; margin-top: -4px; margin-bottom: 8px; color: black;">{{ label }}</q-item-label>
      <div v-for="(recentProject, index) in recentProjects.results"
        style="cursor: pointer; margin-top: 0px; margin-bottom: 0px; margin-left: 0px;" @click="goToRecentProject(recentProject)">
        <div class="row no-wrap quick_access_item">
          <div class="col-auto" style="max-width: 180px;">{{ recentProject.name }}</div>
        </div>
        <!-- <q-separator v-if="index != favorites.clients.length - 1" style="" /> -->
      </div>
    </q-item-section>
  </q-item>

  <q-item v-if="visible && link == 'quick_access' && custom == 'clients_quick_access' && favorites.clients.length > 0"
    class="item" :inset-level="0.4" style="margin-bottom: 16px; margin-left: 6px; margin-right: -12px">
    <q-item-section>
      <q-item-label
        style="font-weight: 800; margin-top: -9px; margin-bottom: 8px; color: black;">{{ label }}</q-item-label>
      <div v-for="(clientFavorite, index) in favorites.clients"
        style="cursor: pointer; margin-top: 0px; margin-bottom: 0px; margin-left: 0px;" @click="goToClientFavorite(clientFavorite)">
        <div class="row no-wrap quick_access_item">
          <div class="col-auto" style="max-width: 180px">{{ clientFavorite.name }}</div>
          <div class="col"></div>
          <div class="col-auto">
            <q-btn style="padding-right: 0px" size="sm" flat round dense icon="close" color="grey"
              @click.stop="unsetClientFavorite(clientFavorite)" class="quick_access_remove_button" />
          </div>
        </div>
        <!-- <q-separator v-if="index != favorites.clients.length - 1" style="" /> -->
      </div>
    </q-item-section>
  </q-item>

  <q-item v-if="visible && link == 'quick_access' && custom == 'user_projects_quick_access' && favorites.userProjects.length > 0
    " class="item" :inset-level="0.4" style="margin-left: 6px; margin-right: -12px">
    <q-item-section>
      <q-item-label
        style="font-weight: 800; margin-top: -9px; margin-bottom: 8px; color: black;">{{ label }}</q-item-label>
      <div v-for="(userProjectFavorite, index) in favorites.userProjects"
        style="cursor: pointer; margin-top: 0px; margin-bottom: 0px; margin-left: 0px;"
        @click="goToUserProjectFavorite(userProjectFavorite)">
        <div class="row no-wrap quick_access_item">
          <div class="col-auto" style="max-width: 180px">{{ userProjectFavorite.name }}</div>
          <div class="col"></div>
          <div class="col-auto">
            <q-btn style="padding-right: 0px" size="sm" flat round dense icon="close" color="grey"
              @click.stop="unsetUserProjectFavorite(userProjectFavorite)" class="quick_access_remove_button" />
          </div>
        </div>
        <!-- <q-separator v-if="index != favorites.userProjects.length - 1" style="" /> -->
      </div>
    </q-item-section>
  </q-item>
</template>

<style lang="scss" scoped>
.item {
  font-weight: 400;
  font-size: 13px;
}

.quick_access_item {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 1px;
}

.quick_access_item:hover {
  background-color: #f1f1f1;
}

.quick_access_remove_button {
  visibility: hidden;
}

.quick_access_item:hover .quick_access_remove_button {
  visibility: visible;
}

.q-router-link--active {
  font-weight: 700 !important;
  color: #5469d4;
}

.q-item__section--avatar {
  min-width: 2px;
  padding-right: 0px;
  margin-left: -3px;
}

</style>
