<script setup lang="ts">
import { ref, watch, computed, inject, provide } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { TaskTypeEnum } from '@/models/projects/TaskType.enum.ts'
import { Task } from '@/models/projects/Task'
import clip from 'text-clipper'
import { ProjectKey } from '@/models/symbols'
import BaseEditableText from '@/components/base/BaseEditableText.vue'
import ProjectTaskAction from './task_actions/ProjectTaskAction.vue'
import ProjectTaskProgression from './ProjectTaskProgression.vue'
import ProjectAssigneeSelector from './ProjectAssigneeSelector.vue'
import ProjectDeadlineInput from './ProjectDeadlineInput.vue'
import LogPanel from './log/LogPanel.vue'
import SendboxPanel from './sendbox/SendBoxPanel.vue'
import UpdateDocumentDialog from './task_actions/document_generation/dialog/UpdateDocumentDialog.vue'
import { getNode } from './documents/DocumentsCommon'
import { removeAccents } from '@/utils/stringUtils'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

interface Props {
  task: Task | undefined
  updateNb: any
}
const props = defineProps<Props>()

const project = inject(ProjectKey)
const documentId = inject('documentId')
// const documentSelected = inject("documentSelected");
const selectedNode = inject('documentTreeSelectedNode')
const currentDocument = ref(undefined)
const lateralTab = inject('lateralTab')
const documentTreeNodes = inject('documentTreeNodes')

const route = useRoute()
const router = useRouter()
const store = useStore()
const authenticatedUser = useAuthenticatedUser()

const tab = ref('details')

if (lateralTab.value) {
  tab.value = lateralTab.value
  console.log('lateral panel - new tab', lateralTab.value)
}

console.log('lateral panel - displayed tab', tab.value)

const taskTypeEnumType = TaskTypeEnum
const showFullDescription = ref(false)
const descriptionLength = ref(0)

const timer = ref(-1)
provide('timer', timer)

if (props.task != undefined) {
  descriptionLength.value = props.task.description.replace(/<[^>]+>/g, '').length
  showFullDescription.value = (props.task.description.length < 300) || (props.task.type == taskTypeEnumType.Information)
}

function toggleShowFullDescription() {
  showFullDescription.value = !showFullDescription.value
}

function isTaskReadOnly() {
  // return !props.task.permissions.includes("execute_legacytask");
  return false
}

watch(
  () => props.task,
  () => {
    if (props.task != undefined) {
      descriptionLength.value = props.task.description.replace(/<[^>]+>/g, '').length
      showFullDescription.value = (props.task.description.length < 300) || (props.task.type == taskTypeEnumType.Information)
    }
  },
)

watch(
  () => lateralTab.value,
  () => {
    console.log('lateral tab - query parameter changed', lateralTab.value)
    if (lateralTab.value == undefined) {
      return
    }
    tab.value = lateralTab.value
  },
)

const logPanelUpdateNb = ref(0)

console.log('documentId', documentId.value)
if (documentId.value) {
  console.log('lateral panel - in document', documentId.value)
}

// watch(() => documentId.value, () => {
//   console.log("lateral panel - documentId changed");
//   updateDocument();
// });

watch(
  () => selectedNode.value,
  () => {
    console.log('lateral panel - selectedNode changed')
    updateDocument()
  },
)

watch(
  () => tab.value,
  () => {
    console.log('lateral panel - tab changed', tab.value)
    onTabChanged()
  },
)

function updateDocument() {
  console.log('lateral panel - update document', selectedNode.value)
  if (selectedNode.value == undefined) {
    currentDocument.value = undefined
  } else {
    currentDocument.value = getNode(documentTreeNodes.value, selectedNode.value)
  }
  console.log('currentDocument', currentDocument.value)

  updateDocumentDataTree()
}

const documentDataTreeNodes = ref([])

function createSummaryNodes(nodes) {
  console.log("createSummaryNodes", nodes)
  nodes.forEach((node) => {
    node.label = node.name
    node.content = node.summary
    node.children = createSummaryNodes(node.children)
  })
  return nodes
}

function createNodeForField(field) {
  const node = {
    label: field.title,
    content: "",
    children: [],
  }

  if (field.title == 'data') {
    node.label = "Résumé"
  }


  if (node.label == 'Résumé') {
    if (Array.isArray(field.content)) {
      node.children = createSummaryNodes(field.content)
    } else if (Array.isArray(field.content.data)) {
      node.children = createSummaryNodes(field.content.data)
    } else if (Array.isArray(field.content.fields.data)) {
      node.children = createSummaryNodes(field.content.fields.data)
    }


    // for (const prop in field.content) {
    //   if (Object.prototype.hasOwnProperty.call(field.content, prop)) {
    //     console.log("prop", prop)
    //     console.log('field.content[prop]', field.content[prop])
    //     const childNode = createNodeForSubField(prop, field.content[prop])
    //     node.children.push(childNode)
    //   }
    // }
  } else {
    node.content = field.content
  }

  return node
}

function createNodeForSubField(prop, field) {

  console.log("sub field params", prop, field)

  const node = {
    label: prop,
    content: undefined,
    children: [],
  }

  if (Array.isArray(field)) {
    console.log("sub field array", field)
    for (var i = 0; i < field.length; i++) {
      const prop = field[i]
      const childNode = createNodeForSubField(prop, prop)
      // const childNode = {
      //   label: prop,
      //   content: prop,
      //   children: [],
      // }
      node.children.push(childNode)
    }
  } else if (field != undefined && typeof field === 'object') {
    console.log("sub field object")
    for (const prop in field) {
      console.log("sub field  prop", prop)
      if (Object.prototype.hasOwnProperty.call(field, prop)) {
        console.log("prop", prop)
        console.log('sub field[prop]', field[prop])
        const childNode = createNodeForSubField(prop, field[prop])
        // const childNode = {
        //   label: prop,
        //   content: field[prop],
        //   children: [],
        // }
        node.children.push(childNode)
      }
    }
  } else {
    node.content = field
  }

  console.log("sub field node", node)

  return node
}

function updateDocumentDataTree() {
  documentDataTreeNodes.value = []

  if (currentDocument.value == undefined) {
    return
  }

  const data = currentDocument.value.data
  console.log("data", data)
  if (data == undefined) {
    return
  }

  data.forEach((field) => {
    const node = createNodeForField(field)
    console.log('tree node', node)
    documentDataTreeNodes.value.push(node)
  })
}

watch(
  () => props.updateNb,
  () => {
    logPanelUpdateNb.value = logPanelUpdateNb.value + 1
    timer.value = 60 * 5
  },
)

setInterval(() => {
  if (timer.value >= 0) {
    timer.value = timer.value - 1
  }
}, 1000)

function formatTimer() {
  if (timer.value % 60 >= 10) {
    return Math.floor(timer.value / 60) + ':' + (timer.value % 60)
  } else {
    return Math.floor(timer.value / 60) + ':0' + (timer.value % 60)
  }
}

function onTabChanged() {
  console.log('new tab selected', tab.value)

  const newRouteQuery = {
    lateralTab: tab.value,
  }

  if (route.query.documentId != undefined) {
    newRouteQuery.documentId = route.query.documentId
  }

  if (route.query.taskId != undefined) {
    newRouteQuery.taskId = route.query.taskId
  }

  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  }
}

const notificationsLabel = ref('Notifications')

watch(
  () => timer.value,
  () => {
    if (timer.value == -1) {
      notificationsLabel.value = 'Notifications'
    } else {
      notificationsLabel.value = 'Notifications&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;'
    }
  },
)

// Search through extracted fields

const searchText = ref('')
const treeFilter = ref('')

function search() {
  // if (searchText.value === '') {
  //   expandedNodes.value = [0]
  // } else {
  //   treeRef.value.expandAll()
  // }

  treeFilter.value = searchText.value
}


function resetSearch() {
  searchText.value = ''
  search()
}

// const filteredFields = computed(() => {
//   if (!searchText.value.trim()) {
//     return currentDocument.value.data
//   } else {
//     const searchLowerCase = searchText.value.trim().toLowerCase()
//     return currentDocument.value.data.filter(field => field['title'].toLowerCase().includes(searchLowerCase))
//   }
// })

function applyTreeFilter(node, filter) {
  console.log("applyTreeFilter", node, filter)

  if (node.type === 0) {
    return true
  }

  const nodeTextLowerCase = removeAccents(node.label.toLowerCase()) + " " + removeAccents(node.content.toLowerCase())
  const filterLowerCase = removeAccents(filter.toLowerCase())

  const filterWords = filterLowerCase.split(' ')
  const foundAllWords = filterWords.every((filterWord) => {
    return removeAccents(nodeTextLowerCase).includes(filterWord)
  })

  if (foundAllWords && filter !== '') {
    // expandToNode2(documentTreeNodes, node.id, expandedNodes);
  }

  return foundAllWords
}
</script>

<template>
  <div style="margin-top: 0px">
    <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary" align="justify"
      narrow-indicator>
      <q-tab name="details" label="Détails" no-caps />
      <q-tab name="discussions" label="Discussions" no-caps />
      <q-tab name="notifications" no-caps v-if="authenticatedUser?.userMode !== 'SOLO'">
        <div v-html="notificationsLabel" />
        <q-badge v-if="timer != -1" color="red" floating>{{ formatTimer() }}</q-badge>
      </q-tab>
    </q-tabs>
    <q-separator />

    <q-tab-panels v-model="tab" animated>
      <!-- Details tab when no active document/task -->
      <q-tab-panel name="details" v-if="task == undefined && currentDocument == undefined" class="tab-panel">
        <q-scroll-area style="height: 100%; max-width: 30000px">
          <div class="row no-wrap">
            <!-- <div class="col"></div> -->
            <div class="col-auto"
              style="font-size: 18px; font-weight: 500; color: #42526e; margin-top: 8px; margin-bottom: 16px">
              Dossier
            </div>
            <!-- <div class="col"></div> -->
          </div>
          <div class="row field items-baseline">
            <div class="field-title document-field-title col-auto">
              <p>Nom</p>
            </div>
            <div class="q-pl-sm col">
              {{ project.name }}
            </div>
          </div>
          <!-- <div class="row field items-baseline">
            <div class="field-title col-lg-3 col-md-4">
              <p>Nom du dossier</p>
            </div>
            <div class="q-pl-sm col-lg-9 col-md-8">
              {{ project.name }}
            </div>
          </div> -->
        </q-scroll-area>
      </q-tab-panel>

      <!-- Details tab for a document -->
      <q-tab-panel name="details" v-if="task == undefined && currentDocument != undefined" class="tab-panel">
        <q-scroll-area style="height: 100%; max-width: 30000px">
          <div class="row no-wrap">
            <!-- <div class="col"></div> -->
            <div class="col-auto"
              style="font-size: 18px; font-weight: 500; color: #42526e; margin-top: 8px; margin-bottom: 16px">
              Document
            </div>
            <!-- <div class="col"></div> -->
          </div>
          <div class="row field items-baseline">
            <div class="field-title document-field-title col-auto">
              <p>Nom</p>
            </div>
            <div class="q-pl-sm col">
              {{ currentDocument.name }}
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title document-field-title col-auto">
              <p>Type</p>
            </div>
            <div class="q-pl-sm col">
              <div v-if="currentDocument.type == 1">Dossier</div>
              <div v-if="currentDocument.type == 2">Fichier</div>
              <div v-if="currentDocument.type == 3">Version de fichier</div>
            </div>
          </div>

          <!-- <div v-if="currentDocument.data != null"> -->
          <div v-if="currentDocument && currentDocument.data != null">
            <div class="row no-wrap">
              <div class="col-auto"
                style="font-size: 18px; font-weight: 500; color: #42526e; margin-top: 8px; margin-bottom: 16px">
                Données extraites
              </div>
            </div>
            <div v-if="!currentDocument.data || currentDocument.data.length == 0"
              class="field-title document-field-title">
              Pas de données identifiées
            </div>
            <div v-else class="search-tooltip-container">

              <q-input dense outlined rounded v-model="searchText" @keydown.enter.prevent="search" class="search-input">
                <template v-slot:prepend>
                  <q-icon v-if="searchText === ''" name="search" size="" />
                  <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
                </template>
              </q-input>
              <!-- TODO: put back once field edition is plugged to backend -->
              <!-- <q-btn round icon="info">
                <q-tooltip class="tooltip">Vous pouvez modifier en un clic les données ci-dessous.
                  <br>Validez vos modifications en appuyant sur "Entrée".</q-tooltip>
              </q-btn> -->
            </div>

            <!-- <div v-for="(field, key) in filteredFields" :key="key" class="row field items-baseline">
              <div class="field-title document-field-title col-6 q-py-xs wrap">
                <BaseEditableText v-model="field.title"></BaseEditableText>
              </div>
              <div class="q-pl-sm col-6">
                <BaseEditableText v-model="field.content"></BaseEditableText>
              </div>
            </div> -->

            <q-tree :nodes="documentDataTreeNodes" default-expand-all node-key="label" :filter="treeFilter"
              :filter-method="applyTreeFilter">
              <template v-slot:default-header="prop">
                <div style="padding: 0px; overflow: hidden"
                  class="row no-wrap justify-start items-start content-start full-width">
                  <div>

                    <div class="col-shrink" style="overflow: hidden">
                      <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: 500">
                        {{ prop.node.label }}
                      </div>
                    </div>

                    <!-- space filler -->
                    <div class="col" style="overflow: hidden"></div>

                    <div class="col-auto" style="overflow: hidden">
                      {{ prop.node.content }}
                    </div>
                  </div>
                </div>
              </template>
            </q-tree>

          </div>
        </q-scroll-area>
      </q-tab-panel>

      <!-- Details tab for a task -->
      <q-tab-panel name="details" v-if="task != undefined" class="tab-panel">
        <q-scroll-area style="height: 100%; max-width: 30000px">
          <div class="row no-wrap" v-if="authenticatedUser?.userMode !== 'SOLO'">
            <!-- <div class="col"></div> -->
            <div class="col-auto q-mt-sm"
              style="font-size: 18px; font-weight: 500; color: #42526e;">
              Tâche
            </div>
            <!-- <div class="col"></div> -->
          </div>

          <div class="row field items-baseline q-mt-md">
            <div class="field-title task-field-title col-auto">
              <p>Nom</p>
            </div>
            <div class="q-pl-md col">
              {{ task.name }}
            </div>
          </div>
          <!-- <div class="row field items-baseline">
            <div class="field-title task-field-title col-auto">
              <p>Responsable</p>
            </div>
            <div class="q-pl-md col" style="margin-left: 0px; margin-bottom: 8px">
              <ProjectAssigneeSelector :task="task" :borderless="true"
                :isDisable="project.isReadOnly || task.readOnly || isTaskReadOnly()"></ProjectAssigneeSelector>
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title task-field-title col-auto">
              <p>Échéance</p>
            </div>
            <div class="q-pl-md col" style="margin-left: 0px; margin-bottom: 8px; max-width: 160px">
              <ProjectDeadlineInput :task="task" :borderless="true"
                :isDisable="project.isReadOnly || task.readOnly || isTaskReadOnly()"></ProjectDeadlineInput>
            </div>
          </div>
          <div class="row field items-baseline">
            <div class="field-title task-field-title col-auto">
              <p>Progression</p>
            </div>
            <div class="q-pl-md col" style="margin-bottom: 8px">
              <ProjectTaskProgression :task="task" :isDisable="project.isReadOnly || task.readOnly || isTaskReadOnly()">
              </ProjectTaskProgression>
            </div>
          </div> -->
          <div class="row field" v-if="task.description">
            <div class="field-title task-field-title col-auto">
              <p>Description</p>
            </div>
            <div class="q-pl-md col">
              <div v-html="showFullDescription ? task.description : clip(task.description, 300, { html: true, maxLines: 5 })
                "></div>
              <div v-if="(descriptionLength > 300) && (task.type != taskTypeEnumType.Information)"
                class="q-pl-xs read-more cursor-pointer" color="primary"
                @click="toggleShowFullDescription">
                {{ showFullDescription ? 'voir moins...' : 'voir plus...' }}
              </div>
            </div>
          </div>
          <div class="row field" v-if="task.type !== taskTypeEnumType.Information">
            <ProjectTaskAction :task="task"></ProjectTaskAction>
          </div>
        </q-scroll-area>
      </q-tab-panel>

      <q-tab-panel name="discussions" class="tab-panel">
        <LogPanel :task="task" :updateNb="logPanelUpdateNb"></LogPanel>
      </q-tab-panel>

      <q-tab-panel name="notifications" class="tab-panel">
        <SendboxPanel :updateNb="logPanelUpdateNb" :task="task"></SendboxPanel>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

:deep(.field) {

  // See JIRA ticket detail tab
  .field-title {
    // color: gray;
    color: #42526e;
    // font-family: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif";
    font-size: 0.98em;
    font-weight: 600;
  }

  .document-field-title {
    width: 150px;
    // word-break: break-word;
    overflow-x: scroll;
  }

  .task-field-title {
    width: 80px;
  }

  .read-more {
    color: #3d73c5;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 8px;
  }
}

.tab-panel {
  height: calc(100vh - $header_height - 3px - $tab_panel_header_height);
  background-color: $page_background_color;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.search-tooltip-container {
  display: flex;
  align-items: baseline;
  /* Vertically center the items */
}

// Search input
.search-input {
  display: inline-flex;
  vertical-align: bottom;
  padding-bottom: 8px;
  font-size: 14px;
}

:deep(.search-input .q-field__control) {
  height: 28px;
}

:deep(.search-input .q-field__marginal) {
  height: 28px;
  font-size: 16px;
}

:deep(.search-input .q-field__prepend) {
  padding-left: 2px;
}

:deep(.search-input .q-field__control) {
  padding-left: 2px;
  padding-right: 8px;
}
</style>
