<script lang="ts" setup>
import { ref } from 'vue'
import { Dialog, useDialogPluginComponent } from 'quasar'
import { Node } from '@/models/database/Node'
import { DatabaseObject } from '@/models/database/Database'
import { Question } from '@/models/survey/Question'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ArrayRowEditDialog from '@/components/common/database/dialog/ArrayRowEditDialog.vue'

interface Props {
  // rows: Array<any> | undefined
  // columns: Array<any>
  databaseObject?: any
  type: string
  modelId: string
  title: any
  listNodeIds?: Array<string>
  question: Question
  multiSelect: boolean
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])
const userApi: UserApi = useApi()

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

let result
const selected = ref([])
const rows = ref([])
const columns = ref([])
const arrayViews = ref([])
const selectedView = ref('')

function beforeShow() {
  if (props.type == 'list') {
    rows.value = getListRows(props.databaseObject, props.modelId)
    columns.value = [{ name: 'Objet', label: 'Objet', field: 'name', align: 'left', sortable: true }]
  } else if (props.type == 'array') {
    result = getArrayRows(props.databaseObject.definitionJson, props.modelId, props.listNodeIds)
    rows.value = result.rows
    columns.value = result.columns
    arrayViews.value = result.field.arrayViews
    console.log('arrayViews', arrayViews.value)
  }
}

function getListRows(databaseObject: DatabaseObject, listModelId) {
  // Search for the right lists in a databaseObject
  const rows = []
  const children = databaseObject.definitionJson.children
  for (const child of children) {
    if (child.type === 'LIST' && child.modelId === listModelId && child.children) {
      console.log('Found children', child.children)
      rows.push(...child.children)
    }
  }
  return rows
}

function formatRow(fieldsRow) {
  let newRow = { id: fieldsRow.id }
  fieldsRow.fields.forEach(f => newRow[f.name] = f.value)
  return newRow
}

function getArrayRows(node: Node, arrayModelId, listNodeIds) {
  // Recursively search for the right arrays in an databaseObject
  // Returns {rows, columns}
  for (const field of node.fields) {
    if (field.type === 'array' && field.modelId === arrayModelId && field.rows) {
      console.log('Found rows', field.rows)
      // Reformat rows
      let rows = field.rows.map(formatRow)
      // Extract column definitions
      // let columns = field.rows[0]?.fields.map(f => ({ name: f.name, label: f.name, field: f.name, align: 'left', sortable: true }))
      let columns = field.columns?.map(col => ({ name: col.name, label: col.name, field: col.name, modelId: col.modelId, align: 'left', sortable: true }))
      return {rows, columns, field}
    }
  }
  if (node.children) {
    let recursiveResult
    let childrenNodes = node.children
    // If the node is a list, only search in the right list node according to listNodeIds
    if (node.type == 'LIST' && listNodeIds != undefined) {
      childrenNodes = childrenNodes.filter(child => listNodeIds.includes(child.id))
    }
    for (const childNode of childrenNodes) {
      recursiveResult = getArrayRows(childNode, arrayModelId, listNodeIds)
      if (recursiveResult) {
        return recursiveResult
      }
    }
  }
  return null
}

function onRowClicked(row) {
  console.log("selected item", row.id)
  emit('ok', [row.id])
}

function refreshObject() {
  userApi.getDatabaseObject(props.databaseObject.id).then((response) => {
    Object.assign(props.databaseObject, response) // response.data
    console.log("refreshed object", props.databaseObject)
    beforeShow()
  })

}

function applyView(view) {
  console.log('apply view', view)
  if (!view) {
    rows.value = result.rows
  } else {
    // Apply the row filter from the selected view
    let rowFilters: Array<Record<string, Array<string>>> = view.rowFilters
    rows.value = result.rows.filter((row) => {
      return Object.entries(rowFilters).every(([column, selectedFilters]) => {
        if (!selectedFilters || selectedFilters.length === 0) {
          return true; // No filter applied for this column
        }
        return selectedFilters.includes(row[column]);
      });
    });
  }
}

function addAnswer() {
  console.log("selected items", selected.value)
  emit('ok', selected.value.map(item => item.id))
}

function onNewClick() {
  Dialog.create({
    component: ArrayRowEditDialog,
    componentProps: {
      columns: result.field.columns,
      title: 'Nouvel élément'
    }
  }).onOk(async (newRow) => {
    // Save the new row by adding it to result and updating the object
    result.field.rows.push(newRow)
    userApi.updateDatabaseObject(props.databaseObject.id, props.databaseObject.definitionJson)

    if (props.multiSelect) {
      rows.value.unshift(formatRow(newRow))
      selected.value.push(formatRow(newRow))
    } else {
      onRowClicked(newRow)
    }
  })
}
</script>
<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="title" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="row justify-between">
          <q-btn color="primary" icon="refresh" @click="refreshObject" class="q-mb-md"/>
          <q-select v-if="arrayViews && props.type == 'array'"
            v-model="selectedView"
            :options="Object.entries(arrayViews).map(([name, view]) => ({label: name, value: view}))"
            emit-value map-options
            label="Sélection"
            outlined dense options-dense clearable
            class="selection-button q-mr-lg"
            @update:model-value="applyView"
          />

          <div v-if="databaseObject">
            <q-btn v-if="type == 'array'" color="primary" label="Nouveau" @click="onNewClick()" style="width: 100px" class="q-mr-md"/>
            <!-- Link to the database object -->
            <a :href="`/clients/${databaseObject.clientId}/database/${databaseObject.id}`"
            target="_blank" rel="noopener noreferrer">
              <q-icon name="open_in_new" size="xs" /> {{ databaseObject.definitionJson.name }}
              <q-tooltip anchor="bottom right" self="top middle">
                Ouvrir les dossiers permanents
              </q-tooltip>
            </a>
          </div>
        </div>
        <q-table v-if="multiSelect"
        flat bordered row-key="id" :rows="rows" :columns="columns" selection="multiple" v-model:selected="selected"
        style="width: 100%"/>
        <q-table v-else
        flat bordered row-key="id" :rows="rows" :columns="columns"
        style="width: 100%" @row-click="(evt, row, index) => onRowClicked(row)"/>
      </template>
      <template #actions><q-btn v-if="multiSelect" flat color="primary" label="Ajouter" @click="addAnswer()"></q-btn> </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
