<script setup lang="ts">
import { ref, provide, Ref, onBeforeMount, inject, watch } from 'vue'
import { useStore } from '@/store/store'
import { Dialog, Notify, useQuasar } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import draggable from 'vuedraggable'
import EntityField from './fields/EntityField.vue'
import PagePreviewDialog from './PagePreviewDialog.vue'

interface Props {
    objectModel: any,
    page: any
}

const props = withDefaults(defineProps<Props>(), {
})

const userApi: UserApi = useApi()
const $q = useQuasar()

onBeforeMount(() => {
    const store = useStore()
    store.commit('setPageTitleComponent', "Editeur d'entité des dossiers permanents")

})

const unsavedChanges = inject("unsavedChanges")

const fields = ref([
    {
        id: 'textField',
        type: 'text',
        name: "Texte",
        existingMapping: [],
    },
    {
        id: 'wysiwygTextField',
        type: 'wysiwyg_text',
        name: "Texte WYSIWYG",
        existingMapping: [],
    },
    {
        id: 'numberField',
        type: 'number',
        name: "Nombre",
        existingMapping: [],
    },
    {
        id: 'dateField',
        type: 'date',
        name: "Date",
        existingMapping: [],
    },
    {
        id: 'choiceField',
        type: 'choice',
        name: "Choix simple",
        existingMapping: [],
        choices: [],
    },
    {
        id: 'multipleChoiceField',
        type: 'multi_choice',
        name: "Choix multiple",
        existingMapping: [],
        choices: [],
    },
    {
        id: 'fileField',
        type: 'file',
        name: "Fichier",
    },
    {
        id: 'arrayField',
        type: 'array',
        name: "Tableau",
        columns: [],
    },
    {
        id: 'groupField',
        type: 'group_field',
        name: "Groupe de champs",
        existingMapping: [],
        fields: []
    },
    {
        id: 'functionField',
        type: 'function',
        name: "Fonction",
        existingMapping: [],
        functionName: "Somme",
        arrayField: "Actions",
        columnField: "Nombre",
    }

])

const splitterModel2 = ref(15)

function getCardClass(type) {
    return "drag-cursor"
}

function createUUID() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
}

function onClone(item) {
    console.log("onClone", item)
    const clone = JSON.parse(JSON.stringify(item))
    clone.id = createUUID()

    unsavedChanges.value = true

    return clone
}

function onChange(arg) {
    console.log("onChange", arg)
    unsavedChanges.value = true
}

function showPreview() {
    console.log("showPreview", props.page)
    const dialog = Dialog.create({
        component: PagePreviewDialog,
        componentProps: {
            page: props.page,
        },
    })
        .onOk(async () => {
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

const thumbStyle = {
    right: '1px',
    borderRadius: '5px',
    width: '5px',
    color: 'rgb(71, 71, 71)',
    opacity: '0.2',
    zIndex: 10,
}

function removeParentReferences(node) {
    node.parent = undefined
    if (node.children) {
        node.children.forEach(child => {
            removeParentReferences(child)
        })
    }

    return node
}

async function saveObjectModel() {
    console.log("saveObjectModel", props.objectModel[0])

    let copiedObject = { ...props.objectModel[0] }

    console.log("copiedObject", copiedObject)
    const copiedObjectWithoutReferences = removeParentReferences(copiedObject)

    const loading = $q.loading.show({
      message: 'Enregistrement en cours...\nCela peut prendre ~30 secondes car tous les DPs existants sont mis à jour.',
      boxClass: 'bg-grey-2 text-grey-9',
      spinnerColor: 'primary',
    })

    try {
      await userApi.updateDatabaseObjectModel(props.objectModel[0].id, props.objectModel[0].name, copiedObjectWithoutReferences)

      Notify.create({
        message: `Entité enregistrée avec succès`,
        type: 'primary',
      })

      unsavedChanges.value = false
    } catch (error) {
      Notify.create({
          message: `Erreur lors de l'enregistrement de l'entité: ${error.message}`,
          type: 'negative',
      })
    } finally {
      $q.loading.hide()
    }
}

</script>

<template>
    <Teleport defer to="#pageTitleComponent">
        <div class="row items-center no-wrap">
            <div>
                Editeur d'entité des dossiers permanents
            </div>

            <div class="col-auto">
                <q-btn dense label="Enregistrer" color="primary" @click="saveObjectModel" :disable="!unsavedChanges"
                    style="margin-left: 8px; padding-left: 8px; padding-right: 8px;"></q-btn>
            </div>
        </div>
    </Teleport>

    <div class="q-pa-md">
        <div v-if="!page">
            Veuillez sélectionner une page pour l'éditer
        </div>
        <div v-if="page">
            <q-splitter v-model="splitterModel2" style="height: calc(100vh - 120px)">
                <template v-slot:before>
                    <div style="margin-right: 16px;">
                        <div class="text-h4">Champs</div>
                        <div>
                            <q-list dense class="" style="margin-top: 24px;">
                                <draggable :list="fields" :group="{ name: 'g1', pull: 'clone', put: false }"
                                    ghost-class="ghost" item-key="id" class="q-gutter-sm" :clone="onClone">
                                    <template #item="{ element, index }">
                                        <q-card flat bordered :class="getCardClass(element.type)">
                                            <q-card-section class="q-pa-sm">
                                                {{ element.name }}
                                            </q-card-section>
                                        </q-card>
                                    </template>
                                </draggable>
                            </q-list>
                        </div>
                    </div>
                </template>

                <template v-slot:after>
                    <div style="margin-left: 16px">
                        <div class="text-h4" style="margin-bottom: 16px;">
                            Contenu de la page
                            <q-btn label="Prévisualiser" color="primary" dense @click="showPreview"
                                style="margin-left: 8px; padding-left: 8px; padding-right: 8px;"></q-btn>
                            <!-- <q-btn dense label="Enregistrer" color="primary" @click="saveObjectModel"
                                style="margin-left: 8px; padding-left: 8px; padding-right: 8px;"></q-btn> -->
                        </div>
                        <div>
                            <q-scroll-area style="width: 100%; height: calc(100vh - 176px);" :thumb-style="thumbStyle">
                                <div style="margin-top: 8px; padding-right: 16px;">
                                    <q-list dense class="">
                                        <draggable :list="page.fields" :group="{ name: 'g1', pull: 'clone', put: true }"
                                            ghost-class="ghost" item-key="id" class="q-gutter-sm" :clone="onClone" :filter="'.no-drag'" preventOnFilter
                                            @change="onChange" style="height: calc(100vh - 170px);">
                                            <template #item="{ element, index }">
                                                <EntityField :element="element"></EntityField>
                                            </template>
                                            <template #header>
                                                <div v-if="page.fields.length === 0" style="height: calc(100vh - 160px);">
                                                    Aucun élément pour le moment<br />
                                                    Glissez un premier élément
                                                    <div>
                                                    </div>
                                                </div>
                                            </template>
                                        </draggable>
                                    </q-list>

                                </div>
                            </q-scroll-area>

                        </div>
                    </div>
                </template>
            </q-splitter>

        </div>
    </div>
</template>

<style lang="scss" scoped>
.text-h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
}

.drag-cursor {
    cursor: grab;
}
</style>