<script setup lang="ts">
import ApplicationBar from './header/ApplicationBar.vue'
import NavigationDrawer from './drawer/NavigationDrawer.vue'
import Main from './main/Main.vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

const authenticatedUser = useAuthenticatedUser()
</script>

<template>
  <q-layout view="lHh lpR lFf">
    <ApplicationBar></ApplicationBar>
    <NavigationDrawer v-if="authenticatedUser?.userMode !== 'SOLO'" class="menu-sidebar"></NavigationDrawer>
    <Main></Main>
  </q-layout>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
aside {
  position: fixed !important;
}

.menu-sidebar {
  overflow-x: hidden !important;
}
</style>
