import { NodeType } from "@/models/database/NodeType"

export function removeElementFromParent(node, elementToRemove) {
  console.log("removeElementFromParent", node, elementToRemove)

  let elementList = undefined
  if (node.type == NodeType.Object || node.type == NodeType.Page || node.type == NodeType.List) {
    elementList = node.fields
  } else if (node.type == 'array') {
    elementList = node.columns
  } else if (node.type == 'group_field') {
    elementList = node.fields
  }

  if (elementList == undefined) {
    return
  }

  removeElementFromList(elementList, elementToRemove)
}

function removeElementFromList(elementList, elementToRemove) {
  // console.log("removeElementFromList", elementList, elementToRemove)
  for (let i = 0; i < elementList.length; i++) {
    const element = elementList[i]
    if (element == elementToRemove) {
      elementList.splice(i, 1)
      return
    }
    removeElementFromParent(element, elementToRemove)
  }
}
