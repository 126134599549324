<script async setup lang="ts">
import { ref, watch } from 'vue'
import { useStore } from '@/store/store'
import { useRouter, useRoute } from 'vue-router'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

const store = useStore()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const route = useRoute()

const userApi: UserApi = useApi()

interface Props {
  query: string
}

const props = defineProps<Props>()

store.commit('setPageTitle', 'Résultats de recherche globale')

let results
const clientRows = ref([])
const projectRows = ref([])
const documentRows = ref([])
const databaseObjectRows = ref([])
const loading = ref(false)

// https://medium.com/js-dojo/watch-vue-route-params-query-with-composition-api-97b3c8c402e
watch(
  () => props.query,
  () => {
    updateResults()
  },
)

async function updateResults() {
  loading.value = true
  clientRows.value = []
  projectRows.value = []
  documentRows.value = []
  databaseObjectRows.value = []
  results = await userApi.search(route.query.q)
  // console.log(results)
  clientRows.value = results.clients.results
  projectRows.value = results.projects.results
  documentRows.value = results.projectDocuments.results
  databaseObjectRows.value = results.databaseObjects.results
  loading.value = false
}

function formatProjectName(val, row) {
  // if (row.lopMatterId) {
  //     return row.name + " (LOP " + row.lopMatterId + ")";
  // }

  return row.name
}

function formatDocumentProjectName(val, row) {
  // if (row.userProject.lopMatterId) {
  //     return row.userProject.name + " (LOP " + row.userProject.lopMatterId + ")";
  // }

  return row.userProject.name
}

const clientColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
  },
])

const projectColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
    format: formatProjectName,
  },
  {
    name: 'client',
    required: false,
    label: 'Client',
    align: 'left',
    field: (row) => row.client.name,
    sortable: true,
  },
])

const documentColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.name,
    sortable: true,
  },
  {
    name: 'client',
    required: false,
    label: 'Client',
    align: 'left',
    field: (row) => row.userProject.client.name,
    sortable: true,
  },
  {
    name: 'dossier',
    required: false,
    label: 'Dossier',
    align: 'left',
    field: (row) => row.userProject.name,
    sortable: true,
    format: formatDocumentProjectName,
  },
])

const databaseObjectColumns = ref([
  {
    name: 'name',
    required: true,
    label: 'Nom',
    align: 'left',
    field: (row) => row.definitionJson.name,
    sortable: true,
  },
  {
    name: 'client',
    required: false,
    label: 'Client',
    align: 'left',
    field: (row) => row.client.name,
    sortable: true,
  },
])

function clientRowClicked(event, row) {
  console.log('client', row)
  store.state.currentClient = row
  authenticatedUser.clientId = row.id
  router.push({
    name: 'clients/client/projects',
    params: { clientId: row.id },
  })
}

function projectRowClicked(event, row) {
  console.log('project', row)
  store.state.user.clientId = row.clientId
  store.state.currentClient = row.client
  router.push({
    name: 'clients/client/projects/project',
    params: {
      clientId: row.clientId,
      projectId: row.id,
    },
  })
}

function documentRowClicked(event, row) {
  console.log('project document', row)
  store.state.user.clientId = row.userProject.client.id
  store.state.currentClient = row.userProject.client
  router.push({
    name: 'clients/client/projects/project',
    params: {
      clientId: row.userProject.client.id,
      projectId: row.userProject.id,
    },
    query: {
      documentId: row.id,
      // preview: true
    },
  })
}

function databaseObjectRowClicked(event, row) {
  console.log('database object', row)
  store.state.user.clientId = row.clientId
  store.state.currentClient = row.client
  router.push({
    name: 'clients/client/database/object',
    params: {
      clientId: row.clientId,
      objectId: row.id,
    },
  })
}

updateResults()
</script>

<template>
  <q-page padding class="page">
    <!-- <h5>Clients</h5> -->
    <q-list>
      <div class="q-pa-md">
        <q-table
          title="Clients"
          :rows="clientRows"
          :columns="clientColumns"
          row-key="name"
          @row-click="clientRowClicked"
          no-data-label="Aucun résultat"
          :loading="loading"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
        </q-table>
      </div>
    </q-list>

    <q-list>
      <div class="q-pa-md">
        <q-table
          title="Dossiers"
          :rows="projectRows"
          :columns="projectColumns"
          row-key="name"
          @row-click="projectRowClicked"
          no-data-label="Aucun résultat"
          :loading="loading"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
        </q-table>
      </div>
    </q-list>

    <q-list>
      <div class="q-pa-md">
        <q-table
          title="Documents"
          :rows="documentRows"
          :columns="documentColumns"
          row-key="name"
          @row-click="documentRowClicked"
          no-data-label="Aucun résultat"
          :loading="loading"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
        </q-table>
      </div>
    </q-list>

    <q-list>
      <div class="q-pa-md">
        <q-table
          title="Dossiers permanents"
          :rows="databaseObjectRows"
          :columns="databaseObjectColumns"
          row-key="name"
          @row-click="databaseObjectRowClicked"
          no-data-label="Aucun résultat"
          :loading="loading"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
        </q-table>
      </div>
    </q-list>
  </q-page>
</template>

<style lang="scss" scoped>
h5 {
  margin-bottom: 10px;
}

h4 {
  font-size: 28px;
}
</style>
