<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { Dialog, Notify } from 'quasar'
import { removeElementFromParent } from './utils'
import TextField from './TextField.vue'
import FunctionField from './FunctionField.vue'
import ArrayField from './ArrayField.vue'
import NumberField from './NumberField.vue'
import DateField from './DateField.vue'
import ChoiceField from './ChoiceField.vue'
import MultiChoiceField from './MultiChoiceField.vue'
import WysiwygTextField from './WysiwygTextField.vue'
import FileField from "./FileField.vue"
import ColumnsField from "./ColumnsField.vue"
import GroupField from "./GroupField.vue"

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

const selectedPage = inject("selectedPage")
const unsavedChanges = inject("unsavedChanges")
const showMappingInfo = ref(false)

function removeNode() {
    console.log("removeNode")
    const confirmationText = ''
    const message = `Êtes-vous sûr de vouloir supprimer ce champ ?
    \nCela supprime TOUTES LES DONNNÉES des DPs associées à ce champ pour l'ensemble des utilisateurs. Ne faîtes rien si vous n'êtes pas sur.
    \n\nVeuillez taper "Je suis sur" pour confirmer.`
    const dialog = Dialog.create({
        title: 'Supprimer',
        message: message,
        style: 'white-space: pre-wrap;',
        prompt: {
            model: confirmationText,
            type: 'text',
            isValid: (val: string) => val === 'Je suis sur',
        },
        cancel: true,
        persistent: true,
    })
        .onOk(() => {
            removeElementFromParent(selectedPage.value, props.element)
            unsavedChanges.value = true
            dialog.hide()
        })
        .onCancel(() => {
            dialog.hide()
        })
}

function getFieldTypeName() {
    switch (props.element.type) {
        case "text":
            return "Texte"
        case "wysiwyg_text":
            return "Texte WYSIWYG"
        case "number":
            return "Nombre"
        case "date":
            return "Date"
        case "choice":
            return "Choix"
        case "multi_choice":
            return "Choix multiples"
        case "file":
            return "Fichier"
        case "array":
            return "Tableau"
        case "function":
            return "Fonction"
        case "function_operator":
            return "Opérateur"
        case "columns":
            return "Colonnes"
        case "group_field":
            return "Groupe de champs"
    }
    return props.element.type
}

function onNameChange() {
    console.log("onNameChange")
    unsavedChanges.value = true
}
</script>


<template>
    <q-card flat bordered class="drag-cursor">
        <q-card-section class="q-pa-sm">
            <q-card-section class="q-pa-sm node">
                <div class="row">
                    <div class="col">
                        <span class="input-label">Type : </span><span
                            style="margin-left: 4px;">{{ getFieldTypeName() }}</span>
                    </div>
                    <div class="col-auto"></div>
                    <div class="col">
                        <q-btn icon="o_clear" size="12px" dense @click.stop="removeNode(element)" text-color="grey"
                            class="float-right node-button">
                            <q-tooltip>Supprimer</q-tooltip>
                        </q-btn>
                    </div>
                </div>
                <div>
                    <q-input class="no-drag" v-if="element.type != 'columns'" dense v-model="element.name" style="margin-top: 8px"
                        @update:model-value="onNameChange" debounce="500">
                        <template v-slot:before>
                            <span class="input-label" style="font-size: 14px; color: black;">Nom : </span>
                        </template>
                        <template v-slot:after>
                            <div v-if="element.existingMapping && element.existingMapping.length > 0"
                                style="margin-left: 5px;">
                                <q-icon name="link" size="sm" style="cursor: pointer" @click="showMappingInfo = true">
                                    <q-dialog v-model="showMappingInfo">
                                        <q-card>
                                            <q-card-section>
                                                <div v-for="mapping in element.existingMapping" style="margin-bottom: 5px">
                                                    Template id: {{ mapping.templateId }} - Variable id: {{ mapping.variableId }} <a style="margin-left: 5px" :href="`https://nocode-staging.comeonlaw.com/documents/${mapping.templateId}`" target="_blank">Lien</a>
                                                </div>
                                            </q-card-section>
                                        </q-card>
                                    </q-dialog>
                                </q-icon>
                            </div>
                        </template>
                    </q-input>
                    <div v-if="element.type == 'text'">
                        <TextField :element="element"></TextField>
                    </div>

                    <div v-if="element.type == 'wysiwyg_text'">
                        <WysiwygTextField :element="element"></WysiwygTextField>
                    </div>

                    <div v-if="element.type == 'number'">
                        <NumberField :element="element"></NumberField>
                    </div>

                    <div v-if="element.type == 'date'">
                        <DateField :element="element"></DateField>
                    </div>

                    <div v-if="element.type == 'choice'">
                        <ChoiceField :element="element"></ChoiceField>
                    </div>

                    <div v-if="element.type == 'multi_choice'">
                        <MultiChoiceField :element="element"></MultiChoiceField>
                    </div>

                    <div v-if="element.type == 'file'">
                        <FileField :element="element"></FileField>
                    </div>

                    <div v-if="element.type == 'array'">
                        <ArrayField :element="element"></ArrayField>
                    </div>

                    <div v-if="element.type == 'function'">
                        <FunctionField :element="element"></FunctionField>
                    </div>

                    <div v-if="element.type == 'columns'">
                        <ColumnsField :element="element"></ColumnsField>
                    </div>

                    <div v-if="element.type == 'group_field'">
                        <GroupField :element="element"></GroupField>
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>

<style lang="scss">
.input-label {
    display: inline-block;
    width: 60px;
    // background-color: red;
}
</style>

<style scoped lang="scss">
.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}
</style>