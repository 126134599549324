<script setup lang="ts">
import { ref } from 'vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'

const authenticatedUser = useAuthenticatedUser()
const router = useRouter()

const store = useStore()

const menuImageUrl = ref('/office-manager-disabled.png')
const officeManagerStatus = ref('Déconnecté')
const appDownloadNotAvailable = ref(false)
const installationInstructions = ref(false)
const outlookInstallationInstructions = ref(false)

function downladOfficeManagerApplication() {
  appDownloadNotAvailable.value = true
}

function showInstallationInstructions() {
  installationInstructions.value = true
}

function showOutlookInstallationInstructions() {
  outlookInstallationInstructions.value = true
}
</script>

<template>
  <!-- <q-btn @click="launchApp" label="Launch"></q-btn> -->
  <q-btn round flat class="" size="14px">
    <q-avatar size="28px">
      <img :src="menuImageUrl" />
    </q-avatar>

    <!-- <q-card-section>
                    <div class="text-subtitle1">Etat : {{ officeManagerStatus }}</div>
                </q-card-section>

                <q-separator /> -->

    <!-- <q-card-section> -->
    <!-- <div class="text-subtitle1">Office Manager</div> -->

    <q-menu fit>
      <div class="menu-title">Office Manager Windows / Mac</div>

      <q-list dense style="min-width: 255px">
        <q-item clickable v-close-popup @click="showInstallationInstructions()">
          <q-item-section>
            <q-item-label lines="1">Instructions d'installation</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup
          href="https://app.marvellup.com/static/office_manager/installers/windows/Office%20Manager-0.2.3 Setup.exe">
          <q-item-section>
            <q-item-label lines="1">Télécharger pour Windows (v0.2.3)</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup
          href="https://app.marvellup.com/static/office_manager/installers/mac/Office%20Manager-0.2.3-x64.dmg">
          <q-item-section>
            <q-item-label lines="1">Télécharger pour Mac (v0.2.3)</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

      <div class="menu-title" style="margin-top: 16px">Office Manager Outlook</div>

      <q-list dense style="min-width: 255px">
        <q-item clickable v-close-popup @click="showOutlookInstallationInstructions()">
          <q-item-section>
            <q-item-label lines="1">Instructions d'installation</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>

    <!-- <q-menu>
            <q-card class="card">
                <q-card-actions dense vertical>


                </q-card-actions>
            </q-card>
        </q-menu> -->
  </q-btn>

  <q-dialog v-model="appDownloadNotAvailable">
    <q-card>
      <q-card-section>
        <div class="text-h6">Information</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        L'application Office Manager sera bientôt disponible pour votre système.
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="installationInstructions">
    <q-card>
      <q-card-section>
        <div class="text-h6">Instructions d'installation</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <h1>Mac Os</h1>
        <ul>
          <li>
            <a href="https://app.marvellup.com/static/office_manager/installers/mac/Office%20Manager-0.2.3-x64.dmg">Télécharger
              l'application</a>.
          </li>
          <li>Ouvrir le fichier .dmg.</li>
          <li>Glisser l'icône Office Manager dans le dossier Applications.</li>
          <li>Lancer Office Manager depuis le Launchpad ou le dossier Applications.</li>
          <li>L'icône d'Office Manager apparaît dans la barre en haut de l'écran.</li>
        </ul>
        <br />
        <h1>Windows</h1>
        <ul>
          <li>
            <a href="https://app.marvellup.com/static/office_manager/installers/windows/Office%20Manager-0.2.3 Setup.exe">Télécharger
              l'application</a>.
          </li>
          <li>
            Si vous avez une anti-virus de type Avast, il faut le désactiver durant l'installation. Pour ceci, cliquer
            avec le bouton droit sur l'icône Avast dans la barre des tâches et choisir "Gestion des agents Avast /
            Désactiver pour 10 minutes".<br />
            Sans cela, vous risquez d'avoir une fenêtre indiquant une exception durant l'installation et Avast aura bloqué
            le programme d'installation.
          </li>
          <li>Ouvrir le fichier .exe d'installation.</li>
          <li>
            Une fenêtre apparaît quelques
            secondes pendant l'installation. Une fois la fenêtre d'installation fermée, l'application est installée et
            s'est démarrée automatiquement.
          </li>
          <li>L'icône d'Office Manager apparaît dans la barre des tâche.</li>
        </ul>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="outlookInstallationInstructions">
    <q-card>
      <q-card-section>
        <div class="text-h6">Instructions d'installation</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <ul>
          <li style="margin-bottom: 10px">
            Dans la barre de menu principale d'Outlook,<br />cliquer sur l'icône
            <img src="/outlook_add_ins.png" width="40" style="margin-bottom: -12px" />"Rechercher des compléments"
          </li>
          <li style="margin-bottom: 10px">
            Dans la boîte de dialogue qui s'affiche,<br />rechercher "MarvellUp" dans le champ de recherche en haut à
            droite
          </li>
          <li style="margin-bottom: 10px">Cliquer sur "Ajouter"</li>
          <li style="margin-bottom: 10px">
            Valider le contrat de licence et la politique de confidentialité en cliquant sur "Continuer"
          </li>
          <li style="margin-bottom: 10px">L'add-in s'ajoute</li>
          <li style="margin-bottom: 10px">
            Un petit tutoriel en 2 étapes vous montre où retrouver l'add-in MarvellUp dans l'interface d'Outlook
          </li>
          <li style="margin-bottom: 10px">
            Fermer la boîte de dialogue en cliquant sur la croix en haut à droite.<br />Ne pas cliquer sur "Détacher le
            complément", au risque de devoir le réinstaller
          </li>
          <li style="margin-bottom: 10px">
            Pour vérifier que le complément est bien installé, cliquer sur un mail de votre boîte mail principale,
            <br />puis cliquer sur le bouton "MarvellUp" dans la barre de menu principale.<br />Il faut éventuellement
            cliquer sur l'icône "..." pour y accéder
          </li>
          <li style="margin-bottom: 10px">
            A la première utilisation, connectez-vous avec vos identifiants MarvellUp.
          </li>
          <li style="margin-bottom: 10px">
            L'add-in est également disponible lors de l'édition de mail pour ajouter des pièces jointes.
          </li>
          <li style="margin-bottom: 10px">
            Si vous avez configuré plusieurs adresses mail dans votre Outlook, il vous faudra installer l'add-in pour
            chacun de vos comptes sur lesquels vous voulez qu'il soit actif
          </li>
        </ul>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';

h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0p;
  padding-bottom: 0px;
  line-height: 1rem;
  font-size: 18px;
  font-weight: 500;
}

.text-h6 {
  font-size: 24px;
}

.text-subtitle1 {
  font-size: 14px;
}

.menu-title {
  color: $primary;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  letter-spacing: 0.00937em;
  padding-left: 8px;
}
</style>
