<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

import SearchMenuItem from './SearchMenuItem.vue'
import OfficeManagerMenuItem from './OfficeManagerMenuItem.vue'
import UserMenuItem from './UserMenuItem.vue'
import ClientLogo from './ClientLogo.vue'
// import NotificationsMenuItem from "./NotificationsMenuItem.vue";
import HelpMenuItem from "./HelpMenuItem.vue";
// import VisioConferenceMenuItem from "./VisioConferenceMenuItem.vue";

const store = useStore()
const authenticatedUser = useAuthenticatedUser()

const portal = computed(() => authenticatedUser.portal)
console.log('portal', portal)
</script>

<template>
  <div class="">
    <!-- <VisioConferenceMenuItem v-if="portal == 'CLIENT'"></VisioConferenceMenuItem> -->
    <!-- <NotificationsMenuItem></NotificationsMenuItem> -->
    <!-- <span v-if="portal == 'CLIENT'" class="client_user_container"> -->
    <SearchMenuItem v-if="portal != 'PLATFORM' && authenticatedUser?.userMode !== 'SOLO'"></SearchMenuItem>
    <OfficeManagerMenuItem v-if="authenticatedUser?.userMode !== 'SOLO'"></OfficeManagerMenuItem>

    <span v-if="portal == 'CLIENT'">
      <ClientLogo></ClientLogo>
    </span>
    <span v-if="portal != 'CLIENT'"> </span>

    <UserMenuItem></UserMenuItem>
  </div>
</template>

<style lang="scss" scoped>
.client_user_container {
  display: inline-block;
  /* border: 1px solid #e0dede; */
  border-radius: 5px;
  margin-bottom: -15px;
}

.client_user_container button {
  height: 50px;
  min-height: 1em;
}
</style>
