import Login from '@/pages/common/base/Login.vue'
import LxbLogin from '@/pages/common/base/LxbLogin.vue'
import PasswordForgot from '@/pages/common/base/PasswordForgot.vue'
import ResetPassword from '@/pages/common/base/ResetPassword.vue'
import Logout from '@/pages/common/base/Logout.vue'
import Register from '@/pages/common/base/Register.vue'
import AcceptInvitation from '@/pages/common/base/AcceptInvitation.vue'
import InvitationAccepted from '@/pages/common/base/InvitationAccepted.vue'
import NotFound from '@/pages/common/base/NotFound.vue'
import Onboarding from '@/pages/common/base/Onboarding.vue'

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'base',
  },
  {
    name: 'base/login',
    path: '/login',
    component: Login,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
  {
    name: 'base/lxb-login',
    path: '/lxb-login/:token',
    component: LxbLogin,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
  {
    name: 'base/onboarding',
    path: '/onboarding',
    component: Onboarding,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'base/password_forgot',
    path: '/password_forgot',
    component: PasswordForgot,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
  {
    name: 'base/reset_password',
    path: '/reset_password',
    component: ResetPassword,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
  {
    name: 'base/register',
    path: '/register',
    component: Register,
    meta: {
      guest: true,
    },
  },
  {
    name: 'base/accept_invitation',
    path: '/accept_invitation/:invitation_key',
    component: AcceptInvitation,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
  {
    name: 'base/invitation_accepted',
    path: '/invitation_accepted/:invitation_key',
    component: InvitationAccepted,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
  {
    path: '/logout',
    name: 'base/logout',
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      guest: true,
      layout: 'public',
    },
  },
]

export { routes }
