<script lang="ts" setup>
import { ref, Ref, inject } from 'vue'
import { QTableProps, Dialog, useDialogPluginComponent, DialogChainObject, Notify } from 'quasar'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { DatabaseObject } from '@/models/database/Database'
import { Question } from '@/models/survey/Question'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ObjectChildSelectionDialog from '@/components/common/database/ObjectChildSelectionDialog.vue'
import ObjectEditionDialog from '@/components/common/database/ObjectEditionDialog.vue'
import { createUUID, createObjectStructure, fieldsFromModelFields } from '@/components/common/database/utils'

interface Props {
  question: Question
}
const props = defineProps<Props>()
const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
const userApi: UserApi = useApi()

let databaseObjects
let objectModel
let mapList
let mapArray
let listNodeIds
let arrayRowIds
let multipleObjects
const selectedNode = ref(undefined)
const filter = ref('')
const selected: Ref<Array<Node>> = ref([])
const loading = ref(false);
const rows: Ref<Array<Node>> = ref([])
const columns: QTableProps['columns'] = [
  {
    name: 'label',
    label: 'Objets',
    field: 'label',
    align: 'left',
  },
  {
    name: 'objectType',
    label: 'Types',
    field: 'objectType',
    align: 'left',
  },
  // {
  //   name: 'informations',
  //   label: 'Informations',
  //   field: 'informations',
  //   align: 'right',
  // },
]

async function beforeShow() {
  loading.value = true
  // Get the right object model
  let databaseObjectModelNames
  try {
    const objectModels = await userApi.getDatabaseObjectModels()

    objectModel = objectModels.find((objectModel) => objectModel.definitionJson.name === props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName)
    // Check if the mapping involves a list or an array
    // in the case of multiarray, we use the first mapping (and expect the same array to be used for other mappings)
    const mappingFieldIds = props.question.databaseObjectModelFieldsMapping[0].data.map((d) => d.databaseObjectModelFieldId)
    const results = findListOrArray(objectModel, mappingFieldIds)
    mapList = results.list
    mapArray = results.array
    console.log('mapList', mapList, 'mapArray', mapArray)
    multipleObjects = mapList.length === 0 && mapArray.length === 0 && (props.question.type === 'array' || props.question.type === 'multiarray' || props.question.type === 'multisign')
    databaseObjectModelNames = [props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName]

    // Get the objects
    const objects = await userApi.getDatabaseObjects()
    databaseObjects = objects.filter((databaseObject) => {
      // console.log(databaseObject.objectModelName, props.question.databaseObjectModelFieldsMapping[0].databaseObjectModelName)
      return databaseObjectModelNames.includes(databaseObject.objectModelName)
    })
    console.log('databaseObjects for selection', databaseObjects)
    console.log('mapList', mapList, 'mapArray', mapArray)
    rows.value.push(...databaseObjects)
  }  catch (error) {
    console.error("Error fetching data: ", error);
  } finally {
    loading.value = false;
  }
}

/**
 * Finds the lists or arrays in the object model that are involved in the mappingFieldIds.
 *
 * @param {Object} objectModel - The object model to parse.
 * @param {Array<string>} mappingFieldIds - The IDs of fields that are mapped.
 * @returns {Array<Object>} - Contains "List" nodes and "array" fields.
 */
function findListOrArray(objectModel, mappingFieldIds) {
  const results: {
    list: { id: string; name: string }[];
    array: { id: string; name: string }[]
  } = { list: [], array: [] };

  // Recursive helper function to traverse the object model
  function traverse(node, parentList: any = undefined) {
    if (node.type == 'LIST') { parentList = {id: node.id, name: node.name}; };
    // If we are under a list, check if any of the fields are in the mappingFieldIds
    if (parentList && !results.list.includes(parentList)) {
      const matchingFields = node.fields.filter(field => mappingFieldIds.includes(field.id));
      if (matchingFields.length > 0) {
        results.list.push(parentList);
      }
    }
    // Check if there are any arrays in the current node
    const arrayFields = node.fields.filter(field => field.type == 'array');
    // If there are arrays, check if any of the columns are in the mappingFieldIds
    if (arrayFields) {
      arrayFields.forEach(arrayField => {
        const hasMatchingFields = arrayField.columns.some(field =>
          mappingFieldIds.includes(field.id) ||
          (Array.isArray(field.fields) && field.fields.some(subfield => mappingFieldIds.includes(subfield.id)))
        )
        if (hasMatchingFields) {
          results.array.push({
            id: arrayField.id,
            name: arrayField.name,
          });
          if (parentList && !results.list.includes(parentList)) {
            results.list.push(parentList);
          }
        }
      })
    }
    // Traverse the children of the current node, if any
    if (node.children) {
      node.children.forEach((child) => traverse(child, parentList));
    }
  }
  // Start traversal from the root of the object model
  traverse(objectModel.definitionJson);
  console.log('findListOrArray results', results)

  return results;
}

const dialogInstances: DialogChainObject[] = []; // Track dialog instances to close them

/**
 * After clicking on a database object, opens if needed the dialogs to select list nodes or array rows.
 * Emits the selection to the parent component.
 *
 * @param databaseObject The object that was clicked
 */
async function onRowClicked(databaseObject) {
  // If there is a mapList, open the list dialog.
  if (mapList.length > 0) {
    console.log('mapList', mapList)
    chooseListNode(databaseObject)
  }
  // If there is no mapList but there is a mapArray, open the array dialog directly.
  else if (mapArray.length > 0) {
    console.log('mapArray', mapArray)
    chooseArrayRow(databaseObject)
  }
  // If neither exists, just process the selection
  else {
    processSelection(databaseObject)
  }
}

function processSelection(databaseObject) {
  // Object / subobject has been selected. Propose the edition if it is a question of type "object"
  if (props.question.type === 'object') {
    const dialog = Dialog.create({
      component: ObjectEditionDialog,
      componentProps: {
        databaseObject: databaseObject,
        listNodeId: listNodeIds ? listNodeIds[0] : undefined,
        arrayRowId: arrayRowIds ? arrayRowIds[0] : undefined,
        questionMappings: props.question.databaseObjectModelFieldsMapping[0].data,
        questionOptions: props.question.options
      },
    })
      .onOk(() => {
        emit('ok', {})
        // Update the DP link of the response
        props.question.answer.dpLink = {
          "databaseObjectId": databaseObject.id,
          "listNodeId": listNodeIds ? listNodeIds[0] : null,
          "arrayRowId": arrayRowIds ? arrayRowIds[0] : null
        }
        dialogInstances.forEach(dialog => dialog.hide());
        dialogInstances.length = 0;
        dialog.hide()
      })
  } else {
    emit('ok', { selectedObjects: [databaseObject], listNodeIds, arrayRowIds })
    dialogInstances.forEach(dialog => dialog.hide());
    dialogInstances.length = 0;
  }
}

function chooseListNode(databaseObject) {
  const listDialog = Dialog.create({
      component: ObjectChildSelectionDialog,
      componentProps: {
        databaseObject: databaseObject,
        type: 'list',
        modelId: mapList[0].id,
        title: mapList[0].name,
        question: props.question,
        multiSelect: props.question.type === 'array' && (mapArray.length === 0),
      },
  })
  listDialog.onOk((nodeIds) => {
      listNodeIds = nodeIds
      // If there is an array mapping, open the array dialog
      if (mapArray.length > 0) {
        dialogInstances.push(listDialog);
        chooseArrayRow(databaseObject);
      }
      // Otherwise, we’re done
      else {
        listDialog.hide();
        processSelection(databaseObject);
      }
    })
}

function chooseArrayRow(databaseObject) {
  const arrayDialog = Dialog.create({
    component: ObjectChildSelectionDialog,
    componentProps: {
      databaseObject: databaseObject,
      type: 'array',
      modelId: mapArray[0].id,
      title: mapArray[0].name,
      listNodeIds: listNodeIds,
      question: props.question,
      multiSelect: (props.question.type === 'array' || props.question.type === 'multiarray'),
    },
  })
  arrayDialog.onOk((rowIds) => {
    arrayRowIds = rowIds
    arrayDialog.hide()
    console.log('added arrayRowIds', arrayRowIds)
    processSelection(databaseObject)
  })
}

function onAddButtonClick() {
  emit('ok', { selectedObjects: selected.value })
}

async function onNewObjectClick() {
  // Ask for the name
  Dialog.create({
    prompt: {
      model: '',
      isValid: (val) => val.length > 0,
      type: 'text', // optional
      label: 'Nom de la nouvelle ' + objectModel.name,
      autofocus: true
    },
    cancel: true,
    persistent: false
  }).onOk(async (name) => {

    // Open dialog to create a new object. Only ask for the info needed in that question
    const id = createUUID()
    const newData: DatabaseObject = {
      objectModelId: objectModel.id,
      id: id,
      definitionJson: {
        name: name,
        id: id,
        modelName: objectModel.name,
        type: "OBJECT",
        fields: fieldsFromModelFields(objectModel.definitionJson.fields),
        children: createObjectStructure(objectModel.definitionJson)
      },
    }
    let newObject = await userApi.createDatabaseObject(newData.id, newData.objectModelId, newData.definitionJson);
    Notify.create(`${objectModel.name} créée avec succès`)
    rows.value.unshift(newObject)
    if (!multipleObjects) {
      onRowClicked(newObject)
    } else {
      selected.value.push(newObject)
    }
  })
}

function objectFilter(rows) {
  return rows.filter(row => row.definitionJson.name.toLowerCase().includes(filter.value.toLowerCase()))
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <BaseDialog :title="question.label || 'Sélection'" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()">
      <template #body>
        <div class="col">
          <q-table v-if="!multipleObjects" :rows="rows" :columns="columns" row-key="id" flat bordered
            v-model:selected="selectedNode" :filter="filter" :filter-method="objectFilter" hide-bottom filt
            :loading="loading">
            <template #bottom></template>
            <template #body="props">
              <q-tr :props="props" class="cursor-pointer">
                <q-td key="label" :props="props" @click="onRowClicked(props.row)">
                  {{ props.row.definitionJson.name }}
                </q-td>
                <q-td key="objectType" :props="props" @click="onRowClicked(props.row)">
                  {{ props.row.objectModelName }}
                </q-td>
              </q-tr>
            </template>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template #top-right>
              <q-btn color="primary" label="Nouveau" @click="onNewObjectClick()" />
            </template>
          </q-table>
          <q-table v-if="multipleObjects" :filter="filter" :rows="rows" :columns="columns" row-key="id" flat bordered selection="multiple"
            v-model:selected="selected">
            <template #body="props">
              <q-tr :props="props" class="cursor-pointer">
                <q-td auto-width>
                  <q-checkbox v-model="props.selected" color="primary"/>
                </q-td>
                <q-td key="label" :props="props">
                  {{ props.row.definitionJson.name }}
                </q-td>
                <q-td key="objectType" :props="props">
                  {{ props.row.objectModelName }}
                </q-td>
                <q-td key="informations" :props="props">
                  <q-btn flat round color="primary" icon="info_outline">
                    <q-popup-proxy>
                      <q-banner class="q-pa-none">
                        <q-list dense>
                          <div v-for="field in props.row.fields">
                            <q-item v-if="typeof field.value !== 'object' && field.value">
                              <q-item-section side>{{ field.name }}</q-item-section>
                              <q-item-section> {{ field.value }}</q-item-section>
                            </q-item>
                          </div>
                        </q-list>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </q-td>
              </q-tr>
            </template>
            <template #top-left>
              <q-input filled debounce="300" color="primary" dense class="filter-input" v-model="filter">
                <template #prepend>
                  <q-icon name="search" />
                </template>
              </q-input>
            </template>
            <template #top-right>
              <q-btn color="primary" label="Nouveau" @click="onNewObjectClick()" />
            </template>
          </q-table>
        </div>
      </template>
      <template #actions>
        <q-btn v-if="multipleObjects" :disable="selected.length === 0" flat label="Ajouter" color="primary" @click="onAddButtonClick" v-close-popup />
      </template>
    </BaseDialog>
  </q-dialog>
</template>

<style lang="scss" scoped>
.cell {
  padding: 0px !important;
}

.filter-input {
  width: 300px;
}

.tree {
  overflow: scroll;
}

.sticky-header-table {

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: #e4e4e4;
  }
}

.sticky-header-column-table {
  /* specifying max-width so the example can
    highlight the sticky column on any browser window */
  max-width: 100%;

  td:first-child {
    /* bg color is important for td; just specify one */
    background-color: #e4e4e4 !important;
  }

  tr th {
    position: sticky;
    /* higher than z-index for td below */
    z-index: 2;
    /* bg color is important; just specify one */
    background: #fff;
  }

  /* this will be the loading indicator */
  thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    /* highest z-index */
    z-index: 3;
  }

  thead tr:first-child th {
    top: 0;
    z-index: 1;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
  }
}
</style>
