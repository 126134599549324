<script setup lang="ts">
import { ref, provide } from 'vue'
import { useRouter } from 'vue-router'
import { Dialog } from 'quasar'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import { DatabaseObject } from '@/models/database/Database'
import {createUUID, createObjectStructure, fieldsFromModelFields, defaultValues} from '@/components/common/database/utils'

const store = useStore()
store.commit('setPageTitle', 'Dossiers permanents')
const router = useRouter()
const userApi: UserApi = useApi()

const columns = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    sortable: true,
    align: 'left',
  },
  {
    name: 'type',
    label: 'Type',
    field: 'objectModelName',
    sortable: true,
    align: 'left',
  },
  {
    name: 'dateCreated',
    label: 'Date de création',
    field: 'createdAt',
    sortable: true,
    align: 'left',
  },
  {
    name: 'dateUpdated',
    label: 'Date de mise à jour',
    field: 'updatedAt',
    sortable: true,
    align: 'left',
  },
]
const slotedColumns = ref([
  {
    name: 'type',
  },
])
const typeColors = {
  'Personne physique': 'orange-6',
  'Personne morale': 'red-6',
  'CPAM': 'brown-6',
  'Tribunal': 'purple-6',
}

const databaseObjects = await userApi.getDatabaseObjects()
// TODO: optimize by not querying full objects (requires to move name and model name outside definitionJson)
const rows = databaseObjects.map((databaseObject) => ({
  id: databaseObject.id,
  name: databaseObject.definitionJson['name'],
  objectModelName: databaseObject.definitionJson['modelName'],
  createdAt: new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })
    .format(new Date(databaseObject.createdAt)),
  updatedAt: new Intl.DateTimeFormat('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })
    .format(new Date(databaseObject.updatedAt)),
}))

function onRowClick(event, row) {
  router.push(`/database/${row.id}`)
}

let databaseObjectModels = await userApi.getDatabaseObjectModels()

async function onAddClick() {
  Dialog.create({
    title: 'Créer un objet',
    ok: 'Créer',
    cancel: 'Annuler',
    options: {
      // model: {},
      items: databaseObjectModels.map((model) => ({
        label: model.name,
        value: model,
      }))
    },
  }).onOk(async objectModel => {
    let objectId = await createNewObject(objectModel)
    router.push(`/database/${objectId}`)
  })
}

async function createNewObject(objectModel) {
  console.log('createNewObject', objectModel)
  const id = createUUID()
  const newObject: DatabaseObject = {
    objectModelId: objectModel.id,
    id: id,
    definitionJson: {
      name: 'Nouveau "' + objectModel.name + '"',
      id: id,
      modelName: objectModel.name,
      type: "OBJECT",
      fields: fieldsFromModelFields(objectModel.definitionJson.fields),
      children: createObjectStructure(objectModel.definitionJson)
    },
  }
  console.log('createNewObject', newObject)
  let result = await userApi.createDatabaseObject(newObject.id, newObject.objectModelId, newObject.definitionJson)
  console.log('---', result.id, result.definitionJson.id, newObject.definitionJson.id)
  return result.id
}

</script>

<template>
  <q-page padding class="page">
      <div>
        <BaseTable
          class="q-ma-md"
          :columns="columns"
          :rows="rows"
          :sloted-columns="slotedColumns"
          :enableActions="false"
          @add-item-click="onAddClick"
          @on-row-click="onRowClick"
        >
          <template v-slot:body-cell-type="props">
            <q-td :props="props.item">
              <q-badge :color="typeColors[props.item.row.objectModelName]" rounded class="q-px-md q-py-sm">
              {{ props.item.row.objectModelName }}
              </q-badge>
            </q-td>
          </template>
        </BaseTable>
      </div>
  </q-page>
</template>

<style lang="scss" scoped>
.page {
  padding: 0px;
}
</style>
