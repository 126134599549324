import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import BackOfficeApi from '@/services/api/BackOfficeApi'
import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'
import { Database } from '@/models/database/Database'
import { demoDatabase } from '@/models/database/demo/DemoDatabase'
import { demoAtDatabase } from '@/models/database/demo/DemoAtDatabase'
import { demoAgoDatabase } from '@/models/database/demo/DemoAgoDatabase'

const api = new BackOfficeApi()

export interface State {
  pageTitle: string
  pageTitleComponent: any
  pageSubtitle: string
  pageTitleLoading: boolean
  user: AuthenticatedUser | null
  api: any
  routes: any
  currentClient: any
  database: Database
  atDatabase: Database
  agoDatabase: Database
  productTour: any
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  modules: {},
  state: {
    pageTitle: '',
    pageTitleComponent: undefined,
    pageSubtitle: '',
    pageTitleLoading: false,
    user: null,
    api,
    routes: {},
    currentClient: undefined,
    database: demoDatabase,
    atDatabase: demoAtDatabase,
    agoDatabase: demoAgoDatabase,
    productTour: false,
  },
  mutations: {
    setPageTitle(state, pageTitle) {
      state.pageTitle = pageTitle
      state.pageTitleComponent = undefined
    },
    setPageTitleComponent(state, pageTitleComponent) {
      state.pageTitleComponent = pageTitleComponent
      state.pageTitle = undefined
    },
    setPageSubtitle(state, pageSubtitle) {
      state.pageSubtitle = pageSubtitle
    },
    setPageTitleLoading(state, pageTitleLoading) {
      state.pageTitleLoading = pageTitleLoading
    },
    setRoutes(state, routes) {
      state.routes = routes
    },
    async setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
      state.api.setUser(user)
    },
    setDatabase(state, database) {
      state.database = database
    },
    setAtDatabase(state, database) {
      state.atDatabase = database
    },
    setAgoDatabase(state, database) {
      state.agoDatabase = database
    },
    setProductTour(state, productTour) {
      state.productTour = productTour
    }
  },
})

export function useStore() {
  return baseUseStore(key)
}
