<script setup lang="ts">
import { Ref, ref } from 'vue'
import { useQuasar } from 'quasar'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useApi'
import { useRouter } from 'vue-router'
import UserApi from '@/services/api/core/UserApi'
import OnboardingSubProjectTree from './OnboardingSubProjectTree.vue'
import { Client } from '@/models/core/Client'
import { Project } from '@/models/projects/Project'

const $q = useQuasar()
const store = useStore()
const router = useRouter()
const userApi: UserApi = useApi()
const currentStep = ref(1)
const selectedDomain = ref('')
const selectedSubProject = ref(null)
const subprojectName = ref('')
const email = ref('')

const domains = [
  { id: 'societe', label: 'Droit des sociétés', catalogLabel: 'Droit des sociétés', icon: 'business' },
  { id: 'travail', label: 'Droit du travail', catalogLabel: 'Social', icon: 'work' },
]

const steps = ref([
  { message: 'Création de l\'opération...', done: false },
  { message: 'Configuration des modèles...', done: false },
  { message: 'Chargement des questionnaires...', done: false },
  { message: 'Préparation des documents...', done: false },
  { message: 'Finalisation...', done: false }
])

const updateLoadingMessage = () => {
  const template = `
    <div style="padding-bottom: 16px;">
      <style>
        .step-list { list-style: none; padding: 0; margin: 0; }
        .step-item { display: flex; align-items: center; padding: 8px 0; }
        .step-icon { width: 24px; margin-right: 16px; display: flex; justify-content: center; }
        .step-text { font-size: 14px; }
        .step-done { color: #9E9E9E; }
        .step-pending { color: #1976D2; }
        @keyframes spin {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
        .loading-dot {
          animation: spin 2s linear infinite;
          font-size: 20px;
        }
        .info-text {
          margin-top: 24px;
          font-size: 14px;
          color: #616161;
          line-height: 1.5;
          text-align: left;
        }
      </style>
      <div class="text-h6 q-mb-md">Veuillez patienter...</div>
      <ul class="step-list">
        ${steps.value.map(step => `
          <li class="step-item">
            <div class="step-icon">
              ${step.done
                ? '<i class="material-icons step-done">check</i>'
                : '<i class="material-icons loading-dot step-pending">autorenew</i>'
              }
            </div>
            <div class="step-text ${step.done ? 'step-done' : ''}">
              ${step.message}
            </div>
          </li>
        `).join('')}
      </ul>
    </div>
  `

  $q.loading.show({
    message: template,
    html: true,
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerSize: 0
  })
}

let messageInterval: NodeJS.Timeout | null = null
let currentIndex = 0

const startProgressMessages = () => {
  steps.value.forEach(step => step.done = false)
  updateLoadingMessage()

  messageInterval = setInterval(() => {
    if (currentIndex < steps.value.length - 1) {
      steps.value[currentIndex].done = true
      currentIndex++
      updateLoadingMessage()
    }
  }, 5000)
}

const stopProgressMessages = () => {
  if (messageInterval) {
    clearInterval(messageInterval)
    messageInterval = null
    steps.value.forEach(step => step.done = true)
    updateLoadingMessage()
  }
}

const goToStep = (step: number) => {
  if (step >= 1 && step <= 3) {
    currentStep.value = step
  }
}

const selectDomain = (domain: string) => {
  selectedDomain.value = domain
  goToStep(2)
}

const selectSubProject = (subProject: any) => {
  selectedSubProject.value = subProject
  subprojectName.value = subProject.label
  goToStep(3)
}

const createProject = async () => {
  if (!selectedSubProject.value || !subprojectName.value) {
    return
  }
  try {
    startProgressMessages()

    let client: Client = await userApi.createClient({
      name: 'Mon client'
    })

    store.state.user!.clientId = client.id
    let project: Project = await userApi.createProject({
      name: 'Mon dossier',
      productId: '307fdeb7-a804-4b8c-8c0f-3ea8f4ab6ea1',
      projectTemplateIds: [],
    })

    let subProjects = await userApi.createSubProjects({
      userProjectId: project.id,
      projectTemplateIds: [selectedSubProject.value.id]
    })

    stopProgressMessages()
    if (currentIndex == 0) {
      await new Promise(resolve => setTimeout(resolve, 3000))
    } else {
      await new Promise(resolve => setTimeout(resolve, 500))
    }

    $q.loading.hide()
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: store.state.user!.clientId,
        projectId: project.id,
      },
      query: {
        onboarding: 'true'
      }
    })
  } catch (error) {
    stopProgressMessages()
    $q.loading.hide()
    console.error('Error creating project:', error)
  }
}

store.commit('setPageTitle', 'Onboarding')
</script>

<template>
  <q-page padding>
    <q-stepper
      v-model="currentStep"
      ref="stepper"
      header-nav
      color="primary"
      :contracted="$q.screen.lt.sm"
      class="full-stepper"
    >
      <q-step
        :name="1"
        :title="currentStep > 1 ? `Domaine: ${selectedDomain}` : 'Domaine'"
        icon="domain"
        :done="currentStep > 1"
      >
        <!-- Step 1: Domain Selection -->
        <div v-if="currentStep === 1">

          <div class="text-h5 text-center q-mb-xl">
            Découvrez notre plateforme en exécutant une opération<br>
            juridique offerte dans le domaine de votre choix.
          </div>

          <!-- <q-separator spaced inset style="border-top: 2px solid #ccc; margin-bottom: 30px;" /> -->

          <div class="row justify-center q-gutter-xl">
            <q-btn
              v-for="domain in domains"
              :key="domain.id"
              class="domain-button"
              :label="domain.label"
              :icon="domain.icon"
              color="primary"
              size="lg"
              @click="selectDomain(domain.catalogLabel)"
            />
          </div>
        </div>

      </q-step>
      <q-step
        :name="2"
        :title="currentStep > 2 ? `Opération: ${selectedSubProject?.label || ''}` : 'Opération'"
        icon="assignment"
        :done="currentStep > 2"
      >

        <!-- Step 2: SubProject Selection -->
        <div v-if="currentStep === 2">
          <div class="row justify-between q-mb-md">
            <q-btn
              flat
              color="primary"
              icon="arrow_back"
              label="Retour"
              @click="goToStep(1)"
            />
          </div>
          <div class="text-h6 q-mb-md">Sélectionnez une opération juridique :</div>
          <OnboardingSubProjectTree
            :selected-domain="selectedDomain"
            @select-subproject="selectSubProject"
          />
        </div>

      </q-step>
      <q-step
        :name="3"
        title="Finalisation"
        icon="info"
      >
        <!-- Step 3: Project Details -->
        <div v-if="currentStep === 3">
          <div class="q-mb-md">
            <q-btn
              flat
              color="primary"
              icon="arrow_back"
              label="Retour"
              @click="goToStep(2)"
            />
          </div>
          <div class="text-h6 q-mb-md">Informations</div>
          <div class="column q-gutter-y-md">
            <q-input
              v-model="subprojectName"
              label="Nom de l'opération"
              outlined autofocus
              :rules="[val => !!val || 'Champs requis']"
            />
            <q-input
              v-model="email"
              label="Email"
              outlined
              class="q-pb-none"
              :rules="[val => !!val || 'Champs requis']"
            />
            <p class="text-caption">*L’ensemble des notifications de l’opération juridique seront envoyées à l’adresse email suivante</p>
            <q-btn
              color="primary"
              label="Créer le projet"
              class="q-py-md"
              :disable="!subprojectName || !email"
              @click="createProject"
            />

          </div>
        </div>
      </q-step>
    </q-stepper>
  </q-page>
</template>

<style lang="scss" scoped>
.full-stepper {
  height: calc(100vh - 100px);  /* account for q-page padding */
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

:deep(.q-stepper__content) {
  height: calc(100% - 72px); /* account for stepper header */
  min-height: 0;
}

:deep(.q-step-content) {
  height: 100%;
  overflow-y: auto;
}

.domain-button {
  width: 250px;
  height: 100px;
}
</style>
