<script lang="ts" setup>
import { Field } from '@/models/database/Field'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])
function onFieldChange() {
  emit('update:field', props.field)
}
</script>

<template>
  <q-select v-model="field.value" :options="field.choices?.map(choice => choice.name)"
  :label="showLabel ? field.name : undefined" @update:model-value="onFieldChange"/>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
