<script setup lang="ts">
import { ref, computed, watch, provide } from 'vue'
import { useStore } from '@/store/store'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import MenuSection from './MenuSection.vue'
import MenuItem from './MenuItem.vue'
import { mainMenu as clientMainMenu } from '@/pages/client/menu/MainMenu.js'
import { mainMenu as environmentMainMenu } from '@/pages/environment/menu/MainMenu.js'
import { mainMenu as platformMainMenu } from '@/pages/platform/menu/MainMenu.js'

const store = useStore()
const authenticatedUser = useAuthenticatedUser()

function selectMenu() {
  switch (authenticatedUser.portal) {
    case 'CLIENT':
      return clientMainMenu.menu
    case 'ENVIRONMENT':
      return environmentMainMenu.menu
    case 'PLATFORM':
      return platformMainMenu.menu
  }
  return null
}

const thumbStyle = {
  right: '1px',
  borderRadius: '5px',
  width: '5px',
  color: 'rgb(71, 71, 71)',
  opacity: '0.2',
  zIndex: 10,
  // backgroundColor: '#027be3',
}

const menu = ref(selectMenu())

watch(store.state, (_newStore, _oldStore) => {
  menu.value = selectMenu()
})

const allowedSections = computed(() => {
  const allowedSections = new Array()
  menu.value.sections.forEach((section) => {
    let isSectionAllowed = false

    section.roles.forEach((sectionRole) => {
      if (sectionRole == "admin" || sectionRole == "user") {
        if (section.permission) {
          console.log("need to check menu section permission", section, authenticatedUser)
          if (authenticatedUser.permissions.globalPermissions.includes(section.permission)) {
            isSectionAllowed = true
          }
        } else {
          isSectionAllowed = true
        }
      }
    })
    if (isSectionAllowed) {
      const environmentName = authenticatedUser.environment.name
      const newSection = {
        name: section.name,
        label: section.label.replace("{{ENVIRONMENT_NAME}}", environmentName),
        link: section.link,
        icon: section.icon,
        iconSelected: section.iconSelected,
        roles: section.roles,
        custom: section.custom,
        items: [],
      }

      const allowedItems = new Array()

      section.items.forEach(function (item) {
        let isItemAllowed = false

        item.roles.forEach(function (itemRole) {
          if (itemRole == "admin" || itemRole == "user") {
            if (item.permission) {
              console.log("need to check menu item permission", item, authenticatedUser)
              if (authenticatedUser.permissions.globalPermissions.includes(item.permission)) {
                isItemAllowed = true
              }
            } else {
              isItemAllowed = true
            }
          }
        })

        if (isItemAllowed) {
          allowedItems.push(item)
        }
      })

      newSection.items = allowedItems

      allowedSections.push(newSection)
    }
  })

  return allowedSections
})
const activeSection = ref(allowedSections.value[0].label)
provide('activeSection', activeSection)

// For empty clients section
const visible = ref(true)
// const clientId = ref("");

const updateVisibleState = function () {
  if (authenticatedUser) {
    // clientId.value = authenticatedUser.clientId;
    // if (props.custom == "need_client") {
    if (store.state.currentClient != undefined) {
      visible.value = true
    } else {
      visible.value = false
    }
    // }
  }
}

watch(store?.state, (_newStore, _oldStore) => {
  updateVisibleState()
})

updateVisibleState()
</script>

<template>
  <q-scroll-area style="height: calc(100% - 50px); width: 100%; margin-top: 10px;" :thumb-style="thumbStyle">
    <q-list class="menu">
      <MenuSection v-for="section in allowedSections" :link="section.link" :label="section.label" :icon="section.icon"
        :icon-selected="section.iconSelected" :custom="section.custom">
        <div v-if="section.items.length != 0" style="height: 8px; width: 100%; margin-bottom: -8px"></div>
        <MenuItem v-for="item in section.items" :link="item.link" :label="item.label" :icon="item.icon"
          :icon-selected="item.iconSelected" :custom="item.custom">
        </MenuItem>
        <div v-if="section.items.length != 0 && visible" style="height: 8px; width: 100%"></div>
      </MenuSection>
    </q-list>
  </q-scroll-area>
</template>

<style lang="scss" scoped>
.menu {
  margin-top: 4px;
}
</style>