<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { Dialog, Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import ItemActionMenuItem from '@/components/common/Table/ItemActionMenuItem.vue'
import BaseTable from '@/components/common/Table/BaseTable.vue'
import RenameEntityDialog from './RenameEntityDialog.vue'
import ConfirmDeleteDialog from '@/components/common/dialog/ConfirmDeleteDialog.vue'
import AddEntityDialog from './AddEntityDialog.vue'

interface Props {
}
const props = withDefaults(defineProps<Props>(), {
})

const store = useStore()
const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()
const router = useRouter()

let columns = [
  {
    name: 'name',
    label: 'Nom',
    field: 'name',
    align: 'left',
  },
]

const selection = ref([])
const selectedRows = ref([])
const isTableLoading = ref(false)

let rows = ref(await userApi.getDatabaseObjectModels())

const pagination = ref({
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 100,
})

const rowsPerPageOptions = [5, 10, 20, 50]

function onEditClick() {
  router.push({
    name: 'database/editor',
    params: {
      objectModelId: selectedRows.value[0].id
    }
  })
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}

async function onAddClick() {
  const dialog = Dialog.create({
    component: AddEntityDialog,
    componentProps: {
    },
  })
    .onOk(async (newName) => {

      const newEntity = await userApi.createDatabaseObjectModel(newName, {
        "type": "OBJECT",
        "name": newName,
        "fields": [],
        "children": [
        ]
      })

      console.log("newEntity", newEntity)

      newEntity.definitionJson.id = newEntity.id
      await userApi.updateDatabaseObjectModel(newEntity.id, newEntity.name, newEntity.definitionJson)
      rows.value = await userApi.getDatabaseObjectModels()

      Notify.create({
        message: `Entité créée avec succès`,
        type: 'primary',
      })

      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })

}

async function onRenameClick() {
  const dialog = Dialog.create({
    component: RenameEntityDialog,
    componentProps: {
      objectModel: selectedRows.value[0],
    },
  })
    .onOk(async (newName) => {
      selectedRows.value[0].definitionJson.name = newName
      await userApi.updateDatabaseObjectModel(selectedRows.value[0].id, newName, selectedRows.value[0].definitionJson)
      rows.value = await userApi.getDatabaseObjectModels()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function onDeleteClick() {
  const dialog = Dialog.create({
    component: ConfirmDeleteDialog,
    componentProps: {
      message: `Voulez-vous vraiment supprimer l'entité ${selectedRows.value[0].name} ?
      Cette suppression n'est possible que si l'ensemble des objets associés ont été supprimés.`,
    },
  })
    .onOk(async () => {
      await userApi.deleteDatabaseObjectModel(selectedRows.value[0].id)
      rows.value = await userApi.getDatabaseObjectModels()
      dialog.hide()
      Notify.create({
        message: 'Entité supprimée avec succès',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })

}

function onRowSelected(row) {
  console.log("onRowSelected: ", row)
  selectedRows.value = row
}

function onRowClick(event, row) {
  console.log("onRowCLick: ", row)
  router.push({
    name: 'database/editor',
    params: {
      objectModelId: row.id
    }
  })
}
</script>

<template>
  <BaseTable add-button-label="Créer un type d'entité" table-label="project" name="project-table"
    @on-row-selected="onRowSelected" :selected-rows="selectedRows" v-model:pagination="pagination" selection="single"
    :columns="columns" :rows="rows" :is-loading="isTableLoading" :enable-actions="true" :enable-add="true"
    :rows-per-page-options="rowsPerPageOptions" @add-item-click="onAddClick" class="table" @on-row-click="onRowClick">
    <template #action-menu-items>
      <ItemActionMenuItem label="Modifier" @click="onEditClick" />
      <ItemActionMenuItem label="Renommer" @click="onRenameClick" />
      <ItemActionMenuItem label="Supprimer" @click="onDeleteClick" />
    </template>
  </BaseTable>
</template>

<style lang="scss" scoped>
.table-mobile {
  margin-top: 16px;
}

.previewInNewTabButton {
  color: #cccccc;
  visibility: hidden;
}

.previewInNewTabButton:hover {
  color: #777777;
  background-color: #bbbbbb;
}

.table tr:hover .previewInNewTabButton {
  visibility: visible;
}
</style>
