<script lang="ts" setup>
import { onBeforeUnmount, onUnmounted } from 'vue'
import { Field } from '@/models/database/Field'
import Editor from '@tinymce/tinymce-vue'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(['update:field'])
let debounceTimeout: number | null = null
function onFieldChange() {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout)
  }
  debounceTimeout = window.setTimeout(() => {
    emit('update:field', props.field)
  }, 3000)
}

onBeforeUnmount(() => {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    emit('update:field', props.field)
  }
});
</script>

<template>
  <div style="">
    <div v-if="showLabel" style="font-size: 16px; font-weight: 500; margin-bottom: 8px">
      {{ field.name }}
    </div>
    <editor api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44" :init="{
      height: 250,
      menubar: false,

      statusbar: true,
      elementpath: false,
      branding: false,
      resize: true,

      plugins: 'lists advlist link searchreplace',
      toolbar: 'undo redo | bold italic underline | align | bullist numlist | removeformat',
    }" v-model="field.value" @update:model-value="onFieldChange"/>
  </div>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
