import { Environment } from './Environment'
import { Client } from './Client'
import { isMobile } from '@/utils/detectMobile'

import { routes as baseRoutes } from '@/router/routes'
import { routes as clientRoutes } from '@/pages/client/router/routes.js'
import { routes as environmentRoutes } from '@/pages/environment/router/routes.js'
import { routes as environmentMobileRoutes } from '@/pages/environment-mobile/router/routes.js'
import { routes as platformRoutes } from '@/pages/platform/router/routes.js'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

type OrNull<Type> = Type | null

export class AuthenticatedUser {
  id: string = ''
  pkid: number = 0
  email: string = ''
  firstName: string = ''
  lastName: string = ''
  fullname: string = ''
  initials: string = ''
  accessToken: string = ''
  refreshToken: string = ''
  portal: string = ''
  environment: OrNull<Environment> = null
  clientId: string = ''
  client: OrNull<Client> = null
  permissions: any = { globalPermissions: [] }
  userMode: string = ''
  constructor() {}

  hasPermission(permission: string): boolean {
    return this.permissions?.globalPermissions?.includes(permission) ?? false
  }
}

export function getRoutes(user: OrNull<AuthenticatedUser>) {
  let routes = baseRoutes

  if (user == undefined) {
    return routes
  }

  switch (user.portal) {
    case 'PLATFORM':
      routes = routes.concat(platformRoutes)
      break
    case 'ENVIRONMENT':
      if (isMobile()) {
        routes = routes.concat(environmentMobileRoutes)
      } else {
        routes = routes.concat(environmentRoutes)
      }
      break
    case 'CLIENT':
      routes = routes.concat(clientRoutes)
      break
  }

  return routes
}

export function loadAuthenticatedUserFromLocalStorage(): OrNull<AuthenticatedUser> {
  const userJsonString = localStorage.getItem('user')
  let user: OrNull<AuthenticatedUser>

  if (userJsonString == undefined) {
    user = null
  } else {
    user = JSON.parse(userJsonString)
  }

  // Force logout if we have an old user model in local storage
  if (user?.accessToken == undefined || user?.portal == undefined || user?.portal == '') {
    localStorage.clear()
    user = null
    return null
  }

  let authenticatedUser: AuthenticatedUser = Object.assign(new AuthenticatedUser(), user)

  return authenticatedUser
}

export function userHasPermission(user, permission): boolean {
  return user.permissions.globalPermissions.includes(permission)
}
