<script lang="ts" setup>
import { useDialogPluginComponent, Notify } from 'quasar'
import BaseDialog from '@/components/base/BaseDialog.vue'

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
</script>
<template>
  <q-dialog ref="dialogRef">
    <BaseDialog
      title="Génération du document"
      :show-cancel-button="false"
      @on-dialog-cancel="onDialogCancel()"
      @hide="onDialogHide()"
    >
      <template #body>
        <span>
          Attention ! Vous n'avez pas répondu à toutes les questions du questionnaire. Nous allons générer un document
          incomplet.
        </span>
      </template>
      <template #actions>
        <q-btn flat label="Annuler" color="primary" v-close-popup />
        <q-btn flat label="Générer" color="primary" @click="emit('ok')" />
      </template>
    </BaseDialog>
  </q-dialog>
</template>
<style lang="scss" scoped></style>
