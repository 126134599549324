<script lang="ts" setup>
import { ref, inject } from 'vue'
import { Notify } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'

// import { refreshFiles, getNode, canMoveTo, moveNode, refreshFlatTree, } from './DocumentsCommon'

interface Props {
  node: any
}
const props = withDefaults(defineProps<Props>(), {})

const userApi: UserApi = useApi()

const nodes = inject("nodes")
const pagesTreeRef = inject("pagesTreeRef")
const unsavedChanges = inject("unsavedChanges")

const dropZone = ref(null)

function getNode(nodes, nodeId) {
  if (nodes === undefined) {
    return undefined
  }
  for (let i = 0; i < nodes.length; ++i) {
    const currentNode = nodes[i]
    if (currentNode.id === nodeId) {
      return currentNode
    }
    const childNode = getNode(currentNode.children, nodeId)
    if (childNode !== undefined) {
      return childNode
    }
  }
  return undefined
}

async function onDrop(evt) {
  console.log('BetweenNodeDropZone - onDrop')
  dropZone.value.classList.remove('between-node-drop-zone-hover')

  const itemId = evt.dataTransfer.getData('itemId')
  const item = getNode(nodes.value[0], itemId)
  const itemParent = getNode(nodes.value[0], item.parentId)
  console.log("item->node", item, props.node, itemParent)

  itemParent.children = itemParent.children.filter((child) => {
    return child.id !== item.id
  })

  if (props.node.type == 'OBJECT') {
    props.node.children.unshift(item)
  } else {
    if (props.node.children.length != 0) {   // todo: check node is expanded
      props.node.children.unshift(item)
    } else {
      const nodeParent = getNode(nodes.value[0], props.node.parentId)
      console.log("nodeParent", nodeParent)
      const index = nodeParent.children.findIndex((child) => {
        return child.id === props.node.id
      })
      console.log("nodeParent", index)

      nodeParent.children = [
        ...nodeParent.children.slice(0, index + 1),
        item,
        ...nodeParent.children.slice(index + 1)
      ]
    }
  }

  // pagesTreeRef.value.setExpanded(props.node.id, true)

  unsavedChanges.value = true
}

function onDragEnterRootDropZone(event) {
  // console.log("onDragEnterRootDropZone", event);
  event.preventDefault()
  dropZone.value.classList.add('between-node-drop-zone-hover')
}

function onDragLeaveRootDropZone(event) {
  // console.log("onDragLeaveRootDropZone", event);
  dropZone.value.classList.remove('between-node-drop-zone-hover')
}
</script>

<template>
  <!-- need to prevent default on dragenter and dragover to allow drop -->
  <div class="drop-zone" @drop="onDrop" @dragenter="onDragEnterRootDropZone" @dragleave="onDragLeaveRootDropZone"
    @dragover.prevent ref="dropZone">
    &nbsp;
  </div>
</template>

<style lang="scss" scoped>
.drop-zone {
  height: 4px;
  // background-color: red;
}

.drop-zone-hover {
  background-color: grey;
}

.between-node-drop-zone-hover {
  background-color: grey;
}
</style>
