<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ref, computed, inject, watch } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { Task } from '@/models/projects/Task'
import LogItem from './LogItem.vue'
import Editor from '@tinymce/tinymce-vue'
import { ProjectAccountableOptionsKey, ProjectKey } from '@/models/symbols'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { UserAccountableOptions } from '@/models/projects/UserAccountableOptions'
import AddUserToProjectDialog from './AddUserToProjectDialog.vue'

import { Dialog, Notify, useQuasar } from 'quasar'


interface Props {
  task: Task | undefined
  isDisable?: boolean
  isReadonly?: boolean
  updateNb: any
}

const props = withDefaults(defineProps<Props>(), {
  isDisable: false,
  isReadonly: false,
})

const route = useRoute()
const userApi: UserApi = useApi()

const project = inject(ProjectKey)
const documentId = inject('documentId')
// const taskDetailsSelected = inject("taskDetailsSelected");

const authenticatedUser = useAuthenticatedUser()
const accountableOptions = inject(ProjectAccountableOptionsKey)
const users: Ref<Array<any>> = ref([])

const isLoading = ref(false)
const events = ref([])
const showAllProjectEvents = ref(false)
const newChatMessage = ref('')

async function onChange(event) {
  console.log('onChange', props.task, documentId.value)

  await updateUsers()

  try {
    isLoading.value = true
    const eventsResponse = await userApi.getProjectEvents(project?.value.id, props.task?.id, documentId.value)
    console.log("eventsResponse", eventsResponse.results)

    if (!showAllProjectEvents.value) {
      // events.value = eventsResponse.results.filter(event => event.legacyTaskId.toString() == props.task?.id.toString());
      if (props.task?.id == undefined && documentId.value == undefined) {
        events.value = filterMessages(eventsResponse.results)
      } else if (documentId.value != undefined) {
        const newEvents = []
        eventsResponse.results.forEach((event) => {
          if (event.documentTreeNodeId == documentId.value) {
            newEvents.push(event)
          }
        })
        events.value = filterMessages(newEvents)
      } else {
        const newEvents = []
        eventsResponse.results.forEach((event) => {
          if (event.legacyTaskId == props.task?.id) {
            newEvents.push(event)
          }
        })
        events.value = filterMessages(newEvents)
      }
    } else {
      events.value = filterMessages(eventsResponse.results)
    }

    isLoading.value = false
  } catch (error) {
    isLoading.value = false
  }
}

watch(
  () => props.updateNb,
  () => {
    onChange(undefined)
  },
)

watch(
  () => props.task,
  () => {
    onChange(undefined)
  },
)

watch(
  () => documentId.value,
  () => {
    onChange(undefined)
  },
)

watch(
  () => showAllProjectEvents.value,
  () => {
    onChange(undefined)
  },
)

watch(
  () => accountableOptions.value,
  () => {
    updateUsers()
  },
)

onChange(undefined)




async function updateUsers() {
  console.log('LogPanel: updateUsers')
  users.value = await Promise.all(
    accountableOptions?.value.map(async (user) => {
      const notificationsEnabled = await userApi.getProjectNotification(project?.value.id, user.id)

      const userData = {
        id: user.id,
        fullName: user.fullName,
        notificationsEnabled,
        send: false,
      }

      return userData
    }),
  )

  // Set authenticated user on top of the list
  users.value = users.value.sort((a, b) => {
    if (a.id === authenticatedUser.id) {
      return -1
    }
    if (b.id === authenticatedUser.id) {
      return 1
    }
    return 0
  })

  console.log('LogPanel: updated users', users.value)
}

await updateUsers()



async function sendChatMessage() {
  const recipients = []
  users.value.forEach((user) => {
    if (user.send) {
      recipients.push(user.id)
    }
  })

  await userApi.createProjectEvent(project?.value.id, {
    type: 5,
    user_project_id: project?.value.id,
    legacy_task_id: props.task?.id,
    document_tree_node_id: documentId.value,
    chat_message_content: newChatMessage.value,
    recipients,
  })

  await userApi.sendNewChatMessageEmail(
    project?.value.id,
    props.task?.id,
    documentId.value,
    recipients,
    newChatMessage.value,
  )
  newChatMessage.value = ''
  onChange(undefined)
}

function selectAllRecipients() {
  users.value.forEach((user) => {
    user.send = true
  })
}

function deselectAllRecipients() {
  users.value.forEach((user) => {
    user.send = false
  })
}

function addUser() {
  console.log('addUser')
  const dialog = Dialog.create({
    component: AddUserToProjectDialog,
    componentProps: {
      project: project,
    },
  })
    .onOk(() => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function filterMessages(events) {
  console.log("authenticatedUser", authenticatedUser)
  return events.filter((event) => {

    if (authenticatedUser.subscriptionModules.length == 0) {
      console.log("authenticatedUser.subscriptionModules == []", authenticatedUser)
      let isUserInRecipients = false
      event.recipients.forEach((recipient) => {
        if (recipient.recipient.id == authenticatedUser.id) {
          isUserInRecipients = true
        }
      })
      if (!isUserInRecipients && event.user.id != authenticatedUser.id) {
        return false
      }
    }

    return true
  })
}
const dropdownLabel = computed(() => `Destinataires (${users.value.filter(user => user.send).length})`)
const noUserSelected = computed(() => users.value.filter(user => user.send).length == 0)

// Manually close dropdown when clicking inside the tinymce editor
const isDropdownOpen = ref(false)
function closeDropdown() {
  isDropdownOpen.value = false
}
</script>

<template>
  <div class="">
    <div class="row" style="margin: 5px;">
      <div class="col">
        <p style="margin-top: 5px; font-size: medium; font-weight: bold;">Nouveau message</p>

      </div>
      <div class="col" style="text-align: right;">
        <q-btn-dropdown
          v-model="isDropdownOpen"
          outline no-caps
          :label=dropdownLabel
        >
            <q-list dense>
              <q-item>
                <q-item-section clickable>
                  <q-btn flat label="Tout sélectionner" @click="selectAllRecipients" class="button-link"/>
                  <q-btn flat label="Tout désélectionner" @click="deselectAllRecipients" class="button-link"/>
                </q-item-section>
              </q-item>

              <!-- Divider between buttons and checkbox list -->
              <q-separator />
              <q-item v-for="recipient in users" :key="recipient.id" dense clickable v-ripple>
                <q-item-section dense>
                  <q-checkbox dense :label="recipient.fullName" v-model="recipient.send"></q-checkbox>
                </q-item-section>
              </q-item>
            </q-list>
        </q-btn-dropdown>
      </div>
    </div>

    <editor api-key="2kfvzi8jvomz43fi5wztrp79sp6j2odq3i3lud6boywjbq44"
    :init="{
      height: 250,
      menubar: false,
      statusbar: true,
      elementpath: false,
      branding: false,
      resize: true,
      plugins: 'lists advlist link searchreplace',
      toolbar: 'undo redo | bold italic underline | align | bullist numlist | removeformat',
      setup: (editor) => editor.on('focus', closeDropdown)
    }" v-model="newChatMessage" />

    <div class="row no-wrap items-center" style="margin: 5px;">
      <q-btn rounded color="primary" label="Envoyer" :disable="noUserSelected" @click="sendChatMessage()"></q-btn>
    </div>
    <br>
    <hr color="grey">

    <div style="display: flex; justify-content: space-between; align-items: center;">
      <p style="margin: 5px; font-size: medium; font-weight: bold;">Messages</p>
      <q-toggle dense v-model="showAllProjectEvents" label="Voir tous les messages du dossier">
        <q-tooltip anchor="bottom middle" self="top middle">
          Activez pour voir tous les messages du dossier, désactivez pour ne voir que les messages de l'élément sélectionné.
        </q-tooltip>
      </q-toggle>
    </div>

    <div v-if="isLoading">
      <q-inner-loading showing color="primary" />
    </div>
    <q-list dense separator style="margin-top: 25px">
      <LogItem v-for="event in events" :event="event" />
    </q-list>
  </div>
</template>

<style lang="scss" scoped></style>
