<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import SurveyQuestions from '@/components/survey/SurveyQuestions.vue'
import { useStore } from '@/store/store'
const store = useStore()

const userApi: UserApi = useApi()
const route = useRoute()
const router = useRouter()
if (!route.query.page) {
  router.push({
    path: route.fullPath,
    query: { page: 1 },
  })
}

if (store.state.pageTitle === '' || store.state.pageTitle !== 'Questionnaire') {
  store.commit('setPageTitle', `Questionnaire`)
  if (route.params.projectId) {
    store.commit('setPageTitleLoading', true)
    const project = await userApi.getProject(route.params.projectId.toString())
    store.commit('setPageTitle', `Dossier : ${project.name}`)
    store.commit('setPageTitleLoading', false)
  }
}
</script>

<template>
  <q-page padding class="page">
    <Suspense>
      <template #default> <SurveyQuestions></SurveyQuestions></template>
      <template #fallback>
        <div>
          <q-skeleton class="q-mt-md" type="QBtn" width="121px"></q-skeleton>
          <q-skeleton class="document-title q-mb-sm" height="36px" width="600px"></q-skeleton>
          <q-skeleton class="q-mb-lg" height="23px" width="100%"></q-skeleton>
          <q-skeleton width="500px" height="36px" class="q-mb-md"></q-skeleton>
          <div v-for="n in 4" :key="n">
            <q-card class="my-card q-mb-md" flat bordered>
              <q-card-section>
                <q-skeleton class="text-h6" width="30%"></q-skeleton>
              </q-card-section>
              <q-separator inset />
              <q-card-section>
                <q-skeleton type="QInput" width="100%"></q-skeleton>
              </q-card-section>
            </q-card>
            <q-card class="my-card q-mb-md" flat bordered>
              <q-card-section>
                <q-skeleton class="text-h6" width="20%"></q-skeleton>
              </q-card-section>
              <q-separator inset />
              <q-card-section>
                <q-skeleton type="QInput" width="100%"></q-skeleton>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </template>
    </Suspense>
  </q-page>
</template>

<style lang="scss" scoped>
.document-title {
  margin-top: 20px;
}
.page {
  padding-bottom: 0px;
}
</style>
