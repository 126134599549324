<script lang="ts" setup>
import { inject, ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import FileSaver from 'file-saver'
import { Notify, Dialog } from 'quasar'
import RenameNodeDialog from './RenameNodeDialog.vue'
import DeleteNodeDialog from './DeleteNodeDialog.vue'
import UnlockNodeDialog from './UnlockNodeDialog.vue'
import NodeLockedDialog from './NodeLockedDialog.vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { ProjectKey } from '@/models/symbols'

interface Props {
    node: any
}
const props = withDefaults(defineProps<Props>(), {})

const unsavedChanges = inject("unsavedChanges")
const nodes = inject("nodes")
const fileDropZoneRef = ref(null)
const pagesTreeRef = inject("pagesTreeRef")

function getNode(nodes, nodeId) {
    for (let i = 0; i < nodes.length; ++i) {
        const currentNode = nodes[i]
        if (currentNode.id === nodeId) {
            return currentNode
        }
        if (!currentNode.children) {
            continue
        }
        const childNode = getNode(currentNode.children, nodeId)
        if (childNode !== undefined) {
            return childNode
        }
    }
    return undefined
}

function startDrag(evt, item) {
    evt.dataTransfer.dropEffect = 'move'
    evt.dataTransfer.effectAllowed = 'move'
    evt.dataTransfer.setData('itemId', item.id)
    console.log('dragging', item.id)
}

async function onDrop(evt) {
    console.log("onDrop", evt)
    fileDropZoneRef.value.classList.remove('file-drop-zone-hover')
    const itemId = evt.dataTransfer.getData('itemId')

    if (itemId) {
        console.log("root", nodes.value[0])
        const item = getNode(nodes.value[0], itemId)
        const itemParent = getNode(nodes.value[0], item.parentId)

        console.log("item->node", item, props.node, itemParent)

        itemParent.children = itemParent.children.filter((child) => {
            return child.id !== item.id
        })

        props.node.children.unshift(item)

        pagesTreeRef.value.setExpanded(props.node.id, true)

        unsavedChanges.value = true
    }
}

function onDragEnter(event) {
    fileDropZoneRef.value.classList.add('file-drop-zone-hover')

    // Needed for onDrop to be fired
    event.preventDefault()
}

function onDragLeave(event) {
    fileDropZoneRef.value.classList.remove('file-drop-zone-hover')
}

function parseNodes(nodes, parent) {
    console.log("parseNodes", nodes, parent)

    if (nodes == undefined) {
        return
    }
    nodes.forEach((node) => {
        console.log("node", node)
        // node.parent = parent
        node.parentId = parent.id
        parseNodes(node.children, node)
    })
}

async function updateNonClassifiedNoCodeProjects() {
    noCodeProjectCatalog.value = noCodeProjectCatalogResponse.response.results
    noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
        return !(project.name.startsWith('Modèle sec') || project.name.startsWith('Test'))
    })

    noCodeProjectCatalog.value = noCodeProjectCatalog.value.sort((a, b) => {
        return a.name.localeCompare(b.name)
    })

    filterNonClassifiedNoCodeProjects()
}

function filterNonClassifiedNoCodeProjects() {
    const projectIds = getProjectIds(nodes.value[0])
    console.log('projectIds', projectIds)
    noCodeProjectCatalog.value = noCodeProjectCatalog.value.filter((project) => {
        return !projectIds.includes(project.id.toString())
    })
}

function getProjectIds(node) {
    const ids = []
    if (node.children) {
        for (const child of node.children) {
            ids.push(child.id)
            ids.push(...getProjectIds(child))
        }
    }
    return ids
}


function createUUID() {
    var dt = new Date().getTime()
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
}

function addSection(node) {
    const newNode = {
        id: createUUID(),
        name: 'Nouvelle page',
        type: 'PAGE',
        children: [],
        fields: [],
    }

    node.children.unshift(newNode)

    parseNodes(node.children, node)

    pagesTreeRef.value.setExpanded(node.id, true)

    unsavedChanges.value = true
}
</script>

<template>
    <div style="padding: 0px; overflow: hidden"
        class="row no-wrap justify-start items-start content-start full-width items-center cursor-pointer" draggable="true"
        @dragstart="startDrag($event, node)" @drop="onDrop($event)" @dragover.prevent @dragenter="onDragEnter($event)"
        @dragleave="onDragLeave($event)" ref="fileDropZoneRef">
        <div v-if="node.type === 'root'">&nbsp;</div>
        <div v-if="node.type !== 'root'"
            class="row  no-wrap justify-start items-start content-start full-width items-center node">
            <div class="col-auto">
                <q-icon v-if="node.icon" :name="node.icon" :style="node.iconStyle"></q-icon>
                <span :style="node.textStyle" class="node-name">{{ node.name }}</span>
                <!-- <q-btn v-if="node.type != 'genericModels'" dense icon="o_edit" size="6px" @click.stop="editNode(node)"
                    text-color="grey" class="node-button">
                    <q-tooltip>Renommer</q-tooltip>
                </q-btn> -->
                <q-btn v-if="node.type != 'project'" icon="o_add" size="6px" dense @click.stop="addSection(node)"
                    text-color="grey" class="node-button">
                    <q-tooltip>Ajouter une page</q-tooltip>
                </q-btn>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.file-name {
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // border: 1px solid;
    // text-wrap: nowrap;
}

.file-date {
    color: #2c2c2c;
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.drop-zone {
    // margin-bottom: 10px;
    // padding: 10px;
}

.drag-el {
    // cursor: grab;
    // margin-bottom: 10px;
    // padding: 5px;
}

.editing-user {
    font-style: italic;
    font-size: 13px;
    color: #431010;
    margin-top: -4px;
}

.previewInNewTabButton {
    color: #cccccc;
}

.previewInNewTabButton:hover {
    color: #777777;
    background-color: #bbbbbb;
}

.file-drop-zone-hover {
    background-color: lightgrey;
}

.node-button {
    visibility: hidden;
}

.node:hover .node-button {
    visibility: visible;
}

.node-name {
    margin-right: 8px;
}

.ul {
    list-style-type: disc;
}

:deep(.q-checkbox__inner) {
    color: rgb(158, 158, 158);

    margin-right: 5px;
    font-size: 34px; // influe sur la taille de la checkbox
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
    height: 0px;
    width: 0px;
    overflow: hidden;
}

// change tree checkbox style
:deep(.q-checkbox__bg) {
    border: 1.3px solid currentColor;
}

.search-input {
    width: 500px;
    margin-left: 0px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.subtitle {
    font-size: 18px;
}

.category-header {
    background-color: #227a68;
    color: white;
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    // cursor: pointer;
}

.category-subheader {
    background-color: #2a5d97;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-left: 30px;
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    // cursor: pointer;
}

.project-category {
    color: rgb(61, 61, 63);
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
}

.available-project {
    cursor: pointer;
    font-weight: 500;
}

.page-content-header {
    // margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
