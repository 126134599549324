export async function refreshFiles(userApi, project) {
  console.log('refreshingFiles')
  const documentTree = await userApi.getUserProjectDocumentTree(project?.value.id)
  createLabels(documentTree.children, undefined)

  const documentTreeNodes = [
    {
      id: 0,
      type: 0,
      children: documentTree.children,
    },
  ]
  return documentTreeNodes
}

export function updateLabel(node) {
  node.label = node.name
  if (node.type === 1) {
    // node.icon = 'o_folder'
    node.icon = 'img:/folder_icon.svg'
  } else if (node.type === 2) {
    node.icon = 'img:/file_icon.svg'

    const lowerCaseName = node.name.toLowerCase()

    if (lowerCaseName.endsWith('.eml')) {
      node.icon = 'o_email'
    } else if (lowerCaseName.endsWith('.msg')) {
      node.icon = 'o_email'
    } else if (lowerCaseName.endsWith('.docx')) {
      node.icon = 'img:/word_icon.svg'
    } else if (lowerCaseName.endsWith('.doc')) {
      node.icon = 'img:/word_icon.svg'
    } else if (lowerCaseName.endsWith('.xlsx')) {
      node.icon = 'img:/excel_icon.svg'
    } else if (lowerCaseName.endsWith('.xls')) {
      node.icon = 'img:/excel_icon.svg'
    } else if (lowerCaseName.endsWith('.pptx')) {
      node.icon = 'img:/powerpoint_icon.svg'
    } else if (lowerCaseName.endsWith('.ppt')) {
      node.icon = 'img:/powerpoint_icon.svg'
    } else if (lowerCaseName.endsWith('.pdf')) {
      node.icon = 'img:/pdf_icon.svg'
    } else if (
      lowerCaseName.endsWith('.jpg') ||
      lowerCaseName.endsWith('.jpeg') ||
      lowerCaseName.endsWith('.png') ||
      lowerCaseName.endsWith('.gif')
    ) {
      node.icon = 'o_image'
    }

    if (node.file.versions.length > 1) {
      console.log('creating versions nodes', node, parent)
      node.children = []
      // node.label = parent.label;
      node.file.versions.forEach((version) => {
        node.children.push({
          id: version.id,
          type: 3,
          name: node.name,
          label: node.label,
          version: version,
          icon: 'xx',
          children: [],
          noTick: true,
        })
      })
    }
  }
}

export function createLabels(nodes, parent = undefined) {
  nodes.forEach((node) => {
    node.parentId = undefined
    node.parent = undefined
    if (parent !== undefined) {
      node.parentId = parent.id
      node.parent = parent
    }

    updateLabel(node)

    createLabels(node.children, node)
  })
}

export function orderParent(documentTreeNodes, node, viewTypeValue) {
  if (node.parent !== undefined) {
    orderChildren(node.parent, viewTypeValue)
  } else {
    console.log('order first level', documentTreeNodes)
    orderChildren(documentTreeNodes.value[0], viewTypeValue)
  }
}

export function orderChildren(parentNode, viewTypeValue) {
  console.log('reordering node children', parentNode)

  const directoryChildren = parentNode.children.filter((child) => {
    return child.type === 1
  })
  directoryChildren.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1
    } else if (a.label > b.label) {
      return 1
    }
    return 0
  })

  const fileChildren = parentNode.children.filter((child) => {
    return child.type === 2
  })
  fileChildren.sort((a, b) => {
    if (viewTypeValue === 'flat') {
      let aUpdatedAt = new Date(a.file.fileModificationDatetime)
      let bUpdatedAt = new Date(b.file.fileModificationDatetime)
      if (a.file.versions.length > 0) {
        aUpdatedAt = new Date(a.file.versions[0].updatedAt)
      }
      if (b.file.versions.length > 0) {
        bUpdatedAt = new Date(b.file.versions[0].updatedAt)
      }
      if (aUpdatedAt > bUpdatedAt) {
        return -1
      } else if (aUpdatedAt < bUpdatedAt) {
        return 1
      }
    } else {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1
      } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1
      }
    }
    return 0
  })

  parentNode.children = directoryChildren.concat(fileChildren)
}

export function getNode(nodes, nodeId) {
  for (let i = 0; i < nodes.length; ++i) {
    const currentNode = nodes[i]
    if (currentNode.id === nodeId) {
      return currentNode
    }
    if (currentNode.children) {
      const childNode = getNode(currentNode.children, nodeId)
      if (childNode !== undefined) {
        return childNode
      }
    }
  }
  return undefined
}

export function expandToNode(documentTreeNodes, nodeId, expandedNodes) {
  console.log('expandToNode', nodeId)
  let currentNode = getNode(documentTreeNodes.value, nodeId)
  console.log(currentNode)
  if (currentNode != undefined) {
    currentNode = currentNode.parent
  }
  while (currentNode != undefined) {
    if (
      // currentNode.type !== 2 &&
      !expandedNodes.value.includes(currentNode.id)
    ) {
      expandedNodes.value.push(currentNode.id)
    }
    currentNode = currentNode.parent
  }

  console.log('expandedNodes', expandedNodes.value)
}

export function canMoveTo(node, destination) {
  let currentNode = destination

  while (currentNode !== undefined) {
    if (node.id === currentNode.id) {
      return false
    }
    currentNode = currentNode.parent
  }

  return true
}

export async function moveNode(project, userApi, documentTreeNodes, item, destination, expandedNodes, viewTypeValue) {
  console.log('moveNode')

  await userApi.moveDocumentTreeNode(project?.value.id, item.id, destination, expandedNodes)

  let oldParent = getNode(documentTreeNodes.value, item.parentId)
  if (oldParent == undefined) {
    console.log('oldParent is root')
    oldParent = documentTreeNodes.value[0]
  }
  console.log('oldParent', oldParent)
  oldParent.children = oldParent.children.filter((child) => child.id != item.id)

  let newParent = getNode(documentTreeNodes.value, destination)
  if (newParent == undefined) {
    console.log('newParent is root')
    newParent = documentTreeNodes.value[0]
  }
  console.log('newParent', destination, newParent)
  item.parentId = destination
  item.parent = newParent
  newParent.children.push(item)
  orderParent(documentTreeNodes, item, viewTypeValue)
  expandToNode2(documentTreeNodes, item.id, expandedNodes)
}

export function expandToNode2(documentTreeNodes, nodeId, expandedNodes) {
  console.log('expandToNode', documentTreeNodes.value, nodeId)
  let currentNode = getNode(documentTreeNodes.value, nodeId)

  const nodeIdsToExpand = JSON.parse(JSON.stringify(expandedNodes.value))
  console.log('currentNode', currentNode)
  while (currentNode != undefined) {
    if (currentNode.type !== 2 && !nodeIdsToExpand.includes(currentNode.id)) {
      nodeIdsToExpand.push(currentNode.id)
    }

    currentNode = currentNode.parent
    console.log('currentNode', currentNode)
  }

  expandedNodes.value = nodeIdsToExpand

  console.log('expandedNodes', expandedNodes.value)
}

export function createFlatTree(flatTreeRoot, nodes) {
  nodes.forEach((node) => {
    if (node.type !== 1) {
      flatTreeRoot.children.push(node)
    }
    createFlatTree(flatTreeRoot, node.children)
  })
}

export function refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes) {
  console.log('refreshFlatTree')
  flatViewNodes.value = [
    {
      id: 0,
      type: 0,
      children: [],
    },
  ]
  createFlatTree(flatViewNodes.value[0], documentTreeNodes.value[0].children)
  orderChildren(flatViewNodes.value[0], 'flat')
  if (viewType.value.value == 'flat') {
    currentDocumentTreeNodes.value = flatViewNodes.value
  }
}
