import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'
import { useStore } from '@/store/store'

export function useAuthenticatedUser(): AuthenticatedUser | null {
  const store = useStore()
  return store.state.user
}

export function useAuthenticatedUserHasPermission() {
  const authenticatedUser = useAuthenticatedUser()

  const authenticatedUserHasPermission = function (permission: string): boolean {
    return authenticatedUser?.permissions?.globalPermissions?.includes(permission) ?? false
  }

  return authenticatedUserHasPermission
}
