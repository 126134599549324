<script setup lang="ts">
import { inject } from 'vue'
import draggable from 'vuedraggable'
import EntityField from './EntityField.vue'

const unsavedChanges = inject("unsavedChanges")

interface Props {
  element: any
}

const props = withDefaults(defineProps<Props>(), {
})

function onChange(event) {
    console.log('onChange', event)
    unsavedChanges.value = true
}

function onClone(event) {
    console.log('onClone', event)
    unsavedChanges.value = true
}
</script>


<template>
    <q-select v-model="element.chipFields" multiple outlined dense options-dense class="q-mt-sm"
        label="Champs visibles (si dans un tableau)" use-chips
        emit-value map-options :options="element.fields"
        option-value="id" option-label="name"
        @update:model-value="onChange"
    ></q-select>
    <div class="q-my-md">
        Sous-champs :
    </div>
    <q-list dense class="q-ml-xl">
        <draggable :list="element.fields" :group="{ name: 'g2', pull: 'clone', put: true }" ghost-class="ghost"
            item-key="id" class="q-gutter-sm" :clone="onClone" @change="onChange"  :filter="'.no-drag'" preventOnFilter>
            <template #item="{ element, index }">
                <EntityField :element="element" style="background-color: rgb(237, 237, 237);"></EntityField>
            </template>
            <template #header>
                <div v-if="element.fields.length === 0" style="height: 60px; margin-left: 0px;">
                    Aucun élément pour le moment<br />
                    Glissez un premier élément
                </div>
            </template>
        </draggable>
    </q-list>
</template>