<script setup lang="ts">
import { computed, ref } from 'vue'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useRouter } from 'vue-router'
import { useStore } from '@/store/store'
import { useApi } from '@/store/useAppStore'

const authenticatedUser = useAuthenticatedUser()
const router = useRouter()
const userApi = useApi()

const store = useStore()
const initials = computed(() => authenticatedUser.initials)
const fullName = computed(() => authenticatedUser.fullName)
const email = computed(() => authenticatedUser.email)

const logout = function () {
  store.state.currentClient = null
  authenticatedUser.clientId = ''
  router.push({ name: 'base/logout' })
}

const usersResponse = await userApi.getEnvironmentUsersBase()

let impersonateUser = null

console.log("authenticatedUser", authenticatedUser)

if (authenticatedUser.impersonatedId) {
  impersonateUser = await userApi.getUserByPkid(authenticatedUser.impersonatedId)
}

const users = ref(usersResponse.map((user: any) => {
  return {
    label: user.fullName,
    value: user.pkid
  }
}))

users.value.unshift({
  label: 'Moi',
  value: authenticatedUser.pkid
})

const impersonatedUser = ref(users.value[0])

async function onUserSelected() {
  console.log("onUserSelected", impersonatedUser.value)
  await userApi.impersonate(impersonatedUser.value.value)
  document.location.reload()
}

async function stopImpersonate() {
  await userApi.stopImpersonate()
  document.location.reload()
}

function launchGuidedTour() {
  store.commit('setProductTour', true);
}

function filterFn(val, update) {
  if (val === '') {
    update(() => {
      users.value = usersResponse.map((user: any) => {
        return {
          label: user.fullName,
          value: user.pkid
        }
      })
    })
    return
  }

  update(() => {
    const needle = val.toLowerCase()
    users.value = usersResponse.filter(v => v.fullName.toLowerCase().indexOf(needle) > -1).map((user: any) => {
      return {
        label: user.fullName,
        value: user.pkid
      }
    })
  })
}
</script>

<template>
  <q-btn round flat class="" size="14px">
    <q-avatar :color="authenticatedUser.impersonatedId ? 'red' : 'primary'" text-color="white"
      size="28px">{{ initials }}</q-avatar>
    <q-menu>
      <q-card class="card">
        <q-card-section>
          <div class="text-subtitle1">{{ fullName }}</div>
          <div class="text-subtitle2">{{ email }}</div>
        </q-card-section>

        <q-separator />

        <div v-if="authenticatedUser.isSuperuser || authenticatedUser.impersonatedId">
          <q-card-section v-if="!authenticatedUser.impersonatedId">
            <div>
              Prendre l'identité de :
            </div>

            <q-select v-model="impersonatedUser" :options="users" dense use-input input-debounce="0"
              @filter="filterFn" @update:model-value="onUserSelected">
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">Aucun utilisateur correspondant</q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-card-section>

          <q-card-section v-if="authenticatedUser.impersonatedId">
            <div>
              Connecté sous l'identité de :
              <div>{{ impersonateUser.fullName }}</div>
            </div>

            <q-btn @click="stopImpersonate">Revenir à mon identité</q-btn>
          </q-card-section>
        </div>


        <q-separator />

        <q-card-section v-if="store.state.user?.userMode == 'SOLO'" style="padding: 0px">
          <div class="row items-center q-gutter-x-md">
            <q-btn flat label="Relancer la visite guidée" @click="launchGuidedTour()" style="width: 100%" />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions vertical>
          <q-btn color="primary" @click="logout">Se déconnecter</q-btn>
        </q-card-actions>
      </q-card>
    </q-menu>
  </q-btn>

  <teleport v-if="authenticatedUser.impersonatedId" to="body">
    <div class="impersonate_label">
      Connecté sous l'identité de {{ impersonateUser.fullName }} </div>
  </teleport>
</template>

<style lang="scss" scoped>
.impersonate_label {
  position: fixed;
  bottom: 6px;
  right: 12px;
  font-size: 16px;
  color: #ff0000;
  background-color: #b2b2d6;
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 24px;
}
</style>
