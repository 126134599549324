<script setup lang="ts">
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'

const userApi: UserApi = useApi()

const props = defineProps<{
  question: {
    aiDescription: string,
    aiPrompt: string
  }
}>()

const { dialogRef, onDialogHide, onDialogOK } = useDialogPluginComponent()

const userInput = ref('')
const loading = ref(false)

function buildPrompt() {
  return `${props.question.aiPrompt} ${userInput.value}`
}

async function generateResponse() {
  try {
    loading.value = true
    const response = await userApi.getWysiwygAiSuggestion(buildPrompt())
    console.log('Response', response)
    onDialogOK(response.text.replace(/\n/g, "<br>"))
  } catch (error) {
    console.error('Error generating AI response:', error)
  } finally {
    loading.value = false
  }
}
</script>
<template>
  <q-dialog ref="dialogRef">
    <q-card class="q-pa-md" style="min-width: 500px">
      <q-card-section>
        <div class="text-h6">Rédaction par IA</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div class="text-body1 q-mb-md">{{ props.question.aiDescription || 'Donnez une description simple et l\'IA proposera une réponse' }}</div>
        <q-input
          v-model="userInput"
          type="textarea"
          maxlength="1000"
          rows="3"
          outlined
          class="q-mb-md"
          :loading="loading"
          @keydown.ctrl.enter.prevent="generateResponse"
          @keydown.meta.enter.prevent="generateResponse"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Annuler" color="primary" @click="onDialogHide" />
        <q-btn
          :loading="loading"
          :disable="userInput.length === 0"
          label="Générer"
          color="primary"
          @click="generateResponse"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
