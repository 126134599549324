<script lang="ts" setup>
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { ref } from 'vue'
import { format } from 'date-fns'

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const model = ref()

const emit = defineEmits(['update:field'])

function updatingModel() {
  emit('update:field', props.field)
}

const proxyDate = ref(format(new Date(), 'dd/MM/yyyy'))

if (props.field.value && props.field.value.includes('-')) {
  model.value = format(new Date(props.field.value), 'dd/MM/yyyy')
  proxyDate.value = format(new Date(props.field.value), 'dd/MM/yyyy')
} else {
  model.value = props.field.value
  proxyDate.value = props.field.value
}

function save() {
  model.value = proxyDate.value
  props.field.value = proxyDate.value
  emit('update:field', props.field)
  // emit('update:modelValue', model.value)
  // emit('update:field', props.node, props.field)
}
</script>

<template>
  <q-input v-model="model" class="q-pb-none" mask="##/##/####" v-bind="$attrs" :label="showLabel ? field.name : undefined"
    @update:model-value="updatingModel" debounce="1000">
    <template v-slot:prepend>
      <q-icon name="event" class="cursor-pointer"> </q-icon>
    </template>
    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
      <q-date v-model="proxyDate" mask="DD/MM/YYYY">
        <div class="row items-center justify-end">
          <q-btn v-close-popup label="Annuler" color="primary" flat />
          <q-btn v-close-popup label="OK" color="primary" @click="save" flat />
        </div>
      </q-date>
    </q-popup-proxy>
  </q-input>
</template>

<style lang="scss" scoped>
.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}
</style>
