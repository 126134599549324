import axios from 'axios'
import UserApi from './core/UserApi'
import { AuthenticatedUser } from '@/models/core/AuthenticatedUser'

type OrNull<Type> = Type | null

class BackOfficeApi {
  protected store: any
  private accessToken: string = ''
  public userApi: any
  public BASE_URL: string | boolean | undefined = import.meta.env.VITE_API_BASE_URL
  public user: AuthenticatedUser | null = null

  constructor() {
    this.userApi = new UserApi(this)
  }

  setStore(store) {
    this.store = store
  }

  getBaseUrl(): string {
    return 'api/v1'
  }

  getCurrentUserBaseUrl(): string {
    // const authenticatedUser = useAuthenticatedUser();
    const authenticatedUser = this.store.state.user
    const userId = authenticatedUser.id
    return 'api/v1/users/' + userId
  }

  getCurrentEnvironmentBaseUrl(): string {
    // const authenticatedUser = useAuthenticatedUser();
    const authenticatedUser = this.store.state.user
    const environmentId = authenticatedUser.environment.id
    return 'api/v1/environments/' + environmentId
  }

  getCurrentClientBaseUrl(): string {
    // const authenticatedUser = useAuthenticatedUser();
    const authenticatedUser = this.store.state.user
    const environmentId = authenticatedUser.environment.id
    let clientId = authenticatedUser.clientId
    if (clientId == undefined || clientId == '') {
      clientId = authenticatedUser.client
    }
    return 'api/v1/environments/' + environmentId + '/clients/' + clientId
  }

  getCurrentPortal(): string {
    // const authenticatedUser = useAuthenticatedUser();
    const authenticatedUser = this.store.state.user
    return authenticatedUser.portal
  }

  setUser(user: OrNull<AuthenticatedUser>) {
    if (user == undefined || user == null) {
      return
    }
    this.accessToken = user.accessToken
    this.user = user
  }

  updateUser(user: any) {
    this.store.commit('setUser', user)
  }

  getAuthenticatedUser() {
    return this.store.state.user
  }

  getUserApi(): UserApi {
    return this.userApi
  }

  getAccessTokenHeaderValue(): string {
    return 'Token ' + this.accessToken
  }

  getDefaultHeaders(): any {
    const headers = {
      Authorization: this.getAccessTokenHeaderValue(),
      'Content-Type': 'application/json',
    }

    if (this.user?.environment?.id) {
      headers['X-Environment-ID'] = this.user.environment.id
    }

    return headers
  }

  async login(username: string, password: string): Promise<void> {
    let bodyFormData = new FormData()
    bodyFormData.append('username', username)
    bodyFormData.append('password', password)
    return axios
      .post(this.BASE_URL + 'authenticate', bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async lxbLogin(token: string): Promise<void> {
    return axios
      .get(this.BASE_URL + 'lxb-login/' + token, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async getInviteInfo(parameters: any): Promise<void> {
    return axios
      .post(this.BASE_URL + 'api/v1/invite_info', parameters)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async acceptInvitation(parameters: any): Promise<void> {
    return axios
      .post(this.BASE_URL + 'api/v1/accept_invitation', parameters)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async forgotPassword(email: string): Promise<void> {
    return axios
      .post(this.BASE_URL + 'api/v1/password_reset/', { email: email })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async resetPassword(token: string, password: string): Promise<void> {
    return axios
      .post(this.BASE_URL + 'api/v1/password_reset/confirm/', {
        token: token,
        password: password,
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async get<T>(url: string, parameters?: [], logError = true) {
    return axios
      .get<T>(this.BASE_URL + url, {
        headers: this.getDefaultHeaders(),
        params: parameters,
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (logError) {
          console.error('API error', error)
        }
        throw error
      })
  }

  async getBlob<T>(url: string, parameters?: []) {
    return axios
      .get<T>(this.BASE_URL + url, {
        headers: this.getDefaultHeaders(),
        params: parameters,
        responseType: 'blob',
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async getBlobAsPost<T>(url: string, parameters?: any) {
    return axios
      .post<T>(this.BASE_URL + url, parameters, {
        headers: this.getDefaultHeaders(),
        responseType: 'blob',
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async post(url, parameters) {
    return axios
      .post(this.BASE_URL + url, parameters, {
        headers: this.getDefaultHeaders(),
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async postDocument(url, parameters) {
    return axios
      .post(this.BASE_URL + url, parameters, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async postDocumentWithProgress(url, parameters, onUploadProgress) {
    const headers = this.getDefaultHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    return axios
      .post(this.BASE_URL + url, parameters, {
        headers: headers,
        onUploadProgress: onUploadProgress,
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async postDocumentWithDocumentResponse(url, parameters) {
    return axios
      .post(this.BASE_URL + url, parameters, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob',
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async delete(url) {
    return axios
      .delete(this.BASE_URL + url, {
        headers: this.getDefaultHeaders(),
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async put<T>(url, parameters) {
    return axios
      .put<T>(this.BASE_URL + url, parameters, {
        headers: this.getDefaultHeaders(),
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }

  async patch<T>(url, parameters) {
    return axios
      .patch<T>(this.BASE_URL + url, parameters, {
        headers: this.getDefaultHeaders(),
      })
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        console.error('API error', error)
        throw error
      })
  }
}

export default BackOfficeApi
