<script lang="ts" setup>
import { ref, Ref, inject, watch, computed } from 'vue'
import { Dialog } from 'quasar'
import * as XLSX from 'xlsx';
import { Field } from '@/models/database/Field'
import TextField from "./TextField.vue"
import NumberField from "./NumberField.vue"
import ChoiceField from "./ChoiceField.vue"
import MultipleChoice from "./MultipleChoiceField.vue"
import DateField from "./DateField.vue"
import WysiwygTextField from "./WysiwygTextField.vue"
import FunctionField from "./FunctionField.vue"
import FileField from "./FileField.vue"
import GroupField from "./GroupField.vue"
import ImportCsvDialog from "./ImportCsvDialog.vue"

interface Props {
  field: Field
  showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
})

const emit = defineEmits(["update:field"]);

interface Column {
  name: string;
  label: string;
  field: string;
  align: string;
  sortable: boolean;
  type: string;
  fields?: Array<Field>;
  choices?: string;
  format?: string;
}
interface Row {
  id: string;
  fields: Array<{
    name: string;
    type: string;
    value: string;
  }>;
}
const columns: Ref<Array<Column>> = ref([]);
const visibleColumns: Ref<Array<string>> = ref([]);
const rows: Ref<Array<Row>> = ref([]);
const rowFilters: Ref<Record<string, Array<string>>> = ref({}); // row filter {'columnName': ['filteredValue1', 'filteredValue2'], ...}
const hasFilters = computed(() => {
  return !Object.values(rowFilters.value).every(f => f.length === 0)
})
const selectedView: Ref<{ rowFilters, columnFilters, active }> = ref()
const initializing = ref(false) // to prevent emitting updates during initialization

function initializeTableData() {
  // Initialize columns
  columns.value = [{
    name: "actions",
    label: "",
    field: "actions",
    align: 'left',
    sortable: false,
    type: "actions"
  }].concat(props.field.columns.map((column) => ({
    name: column.name,
    label: column.name,
    field: column.name,
    align: 'left',
    sortable: false,
    type: column.type,
    choices: column.choices,
    format: column.format,
    fields: column.fields,
    chipFields: column.chipFields,
    // ...(column.choices ? { choices: column.choices } : {}), // only add options if the field column has such a property
    // sort: (a, b) => a.valued > b.value ? 1 : -1, // TODO: make it work
  })));
  // Initialize rows
  rows.value = props.field.rows?.map((row) => ({
    id: row.id || createUUID(),
    fields: row.fields.map((field) => ({
      id: field.id,
      modelId: field.modelId,
      name: field.name,
      type: field.type,
      value: field.value || "",
      ...(field.type === "choice" ? { choices: field.choices } : {}),
      ...(field.type === "function" ? { formula: field.formula } : {}),
      ...(field.type === "group_field" ? { fields: field.fields, chipFields: field.chipFields } : {}),
      ...(field.type === "number" || field.type === "function" ? { format: field.format } : {}),
    })),
  })) || [];
  // Initialize rowFilters
  rowFilters.value = columns.value.reduce((acc, column) => ({ ...acc, [column.name]: [] }), {});
  visibleColumns.value = columns.value.map((column) => column.name);

  if (props.field.arrayViews) {
    const activeView = Object.entries(props.field.arrayViews).find(([, view]) => view.active === true);
    if (activeView) {
      selectedView.value = activeView[1]
      applyView(selectedView.value)
    }
  }
}

watch(
  () => props.field,
  () => {
    // Set table data from the field, and prevent emitting updates during this time
    initializing.value = true
    initializeTableData()
    setTimeout(() => {
      initializing.value = false
    }, 200)
  },
  { immediate: true }
);

// Watch for changes in rows and emit updates to the parent
watch(
  rows,
  (newRows) => {
    if (initializing.value) return
    props.field.rows = newRows
    emit("update:field", props.field);
  },
  { deep: true }
);

const filteredRows = computed(() => {
  return rows.value.filter((row) => {
    return Object.entries(rowFilters.value).every(([column, selectedFilters]) => {
      if (!selectedFilters || selectedFilters.length === 0) {
        return true; // No filter applied for this column
      }
      return selectedFilters.includes(row.fields.find((field) => field.name === column)?.value);
    });
  });
});

const uniqueValues = computed(() => {
  return columns.value.reduce((acc, col) => {
    acc[col.name] = Array.from(new Set(rows.value.map((row) => row.fields.find((field) => field.name === col.name)?.value)));
    return acc;
  }, {});
});

function clearFilters() {
  Object.values(rowFilters.value).forEach(f => f.length = 0)
}

const showSums = computed(() => columns.value.some(column => column.type === "number"));
const rowSums = computed(() => {
  const sums = {};
  columns.value.filter((column) => column.type === "number").forEach((column) => {
    sums[column.name] = 0;
    for (const row of filteredRows.value) {
      const field = row.fields.find((field) => field.name === column.name);
      try { sums[column.name] += parseFloat(field.value.replace(/,/g, '.').replace(/\s/g, '')) || 0 }
      catch { }
    };
    if (sums[column.name] == 0) { sums[column.name] = '' }
    sums[column.name] = sums[column.name].toLocaleString('fr-FR')
    if (column.format) { sums[column.name] = column.format + ' ' + sums[column.name] }
  });
  return sums;
});

function addRow(rowValues: any = null) {
  console.log("rows before add", rows.value)
  const row = {
    id: createUUID(),
    fields: []
  }
  props.field.columns.forEach((column: any) => {
    let rowField: Field = {
      id: createUUID(),
      name: column.name,
      type: column.type,
      modelId: column.modelId,
      value: ""
    }
    if (rowValues) { rowField.value = rowValues[column.name] || "" }
    if (column.type === "group_field") {
      rowField.fields = column.fields.map(field => ({
        ...field,
        value: rowValues ? rowValues[column.name + " / " + field.name] || "" : "",
        id: createUUID()
      }))
      rowField.chipFields = column.chipFields
    }
    if (column.type === "choice") { rowField.choices = column.choices }
    if (column.type === "number" || column.type === "function") { rowField.format = column.format }
    if (column.type === "function") { rowField.formula = column.formula }
    row.fields.push(rowField)
  })
  rows.value.push(row)
  clearFilters()
  console.log("rows after add", rows.value)
}

function createUUID() {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
function removeRow(rowId) {
  console.log("rows before remove", rows.value)
  rows.value = rows.value.filter((row) => row.id !== rowId)
  // rowsUpdated.value = rowsUpdated.value + 1
  console.log("rows after remove", rows.value)
}

const formatNumber = (value) => {
  // From xslx we get the "formatted" data, but it's still formatted in US locale for numbers
  // (1,000.00 instead of 1 000,00), so we manually format numbers into French locale
  try {
    const parsedNumber = parseFloat(value.replace(/,/g, ""));
    return parsedNumber.toLocaleString('fr-FR');
  } catch (error) {
    console.log('Error formatting number:', error);
    return value;
  }
};


function openImportDialog() {
  const dialog = Dialog.create({
    component: ImportCsvDialog,
    componentProps: {
      tableColumns: columns.value,
    }
  }).onOk(({ csvData, columnMapping, choiceMappings }) => {
    console.log('To import :', { csvData, columnMapping, choiceMappings })
    for (let row of csvData) {
      const mappedRow = {};
      for (const [csvColumn, tableColumn] of Object.entries(columnMapping)) {
        if (tableColumn) {
          mappedRow[tableColumn.name] =
            tableColumn.type === 'choice' ?
            choiceMappings[tableColumn.name][row[csvColumn]] :
            tableColumn.type === 'number' ?
            formatNumber(row[csvColumn]):
            row[csvColumn];
        }
      }
      addRow(mappedRow)
    }
    console.log('Import complete');
    dialog.hide()
  })
}

function openExportDialog() {
  const dialog = Dialog.create({
    title: 'Export du tableau',
    message: `Voulez-vous exporter les ${filteredRows.value.length} lignes du tableau ?`,
    options: {
      type: 'radio',
      model: 'xlsx',
      items: [
        { label: 'Fichier Excel (.xlsx)', value: 'xlsx' },
        { label: 'Fichier CSV', value: 'csv' },
      ],
    },
    ok: {
      label: 'Confirmer',
      color: 'primary',
    },
    cancel: true,
  }).onOk((option) => {
    let blob
    const exportCols = columns.value.slice(1)
      .filter((col) => visibleColumns.value.includes(col.name) && col.type !== 'file')
      .flatMap((col) => {
        if (col.type === 'group_field') {
          return col.fields.map((subColumn) => col.name + ' / ' + subColumn.name)
        }
        return [col.name]
      })
    const exportRow = (row) => columns.value.slice(1)
      .filter((col) => visibleColumns.value.includes(col.name) && col.type !== 'file')
      .flatMap((col) => {
        let rowField = row.fields.find((field) => field.name === col.name)
        if (col.type === 'group_field') { return rowField.fields.map((subField) => subField.value) }
        // Remove whitespace delimiters to make it easier for Excel (but it still doesn't automatically recognize as it expects)
        // could be improved by manually assign excel formats https://docs.sheetjs.com/docs/csf/features/nf/#live-demo
        if (col.type === 'number') {
          let value = rowField.value.replace(/\s/g, '')
          if (col.format) { value = value + ' ' + col.format }
          return value
        }
        return rowField.value
      })
    if (option === 'csv') {
      const delimiter = ';'
      const csvContent = [
        exportCols.join(delimiter),
        // exportRowSums.join(delimiter),
        ...filteredRows.value.map((row) => exportRow(row).join(delimiter)),
      ].join('\n');

      blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    } else if (option === 'xlsx') {
      const headerRow = exportCols
      const dataRows = filteredRows.value.map((row) => exportRow(row));

      const worksheetData = [headerRow, ...dataRows];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      worksheet['!cols'] = exportCols.map((col) => ({ wch: Math.min(20, col.length + 5) })) // set column width
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    }
    // Download the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${props.field.name}.${option}`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
}

function createView() {
  // Save a view with current column & row rowFilters
  Dialog.create({
    title: 'Nouvelle sélection',
    message: 'Créer une sélection permet de sauvegarder les filtres de colonnes et de lignes.',
    prompt: {
      model: '',
      isValid: (val) => val.length > 0,
      type: 'text', // optional
      label: 'Nom de la sélection',
      autofocus: true
    },
    cancel: true,
    persistent: false
  }).onOk(viewName => {
    props.field.arrayViews = props.field.arrayViews || {}
    props.field.arrayViews[viewName] = {
      rowFilters: JSON.parse(JSON.stringify(rowFilters.value)),
      columnFilters: visibleColumns.value,
      active: true
    }
    selectedView.value = props.field.arrayViews[viewName]
    console.log('saved view', props.field.arrayViews)
    emit("update:field", props.field)
  })
}

function applyView(view) {
  if (!view) {
    rowFilters.value = columns.value.reduce((acc, column) => ({ ...acc, [column.name]: [] }), {});
    visibleColumns.value = columns.value.map((column) => column.name);
  } else {
    rowFilters.value = JSON.parse(JSON.stringify(view.rowFilters))
    visibleColumns.value = view.columnFilters
    // Mark the view as active for next user (requires saving with emit(...))
    Object.entries(props.field.arrayViews).forEach(([name, viewData]) => {
      viewData.active = (viewData.rowFilters === view.rowFilters && viewData.columnFilters === view.columnFilters)
    })
  }
}

const editingView = ref(false);
const editingViewName = ref("");
const existingViewName = ref("");
const updateViewFilters = ref(false);

const startEditingView = (opt) => {
  // Store the current view's name to be able to cancel changes
  editingViewName.value = opt.label;
  existingViewName.value = opt.label;
  editingView.value = true;
};
const cancelEdit = () => {
  editingView.value = false;
};

const updateView = () => {
  // Update the view name in the arrayViews object
  const oldName = existingViewName.value;
  const newName = editingViewName.value;
  // Name change
  if (oldName !== newName) {
    props.field.arrayViews[newName] = props.field.arrayViews[oldName];
    delete props.field.arrayViews[oldName];
  }
  // Update with current filters
  if (updateViewFilters.value) {
    props.field.arrayViews[newName].rowFilters = JSON.parse(JSON.stringify(rowFilters.value));
    props.field.arrayViews[newName].columnFilters = JSON.parse(JSON.stringify(visibleColumns.value));
  }

  editingView.value = false;
  emit("update:field", props.field)
};

const deleteView = () => {
  if (props.field.arrayViews.hasOwnProperty(editingViewName.value)) {
    delete props.field.arrayViews[editingViewName.value];
    editingView.value = false;
    editingViewName.value = "";
    selectedView.value = undefined;
  }
  emit("update:field", props.field)
};

const showGroupFieldDialog = ref(false)
const selectedGroupField = ref(null)
const showRowDialog = ref(false)
const selectedRow = ref(null)

const openGroupFieldDialog = (field) => {
  selectedGroupField.value = field
  showGroupFieldDialog.value = true
}

const openRowDialog = (row) => {
  selectedRow.value = {
    fields: row.fields,
    id: row.id
  }
  showRowDialog.value = true
}

const openNextRow = () => {
  if (!selectedRow.value) return

  const currentIndex = filteredRows.value.findIndex(row => row.id === selectedRow.value.id)
  if (currentIndex < filteredRows.value.length - 1) {
    const nextRow = filteredRows.value[currentIndex + 1]
    selectedRow.value = {
      fields: nextRow.fields,
      id: nextRow.id
    }
  }
}

const openPreviousRow = () => {
  if (!selectedRow.value) return

  const currentIndex = filteredRows.value.findIndex(row => row.id === selectedRow.value.id)
  if (currentIndex > 0) {
    const previousRow = filteredRows.value[currentIndex - 1]
    selectedRow.value = {
      fields: previousRow.fields,
      id: previousRow.id
    }
  }
}
</script>

<template>
  <div class="row q-col-gutter-x-md items-start">
    <p style="font-weight: 500;"><span style="text-decoration: underline;">Tableau</span>: {{ field.name }}</p>
    <q-btn flat dense round icon="more_vert" size="sm" class="q-ml-sm">
      <q-menu auto-close>
        <q-list style="min-width: 100px">
          <q-item clickable @click="openImportDialog">
            <q-item-section>Importer des données</q-item-section>
          </q-item>
          <q-item clickable @click="openExportDialog">
            <q-item-section>Exporter</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </div>

  <q-table :columns="columns" :visible-columns="visibleColumns" :rows="filteredRows" row-key="id" flat bordered :rows-per-page-options=[0]>
    <template v-slot:top>
      <!-- <q-btn v-if="hasFilters" label="Effacer les filtres" icon="clear" color="primary" @click="clearFilters"/> -->
      <q-btn icon="add" color="primary" @click="addRow()">Ajouter une donnée</q-btn>
      <q-space />
      <q-btn
        v-if="!props.field.arrayViews || Object.keys(props.field.arrayViews).length === 0 " label="Nouvelle sélection"
        class="q-mx-sm" icon='table_view' color="primary" @click="createView"/>
      <q-select
        v-if="props.field.arrayViews && Object.keys(props.field.arrayViews).length > 0"
        v-model="selectedView"
        :options="Object.entries(props.field.arrayViews).map(([name, view]) => ({label: name, value: view}))"
        emit-value map-options
        label="Sélection"
        outlined dense options-dense clearable
        class="selection-button q-mr-lg"
        @update:model-value="(opt) => applyView(opt?.value)"
      >
        <template v-slot:prepend>
          <q-icon name="table_view" />
        </template>
        <template v-slot:before-options>
          <q-item clickable @click="createView">
            <q-item-section avatar>
              <q-icon name="add" />
            </q-item-section>
            <q-item-section>
              Nouvelle sélection
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:option="slot">
          <q-item v-bind="slot.itemProps" @click.stop="applyView(slot.opt.value)">
            <q-item-section>
              <q-item-label v-html="slot.opt.label" />
            </q-item-section>
            <q-item-section side>
              <q-btn flat dense round icon="edit" size="sm" @click.stop="startEditingView(slot.opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <q-dialog v-model="editingView" persistent>
        <q-card style="width: 500px">
          <q-card-section>
            <div class="text-h6">Éditer la sélection</div>
          </q-card-section>
          <q-card-section>
            <q-input v-model="editingViewName" dense class="q-mb-md" label="Nom de la sélection"/>
            <q-checkbox v-model="updateViewFilters" label="Mettre à jour la sélection avec les filtres actuels" />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Annuler" v-close-popup @click="cancelEdit" />
            <q-btn label="Supprimer" color="negative" @click="deleteView" />
            <q-btn label="Enregistrer" color="primary" @click="updateView" />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-select
        v-model="visibleColumns"
        multiple outlined dense options-dense
        :display-value="`Colonnes (${visibleColumns.length-1}/${columns.length-1})`"
        emit-value
        map-options
        :options="columns.filter((col) => col.label !== '')"
        option-value="name"
        style="min-width: 150px"
      >
      <template v-slot:option="scope">
        <q-item clickable :active="scope.selected" @click="scope.toggleOption(scope.opt)" >
          <q-checkbox v-model="scope.selected" size="sm" label=" " @click="scope.toggleOption(scope.opt)"/>
          <q-item-section>
            {{ scope.opt.label }}
          </q-item-section>
        </q-item>
      </template>
    </q-select>
    </template>
    <template v-slot:header-cell="props">
      <q-th :props="props">
        <span>{{ props.col.label }}</span>
        <q-btn-dropdown v-if="props.col.name !== 'actions'" dense flat round size="sm" dropdown-icon="filter_list" @click.stop
          :color="rowFilters[props.col.name]?.length > 0 ? 'primary' : undefined">
          <q-option-group
            v-model="rowFilters[props.col.name]"
            :options="uniqueValues[props.col.name].map(value => ({label: value, value}))"
            type="checkbox"
            class="q-pa-sm q-pr-lg"
          />
        </q-btn-dropdown>
      </q-th>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td key="actions" :props="props">
          <q-btn outline rounded color="primary" padding="xs" icon="open_in_full" class="q-mr-sm" @click="openRowDialog(props.row)"></q-btn>
          <q-btn outline rounded color="red-5" padding="xs" icon="delete" @click="removeRow(props.row.id)"></q-btn>
        </q-td>
        <q-td :props="props" :key="field.name" v-for="field in props.row.fields">
          <TextField v-if="field.type === 'text'" :field="field" :showLabel="false" />
          <WysiwygTextField v-if="field.type === 'wysiwyg_text'" :field="field" :showLabel="false" />
          <NumberField v-if="field.type === 'number'" :field="field" :showLabel="false" />
          <DateField v-if="field.type === 'date'" :field="field" :showLabel="false" />
          <div v-if="field.type === 'group_field'">
            <q-btn flat outlined rounded @click="openGroupFieldDialog(field)" class="group-button">
              {{ field.fields?.filter(f => field.chipFields?.includes(f.modelId)).map(f => f.value).join(' ') }}
            </q-btn>
          </div>
          <ChoiceField v-if="field.type === 'choice'" :field="field" :showLabel="false" />
          <MultipleChoice v-if="field.type === 'multi_choice'" :field="field" :showLabel="false" />
          <FunctionField v-if="field.type === 'function'" :field="field" :showLabel="false" :otherFields="props.row.fields" />
          <FileField v-if="field.type === 'file'" :field="field" :row="props.row" :showLabel="false" />
        </q-td>
      </q-tr>
    </template>
    <!-- <template #top v-if="rows.length">
      <q-btn icon="add" color="primary" class="q-mr-md" style="margin: auto" @click="addRow()">Ajouter
        une donnée</q-btn>
    </template> -->
    <template #no-data>
      <!-- <div class="row justify-center full-width">
        <q-btn icon="add" color="primary" @click="addRow()">Ajouter une donnée</q-btn>
      </div> -->
    </template>
    <template v-if="showSums" v-slot:top-row="props">
      <q-tr style="background-color: #f5f5f5">
        <q-td v-for="col in props.cols" :key="col.name">
          <template v-if="col.name !== 'actions'">
            {{rowSums[col.name]}}
          </template>
          <template v-else>
                   Totaux
          </template>
        </q-td>
      </q-tr>
    </template>

  </q-table>

  <!-- Group Field Dialog -->
  <q-dialog v-model="showGroupFieldDialog">
    <q-card style="min-width: 350px;">
      <q-card-section>
        <GroupField v-if="selectedGroupField" :field="selectedGroupField"
        @enter="showGroupFieldDialog = false" :hasBorder="false" />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" v-close-popup class="q-mr-md q-mb-md"/>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- Row Dialog -->
  <q-dialog v-model="showRowDialog" style="max-height: 80vh;" @keyup.left="openPreviousRow" @keyup.right="openNextRow" @keyup.enter="showRowDialog = false">
    <q-card style="min-width: 50vw;">
      <q-card-section>
        <GroupField
          v-if="selectedRow"
          :field="{ fields: selectedRow.fields }"
          @enter="showRowDialog = false"
          :showLabel="false"
          :hasBorder="false"
        />
      </q-card-section>
      <q-card-actions>
        <div class="q-mr-auto">
          <q-btn color="primary" label="Ligne précédente" icon="chevron_left" @click="openPreviousRow" class="q-mx-sm"
            :disable="!selectedRow || filteredRows.findIndex(row => row.id === selectedRow.id) === 0" />
          <q-btn color="primary" label="Ligne suivante"  icon-right="chevron_right" @click="openNextRow" class="q-mr-sm"
          :disable="!selectedRow || filteredRows.findIndex(row => row.id === selectedRow.id) === filteredRows.length - 1" />
          <q-btn color="primary" label="Créer une autre ligne"  icon-right="add" @click="{addRow(); selectedRow = filteredRows[filteredRows.length - 1]}"
            v-if="!selectedRow || filteredRows.findIndex(row => row.id === selectedRow.id) === filteredRows.length - 1" />
        </div>
        <q-btn color="primary" label="Fermer" v-close-popup class="q-mx-sm" />
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<style lang="scss">
@import '@/styles/app.variables.scss';

.table {
  margin-top: 10px;
}

h3 {
  font-size: medium;
}

.selection-button {
  width: 200px;
}
.selection-button .q-field__native {
  text-wrap: nowrap; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
}

.group-button {
  background-color: $page_background_color;
  border: 1px solid #ccc;
  font-weight: 400;
}

.close-button {
  position: absolute;
  top: -12px; /* Moves it above the dialog */
  right: -12px; /* Moves it slightly to the right */
  background-color: white; /* Optional: Change background color */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
}
</style>
