import Database from './Database.vue'
import DatabaseObject from './DatabaseObject.vue'
import DatabaseRedirect from './DatabaseRedirect.vue'

const routes = [
  {
    name: 'database',
    component: Database,
    path: '/database',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'clients/client/database',
    component: DatabaseRedirect,
    path: '/clients/:clientId/database',
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'database/object',
    component: DatabaseObject,
    path: '/database/:objectId',
    meta: {
      requiresAuth: true,
    },
  },
]

export { routes }
