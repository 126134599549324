<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'

const props = defineProps<{
  selectedDomain: string
}>()

const emit = defineEmits(['select-subproject'])

const userApi: UserApi = useApi()
// const authenticatedUser = useAuthenticatedUser()
// const environmentId = authenticatedUser.environment.pkid

const expandedNodes = ref(['root'])
const selectedNode = ref(null)
const nodes = ref([])
const loading = ref(true)

// Get the project catalog filtered by domain
const getFilteredCatalog = async () => {
  try {
    const platformCatalogResponse = await userApi.getPlatformProjectCatalog()
    const catalog = platformCatalogResponse.catalogJson[0].children[0].children

    // Filter the chosen domain
    const filteredCatalog = catalog.find(node => node.label === props.selectedDomain)
    console.log('filteredCatalog', filteredCatalog)

    if (filteredCatalog) {
      // Transform into the format q-tree expects
      return [{
        id: 'root',
        label: filteredCatalog.label,
        type: 'root',
        children: filteredCatalog.children || []
      }]
    }
    return []
  } catch (error) {
    console.error('Error loading catalog:', error)
    return []
  }
}

// Load catalog when domain changes
watch(() => props.selectedDomain, async () => {
  loading.value = true
  nodes.value = await getFilteredCatalog()
  loading.value = false
}, { immediate: true })

const getNode = (nodes, nodeId) => {
  for (const node of nodes) {
    if (node.id === nodeId) {
      return node
    }
    if (node.children) {
      const childNode = getNode(node.children, nodeId)
      if (childNode) {
        return childNode
      }
    }
  }
  return undefined
}

const selectNode = (nodeId) => {
  let node = getNode(nodes.value, nodeId)
  console.log('selectNode', node, node.type)
  if (node.type === 'project') {
    // selectedNode.value = node
    emit('select-subproject', node)
  }
}

</script>

<template>
  <div class="tree-container q-pa-md">
    <q-inner-loading :showing="loading">
      <q-spinner-dots size="50px" color="primary" />
    </q-inner-loading>

    <div v-if="!loading && nodes.length === 0" class="text-center q-pa-md">
      Aucun projet disponible pour ce domaine
    </div>

    <q-tree
      v-if="!loading && nodes.length > 0"
      :nodes="nodes"
      node-key="id"
      v-model:expanded="expandedNodes"
      v-model:selected="selectedNode"
      selected-color="black"
      select-strategy="leaf"
      @update:selected="selectNode"
    >
      <template v-slot:default-header="prop">
        <div class="row items-center">
          <div class="text-weight-medium">{{ prop.node.label }}</div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<style lang="scss" scoped>
.tree-container {
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.483);
}
</style>
