<script setup lang="ts">
import { useStore } from '@/store/store'
import { useApi } from '@/store/useApi'
import UserApi from '@/services/api/core/UserApi'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import BackOfficeApi from '@/services/api/BackOfficeApi'
import { AuthenticatedUser, getRoutes } from '@/models/core/AuthenticatedUser'

const store = useStore()
const userApi: UserApi = useApi()
const route = useRoute()
const router = useRouter()

onMounted(async () => {
  store.commit('setPageTitle', 'Authentification')
  const token: string = route.path.split('/').pop() || ''

  // Call backend to verify credentials
  const api = new BackOfficeApi()
  console.log('token', token)
  const user = await api.lxbLogin(token)

  // Store the user in the store
  console.log('user', user)
  let authenticatedUser: AuthenticatedUser = Object.assign(new AuthenticatedUser(), user)
  store.commit('setUser', authenticatedUser)

  // Fetch full user details
  const completeUser = await userApi.getUser(authenticatedUser.id)
  console.log('Complete user', completeUser)

  authenticatedUser.permissions = completeUser.permissions
  authenticatedUser.environments = completeUser.environments
  let selectedEnvironment = completeUser.environments[0]
  authenticatedUser.portal = selectedEnvironment.portalType
  authenticatedUser.environment = selectedEnvironment
  authenticatedUser.client = selectedEnvironment.client
  if (authenticatedUser.clientId = selectedEnvironment.client) {
    authenticatedUser.clientId = selectedEnvironment.client.id
  }

  store.commit('setUser', authenticatedUser)

  // Update routes based on user roles
  const newRoutes = getRoutes(authenticatedUser)
  const oldRoutes = store.state.routes
  oldRoutes.forEach(route => router.removeRoute(route.name))
  newRoutes.forEach(route => router.addRoute(route))

  // Fetch and store favorites
  const newFavorites = await userApi.getFavorites()
  store.commit('setFavorites', newFavorites)

  // Redirect the user
  router.replace({ name: 'base' })
  // if (localStorage.getItem('redirectPath')) {
  //   router.replace({ path: localStorage.getItem('redirectPath') })
  // } else if (isOutlookAddIn()) {
  //   router.replace({ name: 'outlook-add-in/taskpane' })
  // } else {
  //   router.replace({ name: 'base' })
  // }
})

</script>

<template>
  <q-page flex>
    <div class="q-pa-md page-content q-col-gutter-md centered-form" style="max-width: 450px">
      <div class="row q-col-gutter-sd">
        <h1>Bienvenue sur ComeOnLaw !</h1>
      </div>
      <div class="row">
      </div>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

h1 {
  margin-bottom: 10px;
  margin-top: 80px;
  line-height: 50px;
  text-align: center;
}

input {
  width: 400px;
}

.submit-button {
  margin-top: 20px;
}
</style>
