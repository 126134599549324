<script lang="ts" setup>
import { computed, ref } from 'vue'
import { Notify, Dialog, useQuasar } from 'quasar'
import FileSaver from 'file-saver'
import { Database } from '@/models/database/Database'
import { Field } from '@/models/database/Field'
import { Node } from '@/models/database/Node'
import { NodeType } from '@/models/database/NodeType'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import FileUploadDialog from './FileUploadDialog.vue'
import DeleteFileDialog from './DeleteFileDialog.vue'
import PdfPreviewDialogWithIframe from '@/components/projects/execution/task_actions/document_generation/dialog/PdfPreviewDialogWithIframe.vue'
import ImagePreviewDialog from '@/components/projects/execution/documents/ImagePreviewDialog.vue'

interface Props {
    field: Field
    showLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showLabel: true,
})
const emit = defineEmits(['update:field'])

const userApi: UserApi = useApi()
const isLoading = ref(false)
const isUploaded = computed(() => {
  return props.field.value && props.field.value.filename
})
const isPreviewableFile = computed(() => {
  if (props.field.value && props.field.value.filename) {
    return (
      props.field.value.filename.toLowerCase().endsWith('.pdf') ||
      props.field.value.filename.toLowerCase().endsWith('.jpg') ||
      props.field.value.filename.toLowerCase().endsWith('.jpeg') ||
      props.field.value.filename.toLowerCase().endsWith('.png') ||
      props.field.value.filename.toLowerCase().endsWith('.gif') ||
      props.field.value.filename.toLowerCase().endsWith('.webp')
    )
  }
})

async function downloadFile() {
  console.log("props.field.value", props.field.value)
  try {
    const doc = await userApi.downloadDatabaseFile(props.field.value.file_id)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, props.field.value.filename)
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement du document',
      type: 'negative',
    })
  }
}

async function showPdfPreview() {
  const BASE_URL = import.meta.env.VITE_API_BASE_URL
  let filenameConverted = encodeURIComponent(props.field.value.filename)
  let fileUrl =
    BASE_URL +
    userApi.getCurrentClientBaseUrl() +
    `/database/files/${props.field.value.file_id}/download/${filenameConverted}?type=preview`
  let comp = props.field.value.filename.endsWith('.pdf') ?
    PdfPreviewDialogWithIframe : ImagePreviewDialog
  console.log('fileUrl', fileUrl)
  const dialog = Dialog.create({
    component: comp,
    componentProps: {
      documentName: props.field.value.filename,
      fileUrl: fileUrl,
    },
  })
    .onOk(async (data) => {
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showUploadFileDialog() {
  const dialog = Dialog.create({
    component: FileUploadDialog,
    componentProps: {
      file: props.field.value,
    },
  })
    .onOk((uploadedFileInfos) => {
      props.field.value = {
        file_id: uploadedFileInfos.id,
        filename: uploadedFileInfos.fileName,
      }
      emit('update:field', props.field)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showDeleteFileModal() {
  const dialog = Dialog.create({
    component: DeleteFileDialog,
    componentProps: {},
  })
    .onOk(() => {
      deleteFile()
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function deleteFile() {
  props.field.value = undefined
}
</script>


<template>
  <div :style="showLabel ? 'border: 0.5px solid #ccc; padding: 16px;' : ''">
      <div v-if="showLabel" style="font-weight: 500">{{ field.name }}</div>
      <span v-if="!isUploaded">Aucun fichier</span>
    <span v-if="isUploaded && isPreviewableFile" @click="showPdfPreview()" style="cursor: pointer">{{ field.value.filename }}</span>
    <span v-if="isUploaded && !isPreviewableFile">{{ field.value.filename }}</span>
    <q-btn-dropdown :disable="isLoading" flat dense rounded dropdown-icon="more_vert">
        <q-list>
            <q-item clickable v-close-popup @click="showUploadFileDialog()">
                <q-item-section>
                    <q-item-label>Importer un fichier</q-item-label>
                </q-item-section>
            </q-item>
            <q-item clickable v-if="isPreviewableFile" v-close-popup @click="showPdfPreview()">
              <q-item-section>
                <q-item-label>Prévisualiser</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-if="isUploaded" v-close-popup @click="downloadFile()">
                <q-item-section>
                    <q-item-label>Télécharger</q-item-label>
                </q-item-section>
            </q-item>
            <q-item clickable v-if="isUploaded" v-close-popup @click="showDeleteFileModal()">
                <q-item-section>
                    <q-item-label text-color="negative">Supprimer</q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </q-btn-dropdown>
    </div></template>

<style lang="scss" scoped>
.table {
    margin-top: 10px;
}

h3 {
    font-size: medium;
}
</style>
