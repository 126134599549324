<script lang="ts" setup>
import { ref } from 'vue'
import { useDialogPluginComponent } from 'quasar'
import { createUUID } from '@/components/common/database/utils'
import GroupField from '../rendered_fields/GroupField.vue'

interface Props {
  columns: any[]
  title?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Nouvel élément'
})

const emit = defineEmits([...useDialogPluginComponent.emits])
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const row = ref({
  id: createUUID(),
  fields: []
})

function beforeShow() {
  // Initialize fields with empty values
  props.columns.forEach((column: any) => {
    let field: any = {
      id: createUUID(),
      name: column.name,
      type: column.type,
      modelId: column.modelId,
      value: ""
    }
    if (column.type === "group_field") {
      field.fields = column.fields.map(subfield => ({
        ...subfield,
        value: "",
        id: createUUID()
      }))
    }
    if (column.type === "choice") { field.choices = column.choices }
    if (column.type === "number" || column.type === "function") { field.format = column.format }
    if (column.type === "function") { field.formula = column.formula }
    row.value.fields.push(field)
  })
}

function onFieldChanged(field) {
  // No need to do anything, the field is already updated by reference
}

function onConfirm() {
  emit('ok', row.value)
  dialogRef.value?.hide()
}
</script>

<template>
  <q-dialog ref="dialogRef" @before-show="beforeShow">
    <q-card style="width: 700px; max-width: 80vw;">
      <q-card-section class="row items-center">
        <div class="text-h6">{{ title }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <GroupField :field="row" @update:field="onFieldChanged" :hasBorder="false"></GroupField>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Annuler" color="primary" @click="onDialogCancel" />
        <q-btn flat label="Confirmer" color="primary" @click="onConfirm" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
