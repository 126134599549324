<script setup lang="ts">
import { ref, provide, Ref, onMounted, inject, watch } from 'vue'

const unsavedChanges = inject("unsavedChanges")

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

const formatOptions = [
  { label: 'Sans format', value: '' },
  { label: 'Euro', value: '€' },
  { label: 'Dollar', value: '$' },
  { label: 'Pourcentage', value: '%' },
]

</script>


<template>
  <div style="display: flex; align-items: center;">
    <span class="q-mr-sm">Format :</span>
    <q-select
      v-model="props.element.format"
      :options="formatOptions"
      emit-value
      map-options
      options-dense
      style="width: 120px"
      @update:model-value="unsavedChanges = true"
    />
  </div>

</template>
