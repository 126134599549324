<script setup lang="ts">
import { inject, computed } from 'vue'

const unsavedChanges = inject("unsavedChanges")
const selectedPage = inject("selectedPage")
let functionNames = ['dateDiff', 'dateAdd', 'today', 'date', '\"year\"', '\"month\"', '\"week\"', '\"day\"']

interface Props {
    element: any
}

const props = withDefaults(defineProps<Props>(), {
})

if (props.element.formulaFields == undefined) {
    props.element.formulaFields = []
}

const isValid = computed(() => {
    // Test that the formula only contains valid field names in {} or operators
    // (it mixes fields and group_field/array subfields although these are not allowed together)
    let fieldNames = selectedPage.value.fields.map((f) => {
        if (f.type === 'group_field') {
            return f.fields.map((sf) => sf.name.replace(/[()]/g, '\\$&')).join('|')
        } else if (f.type === 'array') {
            return f.columns.map((sf) => sf.name.replace(/[()]/g, '\\$&')).join('|')
        }
        return f.name.replace(/[()]/g, '\\$&')
    }).join('|')
    let regex = `^((\\{(${fieldNames})\\})|${functionNames.join('|')}|\\+|\\-|\\*|\\(|\\)|,|\\s|\\d|(".*")|\\/)*$`
    console.log(fieldNames, selectedPage.value.fields.map((f) => f.name), regex)
    return new RegExp(regex).test(props.element.formula)
})

const formatOptions = [
  { label: 'Sans format', value: '' },
  { label: 'Euro', value: '€' },
  { label: 'Dollar', value: '$' },
  { label: 'Pourcentage', value: '%' },
]

function onChange() {
    unsavedChanges.value = true
}
</script>


<template>
    <q-input dense class="no-drag" outlined v-model="element.formula" style="margin-top: 8px" @update:model-value="onChange">
        <template v-slot:before>
            <span style="font-size: 14px; color: black;">Formule :</span>
        </template>
    </q-input>
    <div v-if="!isValid" style="font-size: 12px; margin-top: 8px">
        <p style="color: red;">La formule n'est pas valide</p>

        La formule peut contenir les noms de champs de la page encadrés de { et }.<br/>
        Opérateurs pour les nombres : +, -, * et /.<br/>
        Fonctions pour les dates :
        <ul>
            <li><code>dateDiff(début, fin, unité)</code><br/>
                Différence entre les dates en année/mois/semaine/jour.<br/>
                Exemple : <code>dateDiff({Date de naissance}, today(), "year")</code> donnera l'âge.
            </li>
            <li><code>dateAdd(début, nombre, unité)</code><br/>
                Date résultant de l'ajout à une autre date d'années/mois/semaines/jours.<br/>
                Exemple : <code>dateAdd(date("06/01/2022"), 3, "year")</code> donnera "06/01/2025".
            </li>
            <li><code>today()</code> : la date d'aujourd'hui.</li>
            <li><code>date("01/01/2022")</code> : permet de définir une date.</li>
            </ul>

    </div>

    <div style="display: flex; align-items: center;">
      <span class="q-mr-sm">Format :</span>
      <q-select
        v-model="props.element.format"
        :options="formatOptions"
        emit-value
        map-options
        options-dense
        style="width: 120px"
        @update:model-value="unsavedChanges = true"
      />
    </div>

</template>