<script setup lang="ts">
import { useStore } from '@/store/store'
import { useRouter } from 'vue-router'
import { useBrowserLocation } from '@vueuse/core'
import { Notify } from 'quasar'
import { ref, computed, onMounted, inject } from 'vue'
import { usePageTitle } from '@/store/useAppStore'

import { AuthenticatedUser, getRoutes } from '@/models/core/AuthenticatedUser'
import BackOfficeApi from '@/services/api/BackOfficeApi'
import { isMobile } from '@/utils/detectMobile'
import { initOffice } from '@/pages/outlook-add-in/OutlookAddInCommon'

const store = useStore()
const router = useRouter()

const logoUrl = ref('')
const logoStyle = ref('')
const login = ref('')
const password = ref('')
const isPwd = ref(true)
const isLoading = ref(false)
const favorites = inject("favorites")

usePageTitle('Authentification')

console.log('isMobile()', isMobile())

const location = useBrowserLocation()

function isOutlookAddIn() {
  if (
    location.value.hostname.includes('cedricdinont.dyndns-home.com') ||
    location.value.hostname.includes('outlook-add-in')
  ) {
    return true
  } else {
    return false
  }
}

console.log('isOutlookAddIn()', isOutlookAddIn())

onMounted(() => {
  console.log('onmounted')
  if (isOutlookAddIn()) {
    console.log('isOutlookAddIn: initOffice')
    initOffice(() => { })
  }
})

// TODO: Replace with an API call
if (location.value.hostname.includes('bewiz')) {
  logoUrl.value = 'https://www.bewiz.fr/wp-content/uploads/2015/01/bewiz2.png'
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_bewiz.gif'
  logoStyle.value = 'width: 80%;padding-left: 100px;'
} else if (location.value.hostname.includes('marvellup')) {
  // logoUrl.value =
  //   "https://uploads-ssl.webflow.com/612f3b9c9f40bd43274df45f/615b210de1a84a70208dd30e_MarvellUp-03%20(1)-p-500.png";
  // logoUrl.value =
  //   "https://app.staging.comeonlaw.com/static/images/logo_marvell_up.png";
  logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_marvellup.png'
  if (isMobile() || isOutlookAddIn()) {
    logoStyle.value = 'width: 80%;padding-left: 68px;'
  } else {
    logoStyle.value = 'width: 80%;padding-left: 100px;'
  }
} else if (location.value.hostname.includes('karila')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_karila.png'
} else if (location.value.hostname.includes('chicheattaliavocats')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_chicheattaliavocats.jpeg'
  logoStyle.value = 'width: 85%;padding-left: 100px;'
} else if (location.value.hostname.includes('cercali')) {
  logoUrl.value = 'https://app.comeonlaw.com/static/images/logo_cercali.png'
  logoStyle.value = 'width: 90%;padding-left: 70px;'
} else {
  logoUrl.value =
    "https://uploads-ssl.webflow.com/60ae33936d219445306748b5/60ae6b0c56ec71df6665d5a3_comeon_logofinal-p-500.png"
  // logoUrl.value = 'https://app.staging.comeonlaw.com/static/images/logo_demo_cabinet_avocat.png'
  if (isMobile() || isOutlookAddIn()) {
    logoStyle.value = 'width: 80%;padding-left: 68px;'
  } else {
    // logoStyle.value = 'width: 80%; padding-left: 100px; margin-top: 50px;'  // cabinet d'avocats
    logoStyle.value = 'width: 100%; padding-left: 16px; margin-top: 50px;'    // COL
  }
}

function handleSubmit() {
  const api = new BackOfficeApi()
  api.setStore(store)
  isLoading.value = true
  api
    .login(login.value, password.value)
    .then((user) => {

      let authenticatedUser: AuthenticatedUser = Object.assign(new AuthenticatedUser(), user)

      store.commit('setUser', authenticatedUser)

      store.state.api.userApi.getUser(authenticatedUser.id).then((completeUser) => {
        console.log("completeUser", completeUser)
        authenticatedUser.permissions = completeUser.permissions

        let selectedEnvironment = completeUser.environments[0]
        completeUser.environments.forEach((environment) => {
          if (environment.id == "2c29fa44-fb99-4b0d-affd-b1800b379efa") {
            // Use MarvellUp as default environment
            selectedEnvironment = environment
          }
        })

        authenticatedUser.portal = selectedEnvironment.portalType
        authenticatedUser.environments = completeUser.environments
        authenticatedUser.environment = selectedEnvironment
        authenticatedUser.client = selectedEnvironment.client
        if (authenticatedUser.clientId = selectedEnvironment.client) {
          authenticatedUser.clientId = selectedEnvironment.client.id
        }

        store.commit('setUser', authenticatedUser)

        const newRoutes = getRoutes(authenticatedUser)

        // Clear all routes
        const oldRoutes = store.state.routes
        oldRoutes.forEach((route) => {
          router.removeRoute(route.name)
        })

        // Add  all new routes
        newRoutes.forEach((route) => {
          router.addRoute(route)
        })

        store.state.api.userApi.getFavorites().then((newFavorites) => {
          favorites.value = newFavorites

          if (localStorage.getItem('redirectPath')) {
            router.replace({
              path: localStorage.getItem('redirectPath'),
              // query: { page: 1 },
            })
          } else if (isOutlookAddIn()) {
            router.replace({ name: 'outlook-add-in/taskpane' })
          } else {
            router.replace({ name: 'base' })
          }
        })
      })

      isLoading.value = false
    })
    .catch((error) => {
      console.error('error', error)
      Notify.create({
        message: "L'identifiant et/ou le mot de passe sont incorrects",
        type: 'negative',
      })
      isLoading.value = false
    })
}

var mainDivClass = computed({
  get: () => {
    if (isMobile() || isOutlookAddIn()) {
      return 'q-pa-md page-content q-col-gutter-md centered-form-mobile'
    } else {
      return 'q-pa-md page-content q-col-gutter-md centered-form'
    }
  },
})

var titleDivClass = computed({
  get: () => {
    if (isMobile() || isOutlookAddIn()) {
      return 'col-md-12 title-mobile'
    } else {
      return 'col-md-12 title'
    }
  },
})
</script>

<template>
  <q-page flex>
    <div :class="mainDivClass" style="max-width: 450px">
      <div class="row q-col-gutter-sd" :style="logoStyle">
        <q-img :src="logoUrl"></q-img>
      </div>
      <div class="row q-col-gutter-sd">
        <h1 :class="titleDivClass">Connexion</h1>
      </div>
      <div class="columns">
        <q-input outlined class="q-mb-lg" id="login" type="text" label="Identifiant" v-model="login" required autofocus
          :disable="isLoading" />
        <q-input outlined id="password" :type="isPwd ? 'password' : 'text'" label="Mot de passe" v-model="password"
          required bottom-slots :disable="isLoading">
          <template #append>
            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
          </template>
          <template #hint>
            <span class="cursor-pointer forgot-password" @click="router.push({ name: 'base/password_forgot' })">Mot de
              passe oublié ?</span>
          </template>
        </q-input>
      </div>
      <div class="row">
        <q-btn class="col-md-12 submit-button justify-center full-width" color="primary" label="Se connecter"
          @click="handleSubmit" :loading="isLoading" :disable="isLoading" />
      </div>

      <div v-if="isOutlookAddIn()" class="row">
        MarvellUp propose aux avocats de gérer de manière centralisée les documents et les échanges avec les parties
        prenantes de leurs dossiers.<br /><br />
        L'add-in Outlook de MarvellUp permet de classer les mails entrants directement dans les dossiers de MarvellUp et
        également d'envoyer en pièces jointes les documents des dossiers de MarvellUp.
        <br /><br />
        Requiert un compte MarvellUp. Contactez-nous sur
        <a href="https://www.marvellup.com">https://marvellup.com/</a>&nbsp;ou
        <a href="mailto:contact@marvellup.com">contact@marvellup.com</a> pour obtenir vos identifiants.
      </div>
    </div>
  </q-page>
</template>

<style lang="scss" scoped>
.centered-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.centered-form-mobile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.title {
  margin-bottom: 10px;
  margin-top: 80px;
  line-height: 50px;
  text-align: center;
}

.title-mobile {
  margin-bottom: 10px;
  margin-top: 20px;
  line-height: 50px;
  text-align: center;
  width: 100%;
}

input {
  width: 400px;
}

.forgot-password {
  color: #3d73c5;
  margin-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.submit-button {
  margin-top: 20px;
}
</style>
